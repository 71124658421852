import { ICommunication, PlainAction, PlainFailAction } from 'shared/types/redux';

interface IProtectAction {
  type: string;
  error: any;
}

export default function makeCommunicationReducer<
  E extends PlainAction<string> = IProtectAction,
  C extends PlainAction<string> = IProtectAction,
  F extends PlainFailAction<string> = IProtectAction,
  D extends PlainAction<string> = IProtectAction,
  >(
    executeType: E['type'],
    completedType: C['type'],
    failedType: F['type'],
    initial: ICommunication<F['error']>,
    resetType?: D['type'],
): (state: ICommunication<F['error']>, action: PlainAction<string>) => ICommunication<F['error']> {
  return (state: ICommunication<F['error']> = initial, action: PlainAction<string>) => {
    switch (action.type) {
      case executeType: return { error: '', isRequesting: true, completed: false };
      case completedType: return { error: '', isRequesting: false, completed: true };
      case failedType: return { error: (action as F).error, isRequesting: false, completed: true };
      case resetType: return { error: '', isRequesting: false, completed: false };
      default: return state;
    }
  };
}
