import { v4 as uuidv4 } from 'uuid';

import { MetricColumn } from 'shared/types/models/EditMetrics';

export function makeMarkupBudgetColumnFromColumn(
  column: MetricColumn,
  markupBudgetSubId: number | null
): MetricColumn {
  return {
    ...column,
    columnTitle: 'markup',
    subId: markupBudgetSubId,
    id: uuidv4(),
  };
}
