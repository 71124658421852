import * as React from 'react';

import { block } from 'bem-cn';

import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { autobind } from 'core-decorators';
import { IAppReduxState } from 'shared/types/app';
import { selectors, actionCreators } from 'services/Alert/redux';
import { TypeAlert } from 'services/Alert/types';

import './style.scss';

type StateProps = {
  isShow: boolean;
  message: string;
  type: TypeAlert | null;
};

type ActionProps = typeof containerActionCreators;

type Props = StateProps & ActionProps;

type State = {
  type: TypeAlert | null;
};

const b = block('snackbar');

class Alert extends React.PureComponent<Props, State> {

  public state: State = {
    type: null,
  };

  public componentDidUpdate(prevProps: Props) {
    const { type, isShow } = this.props;
    if (prevProps.isShow !== isShow || prevProps.type !== type) {
      // change type in state after delay animation
      setTimeout(() => this.setState({ type }), !isShow ? 225 : 0);
    }
  }

  public render() {
    const { message, isShow  } = this.props;
    const { type } = this.state;

    return (
      <Snackbar
        className={b()}
        open={isShow}
        onClose={this.handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={type === 'error' ? undefined : 8000}
      >
        <MuiAlert
          onClose={this.handleClose as any}
          severity={type || undefined}
          variant="filled"
          elevation={6}
        >
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </MuiAlert>
      </Snackbar>
    );
  }

  @autobind
  private handleClose(event: React.SyntheticEvent, reason: string) {
    const { type } = this.state;

    if (type === 'error' && reason === 'clickaway') {
      return;
    }

    this.props.closeAlert();
  }
}

function mapState(state: IAppReduxState): StateProps {
  return {
    isShow: selectors.selectShow(state),
    message: selectors.selectMessage(state),
    type: selectors.selectType(state),
  };
}

const containerActionCreators = {
  openAlert: actionCreators.openAlert,
  closeAlert: actionCreators.closeAlert,
};

export default connect(mapState, containerActionCreators)(Alert);
