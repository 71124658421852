import { call, put } from 'redux-saga/effects';
import moment from 'moment';

import { IDependencies } from 'shared/types/app';
import * as M from 'shared/types/models';

import { tryCatch } from 'services/ErrorTracking';

import { isFullWeek } from '../../../helpers';
import { SeparatedWeeks } from '../../../types';
import * as actionCreators from '../../actionCreators';

export function* loadCalendarPeriods({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const year = new Date().getFullYear();
      const prevYear = year - 1;
      const nextYear = year + 1;
      const calendarPeriods: M.CalendarPeriods
        = yield call(api.mediaplan.getCalendarPeriod, { start: String(prevYear), end: String(nextYear) });
      const prevYearWeeks = calendarPeriods[prevYear].weeks;
      const currentYearWeeks = calendarPeriods[year].weeks;
      const prevYearLastWeek = prevYearWeeks[prevYearWeeks.length - 1];
      const currentYearFirstWeek = calendarPeriods[year].weeks[0];
      const firstIntersectionWeek: M.CalendarWeek = {
        ...currentYearFirstWeek,
        firstWeekDay: isFullWeek(currentYearFirstWeek) ? currentYearFirstWeek.firstWeekDay : prevYearLastWeek.firstWeekDay,
      };
      const nextYearFirstWeek = calendarPeriods[nextYear].weeks[0];
      const currentYearLastWeek = currentYearWeeks[currentYearWeeks.length - 1];
      const secondIntersectionWeek: M.CalendarWeek = {
        ...currentYearLastWeek,
        lastWeekDay: isFullWeek(currentYearLastWeek) ? currentYearLastWeek.lastWeekDay : nextYearFirstWeek.lastWeekDay,
      };
      const weeks = [
        firstIntersectionWeek,
        ...currentYearWeeks.filter((_, index) => index !== 0 && index !== currentYearWeeks.length - 1),
        secondIntersectionWeek,
      ];

      const separatedWeeks = weeks.reduce<SeparatedWeeks>((acc, week, index, arr) => {
        const monthIndex = moment(week.firstWeekDay).month();

        if (index === 0) {
          return { ...acc, 0: [week] };
        }

        if (index === arr.length - 1) {
          return { ...acc, [monthIndex]: [...acc[monthIndex], week] };
        }

        return { ...acc, [monthIndex]: acc[monthIndex] ? [...acc[monthIndex], week] : [week] };
      }, {});

      yield put(actionCreators.loadCalendarPeriodsCompleted({ weeks: separatedWeeks }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadCalendarPeriodsFailed(message));
    },
  });
}
