import * as React from 'react';

import { bind } from 'decko';
import { block } from 'bem-cn';

import { Button as MuiButton, ButtonProps } from '@material-ui/core';

import { Tooltip } from 'shared/view/elements';

import './Button.scss';

const b = block('button');

interface IOwnProps {
  onClick?(): void;
  disabled?: boolean;
  tooltip?: string;
}

class Button extends React.Component<IOwnProps & ButtonProps> {

  render() {
    const { children, tooltip, disabled = false, ...restProps } = this.props;
    const button = (
      <div className={b()}>
        <MuiButton
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={this.onClick}
          {...restProps}
        >
          {children}
        </MuiButton>
      </div>
    );

    return tooltip && !disabled
      ? (
        <Tooltip placement="top-start" title={tooltip}>
          <div>{button}</div>
        </Tooltip>
      ) : button;
  }

  @bind
  private onClick(event: React.FormEvent<HTMLButtonElement>) {
    const { onClick } = this.props;
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  }
}

export { Button };
