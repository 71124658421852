import axios, { AxiosRequestConfig, AxiosInstance, AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios';

import { getEnvParams } from 'core/getEnvParams';

import { ApiErrorInterceptor } from './interceptors/types';

import { TokenExpiredHandler } from './interceptors/handleTokenExpired';
import { isErrorStatus, isHandledErrorResponse } from './helpers';

interface IHttpActionParams<T> {
  url: string;
  options?: AxiosRequestConfig;
  data?: any;
  domainType?: DomainType;
  version?: ApiVersion;
  performLatestOnly?: boolean;
}

type DomainType = 'baseApi' | 'keyCloackSSOApi' | 'portalApi' | 'mockApi';
type ApiVersion = 'v1' | 'v2';

class HttpActions {
  private request: AxiosInstance;
  private axiosConfig: AxiosRequestConfig;
  private baseURL = getEnvParams().baseAPI!;
  private ssoApiKeyCloackUrl = getEnvParams().keyCloackSSOAPI!;
  private portalURL = getEnvParams().portalBackURL!;
  private mockApiURL = getEnvParams().mockApiURL || '';
  private _handleTokenExpiredInterceptor: TokenExpiredHandler | null = null;
  private _networkErrorHandler: (() => void) | null = null;
  private postCancelTokenSource: CancelTokenSource | null = null;

  set handleTokenExpiredInterceptor(interceptor: TokenExpiredHandler) {
    this._handleTokenExpiredInterceptor = interceptor;
  }

  set networkErrorHandler(handler: () => void) {
    this._networkErrorHandler = handler;
  }

  constructor(interceptors: ApiErrorInterceptor[]) {

    if (!this.baseURL) {
      console.error('Api not configured!');
    }

    this.axiosConfig = {
      baseURL: this.baseURL,
      validateStatus: (status: number) => status <= 526,
      withCredentials: false,
    };

    this.request = axios.create(this.axiosConfig);
    const onReject = (err: any) => {
      if (!axios.isCancel(err)) {
        this._networkErrorHandler && this._networkErrorHandler();
      }
    }
    this.request.interceptors.response.use((response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {

      if (isErrorStatus(response.status) || isHandledErrorResponse(response)) {
        interceptors.forEach(interceptor => interceptor({ response }));
      }

      if (response.status === 401 && this._handleTokenExpiredInterceptor) {
        return this._handleTokenExpiredInterceptor({ response }, onReject as any);
      }

      return response;
    }, onReject);
  }
  public resetPostCancelTokenSource() {
    this.postCancelTokenSource = null;
  }


  public get<T>(params: IHttpActionParams<T>): AxiosPromise<T> {
    const { url, options, data, domainType, version } = params;
    const axiosInstance = this.getAxiosInstance(domainType, version);
    return axiosInstance.get(url, { ...options, params: data });
  }


  public post<T>(params: IHttpActionParams<T>): AxiosPromise<T> {
    const { url, data, options, domainType, version, performLatestOnly } = params;

    const axiosInstance = this.getAxiosInstance(domainType, version);

    if (performLatestOnly) {
      if (this.postCancelTokenSource !== null) {
        this.postCancelTokenSource.cancel('this post request is limited by single simultaneous request');
      }

      this.postCancelTokenSource = axios.CancelToken.source();

      return axiosInstance.post(
        url,
        data,
        { ...options, cancelToken: this.postCancelTokenSource.token },
      );
    }

    return axiosInstance.post(url, data, options)
  }

  public del<T>(params: IHttpActionParams<T>): AxiosPromise<T> {
    const { url, data, options, domainType, version } = params;
    const axiosInstance = this.getAxiosInstance(domainType, version);
    return axiosInstance.delete(url, { ...options, data } as any);
  }

  public put<T>(params: IHttpActionParams<T>): AxiosPromise<T> {
    const { url, data, options, domainType, version } = params;
    const axiosInstance = this.getAxiosInstance(domainType, version);
    return axiosInstance.put(url, data, options);
  }

  private getAxiosInstance(domain?: DomainType, version?: ApiVersion) {
    if (domain) {
      return axios.create({
        ...this.axiosConfig,
        baseURL: this.getBaseUrl(domain, version),
      });
    }
    return this.request;
  }

  private getBaseUrl(domain: DomainType, version?: string) {
    const urls: Record<DomainType, string> = {
      baseApi: this.baseURL,
      keyCloackSSOApi: this.ssoApiKeyCloackUrl,
      portalApi: this.portalURL,
      mockApi: this.mockApiURL,
    };
    const url = urls[domain];
    return version ? `${url}/${version}` : url;
  }

}

export default HttpActions;
