import { createBrowserHistory } from 'history';

export const helpers = (() => {
  const params = new URLSearchParams(window.location && window.location.search);
  const history = createBrowserHistory();

  return {
    getFileID(): string | null {
      return params.get('fileId');
    },

    getMediaplanID(): number | null {
      const id = params.get('mediaplanId');
      return id ? +id : null;
    },

    getMediaplanVersionID(): number | null {
      const id = params.get('mediaplanVersionId');
      return id ? +id : null;
    },

    getSheetIndex(): number {
      const param = params.get('sheet')
      return Number(param) || 0;
    },

    setSheetIndex(x: number) {
      params.set('sheet', x.toString());
      history.replace(`${history.location.pathname}?${(params as Object).toString()}`);
    }

  }
})()
