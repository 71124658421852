import { autobind } from 'core-decorators';
import { AxiosResponse } from 'axios';

import * as M from 'shared/types/models';
import { EditableMetric, MapFactSubIdData, Column, CellContent } from 'shared/types/models/EditMetrics';
import * as SM from 'shared/types/models/Server';

import { BudgetMayExceedPlanType } from 'features/EditMetrics/types';

import { TimeoutApiError } from 'services/api/error';

import BaseApi from '../../BaseApi';

import * as converters from './converters';

class Metric extends BaseApi {

  @autobind
  public async getMetrics(mediaplanId: number | string, mediaplanVersionId: number | string): Promise<M.Metric[]> {
    const response = await this.actions.get<SM.ServerMetric[]>({
      url: '/metric/clientspace-web',
      options: { headers: this.authHeaders },
      data: { mediaplanId, mediaplanVersionId },
    });
    return this.handleResponse(response, metrics => metrics.map(converters.convertServerMetric));
  }

  @autobind
  public async getAMPMetrics(): Promise<M.Metric[]> {
    const response = await this.actions.get<SM.ServerMetric[]>({
      url: '/metric/amp',
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, metrics => metrics.map(converters.convertServerMetric));
  }

  @autobind
  public async loadEditableMetrics(mediaplanId: number | string, mediaplanVersionId: number | string): Promise<EditableMetric[]> {
    const response = await this.actions.get<SM.ServerEditableMetric[]>({
      url: '/metric/edit-data',
      options: { headers: this.authHeaders },
      data: { mediaplanId, mediaplanVersionId },
    });
    return this.handleResponse(response, (data) => data.map(converters.convertEditableMetric));
  }

  @autobind
  public async getMetricsToCabinet(cabinetId: number): Promise<M.Metric[]> {
    const response = await this.actions.get<SM.ServerMetric[]>({
      url: '/metric/cabinet',
      options: { headers: this.authHeaders },
      data: { cabinetId },
    });
    return this.handleResponse(response, metrics => metrics.map(converters.convertServerMetric));
  }

  @autobind
  public async loadFact(
    mediaplanId: number,
    mediaplanVersionId: number,
    factParams: SM.FactParams[],
  ): Promise<MapFactSubIdData> {
    const response = await this.actions.post<SM.FactDataResponse>({
      url: `/mediaplan/${mediaplanId}/fact`,
      data: {
        factMetrics: factParams.map(({ subId, dates }) => ({
          subMetricId: subId,
          dataPeriods: dates.map(range => ({ periodStart: range.start, periodEnd: range.end })),
        })),
        mediaplanVersionId,
      },
      options: { headers: this.authHeaders, timeout: 120000 },
    }) as AxiosResponse<SM.FactDataResponse> | undefined;

    if (response === undefined) {
      throw new TimeoutApiError({ response: null });
    }

    return this.handleResponse(response, data => converters.convertFactSubIdsData(factParams, data.factDataContent));
  }

  @autobind
  public async saveMediaplanEditedMetrics(data: SaveEditedMediaplanData): Promise<void> {
    const { mediaplanId, mediaplanVersionId, content, columns, budgetCanExceedPlan } = data;
    const metrics = converters.convertEditableMetrics(columns);
    const convertedContent = converters.convertEditabaleMetricsContent(content);
    const response = await this.actions.post<any[]>({
      url: `/mediaplan/${mediaplanId}/save-edited-data`,
      options: { headers: this.authHeaders },
      data: {
        mediaplanVersionId,
        mediaplan: {
          rowIdColumnNum: 0,
          manualEditedDataContent: convertedContent,
          manualEditedDataMapping: { metrics },
          planFactSettings: {
            budgetCannotExceedPlan: converters.convertClientBudgetExceedParameter(budgetCanExceedPlan),
          },
        },
      },
    });
    this.handleResponse(response);
  }

  @autobind
  public async saveCustomMetric(data: SaveCustomMetricData): Promise<M.Metric> {
    const { mediaplanId, mediaplanVersionId, title, description } = data;
    const response = await this.actions.post<SM.ServerMetric>({
      url: '/metric/add-custom',
      options: { headers: this.authHeaders },
      data: {
        mediaplanId, mediaplanVersionId, title, description,
      },
    });
    return this.handleResponse(response, data => converters.convertServerMetric(data));
  }

  @autobind
  public async editCustomMetric(data: EditCustomMetricData): Promise<M.Metric> {
    const { customMetricId, title, description } = data;
    const response = await this.actions.post<SM.ServerMetric>({
      url: '/metric/edit-custom',
      options: { headers: this.authHeaders },
      data: {
        customMetricId, title, description,
      },
    });
    return this.handleResponse(response, data => converters.convertServerMetric(data));
  }

  @autobind
  public async removeCustomMetric(id: number): Promise<M.Metric> {
    const response = await this.actions.post<SM.ServerMetric>({
      url: `/metric/remove-custom?customMetricId=${id}`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, data => converters.convertServerMetric(data));
  }

  @autobind
  public async restoreCustomMetric(id: number): Promise<M.Metric> {
    const response = await this.actions.post<SM.ServerMetric>({
      url: `/metric/restore-custom?customMetricId=${id}`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, data => converters.convertServerMetric(data));
  }
}

type SaveEditedMediaplanData = {
  mediaplanId: number;
  mediaplanVersionId: number;
  columns: Column[];
  content: CellContent[][];
  budgetCanExceedPlan: BudgetMayExceedPlanType;
};

type CustomMetricData = {
  title: string;
  description?: string;
};

type SaveCustomMetricData = CustomMetricData & {
  mediaplanId: number;
  mediaplanVersionId: number;
}

type EditCustomMetricData = CustomMetricData & {
  customMetricId: number;
}

export default Metric;
