import Handsontable from 'handsontable';
import { ColumnConditions } from 'handsontable/plugins/filters';

export function addFilters(
  ht: Handsontable,
  conditionsCollection: ColumnConditions[],
): void {
  const filterPlugin = ht.getPlugin('filters');

  conditionsCollection.forEach(({ column, operation, conditions }) =>
    conditions.forEach(condition =>
      filterPlugin.addCondition(column, condition.name as string, condition.args, operation)
    )
  );
}
