import { makeCommunicationReducer } from 'redux-make-communication';
import { combineReducers } from 'redux';

import * as TS from '../../types';
import { initialState } from '../intialState';

export const communication = combineReducers<TS.ReduxState['communication']>({
  loadingAmpCampaigns: makeCommunicationReducer<TS.LoadAmpCampaigns, TS.LoadAmpCampaignsSuccess, TS.LoadAmpCampaignsFail>(
    'AMP_CAMPAIGNS_SERVICE:LOAD_AMP_CAMPAIGNS',
    'AMP_CAMPAIGNS_SERVICE:LOAD_AMP_CAMPAIGNS_SUCCESS',
    'AMP_CAMPAIGNS_SERVICE:LOAD_AMP_CAMPAIGNS_FAIL',
    initialState.communication.loadingAmpCampaigns,
  ) as any,
});
