import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

export function convertServerValueType(type: SM.ServerValueType): M.ValueType {
  const convertMap: Record<SM.ServerValueType, M.ValueType> = {
    DOUBLE: 'double',
    DATE: 'date',
    STRING: 'string',
    DICTIONARY: 'dictionary',
    INTEGER: 'integer',
    PERCENT: 'percent',
    BOOL: 'bool',
  };
  return convertMap[type];
}
