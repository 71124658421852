import { Column, TableCell, RatioColumn, CellChange } from 'shared/types/models/EditMetrics';

import { stylesInvalidRatioCell, stylesRatioCell } from '../../../constants';

import { getRelationColumnsIndexesByRatioColumn } from '../../columns';

import { getCellsByColumnIndex, applyChangesToCells } from '../baseOperations';

import { calcRatioCellValue } from './calcRatioCellValue';
import { isInvalidRatioCell } from './isInvalidRatioCell';

type Arguments = {
  ratioColumns: Column[];
  columns: Column[];
  cells: TableCell[][];
};

export function setValuesToRatioCells(args: Arguments) {
  const { ratioColumns, cells, columns } = args;
  const changes = makeChangesForRatioCells({ ratioColumns, columns, cells });
  const updatedCells = applyChangesToCells(changes, cells);
  return { updatedCells, changes };
}

function makeChangesForRatioCells(args: Arguments): CellChange[] {
  const { ratioColumns, cells, columns } = args;
  const ratioColumnsEntries = Object.entries(columns)
    .filter(([_, column]) => ratioColumns.find(x => x.id === column.id)) as [string, RatioColumn][];

  return ratioColumnsEntries.flatMap(([ratioColumnIndex, ratioColumn]) => {
    const relationColumnsIndexes = getRelationColumnsIndexesByRatioColumn(columns, ratioColumn);
    if (relationColumnsIndexes) {
      const { planColumnIndex, factColumnIndex } = relationColumnsIndexes;

      const planColumnCells = getCellsByColumnIndex(cells, planColumnIndex);
      const factColumnCells = getCellsByColumnIndex(cells, factColumnIndex);

      return factColumnCells.map<CellChange>((cell, rowIndex) => {
        const plan = planColumnCells[rowIndex].content;
        const fact = cell.content;
        const ratioCellValue = calcRatioCellValue(plan, fact);
        return {
          coordinate: { row: rowIndex, column: +ratioColumnIndex },
          cellProperties: {
            content: ratioCellValue,
            style: isInvalidRatioCell(plan, fact) ? stylesInvalidRatioCell : stylesRatioCell,
          }
        };
      });

    }
    return null;
  }).filter(x => x !== null) as CellChange[];
}
