import * as React from 'react';
import cx from 'classnames';
import block from 'bem-cn';
import { getEnvParams } from 'core/getEnvParams';
import { Link } from '@material-ui/core';

import { getClientPeriodDate } from 'shared/helpers';
import * as M from 'shared/types/models';
import { CardProps } from 'shared/view/elements';
import { CampaignInfoCard, CampaignInfoCardList } from '../CampaignInfoCard';

import './style.scss';

type Props = CardProps & {
  className?: string;
  info: M.CsCampaignInfo;
  isPeriodVisible?: boolean;
};

const b = block('cs-campaign-info');

function CsCampaignInfo(props: Props) {
  const {
    className,
    info: {
      campaignIdCs,
      campaignNameCs,
      advertiserName,
      dateStart,
      dateEnd,
      mediaplanName,
    },
    isPeriodVisible = true,
    ...cardProps
  } = props;

  const clientSpaceCampaignInfo = [
    {
      title: 'Кампания: ',
      value: (
        <Link
          href={`${getEnvParams().portalURL}/campaigns/${campaignIdCs}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {campaignNameCs}
        </Link>
      ),
    },
    {
      title: 'Рекламодатель: ',
      value: advertiserName,
    },
    isPeriodVisible ? {
      title: 'Период: ',
      value: `${getClientPeriodDate(dateStart)} - ${getClientPeriodDate(
        dateEnd,
      )}`,
    } : null,
    {
      title: 'Медиаплан: ',
      value: mediaplanName,
    },
  ].filter(Boolean) as CampaignInfoCardList;

  return (
    <div className={cx(b().toString(), className)}>
      <CampaignInfoCard
        title="Кампания Client Space"
        list={clientSpaceCampaignInfo}
        {...cardProps}
      />
    </div>
  );
}

export { CsCampaignInfo };
