import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { ICommunication } from 'shared/types/redux';

export function makeCommunicationPendingReducer<
  T extends { communication: Record<keyof T['communication'], ICommunication>},
  U extends AnyAction
>(
  communicationType: keyof T['communication'],
  cb?: (state: T, action: U) => void,
) {
  return (state: T, action: U) => {
    state.communication[communicationType] = { error: '', isRequesting: true, completed: false };

    if (cb) {
      cb(state, action);
    }
  };
}

export function makeCommunicationRejectedReducer<
  T extends { communication: Record<keyof T['communication'], ICommunication>},
  U extends PayloadAction<{ error: string }>
>(
  communicationType: keyof T['communication'],
  cb?: (state: T, action: U) => void,
) {
  return (state: T, action: U) => {
    state.communication[communicationType] = { error: action.payload.error, isRequesting: false, completed: true };

    if (cb) {
      cb(state, action);
    }
  };
}

export function makeCommunicationFulfilledReducer<
  T extends { communication: Record<keyof T['communication'], ICommunication>},
  U extends AnyAction
>(
  communicationType: keyof T['communication'],
  cb?: (state: T, action: U) => void,
) {
  return (state: T, action: U) => {
    state.communication[communicationType] = { error: '', isRequesting: false, completed: true };

    if (cb) {
      cb(state, action);
    }
  };
}
