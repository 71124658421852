import { ICookies, CookiesKey } from './types';
import JSCookies from 'js-cookie';

class Cookies implements ICookies {

  public getCookie(name: CookiesKey): string | null {
    const match = JSCookies.get(name);
    return match || null;
  }

  public setCookie(name: CookiesKey, value: string): void {
    const rootDomain = window.location.hostname.split('.').slice(-2).join('.');
    const currentYear = new Date().getFullYear();
    JSCookies.set(name, value, { domain: rootDomain, expires: new Date(String(currentYear + 1)) });
  }
}

export default Cookies;
