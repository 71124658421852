import { Column, TableCell, EditableMetric } from 'shared/types/models/EditMetrics';

import { getRatioColumnsIndexes, getReadOnlyColumnsIndexes } from '../../columns';

import { updateRatioColumnsStyles } from './updateRatioColumnsStyles';
import { updateReadonlyColumnsStyles } from './updateReadonlyColumnsStyles';

type Arguments = {
  columnsIndexes: number[];
  columns: Column[];
  cells: TableCell[][];
  metrics: EditableMetric[];
};

export function updateCellsStyles(args: Arguments) {
  const { columnsIndexes, columns, cells, metrics } = args;

  const ratioColumnsIndexes = getRatioColumnsIndexes(columns).filter(x => columnsIndexes.includes(x));
  const readOnlyColumnsIndexes = getReadOnlyColumnsIndexes(columns, metrics).filter(x => columnsIndexes.includes(x));
  // TODO: return when did markup on the back
  // const markupBudgetColumnsIndexes = getMarkupBudgetColumnsIndexes(columns).filter(x => columnsIndexes.includes(x));

  const withReadOnlyStylesCells = updateReadonlyColumnsStyles(cells, readOnlyColumnsIndexes);
  const withRatioStylesCells = updateRatioColumnsStyles(withReadOnlyStylesCells, ratioColumnsIndexes);
  return withRatioStylesCells;
  // const allComparingBudgetIndexes = markupBudgetColumnsIndexes.flatMap(index => [index - 1, index]);
  // return highlightNotEqualMarkupBudgetCells(withRatioStylesCells, allComparingBudgetIndexes);
}
