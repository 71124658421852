import * as R from 'ramda';

import format from 'string-template';

import { ErrorMessage, errorColumnsTypes, errorTemplates } from '../error';

export function formatErrorTemplates(errorMessage: ErrorMessage, params: Record<string, string>) {
  const templatedParams = R.mapObjIndexed(
    (value, key) => key === 'columnType' ? errorColumnsTypes[value] : value,
    params,
  );
  const template = errorTemplates[errorMessage];
  if (!template) {
    return 'Отсутствует релевантный шаблон для отображения ошибки с сервера';
  }
  return format(template, templatedParams);
}