import * as R from 'ramda';

import { IAppReduxState } from 'shared/types/app';
import makeSelectFeatureState from 'shared/helpers/redux/makeSelectFeatureState';
import { makeCommunicationSelector } from 'shared/helpers/redux';
import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import { MetricSubId, EditableMetric, TableCell, Column } from 'shared/types/models/EditMetrics';
import { csDictionaryAttributesIDs } from 'shared/constants';
import { GAMetric, SeparatedWeeks, MetricsParameters, SettingsMetric } from '../types';
import { markupBudgetMetricId } from '../constants';
import { createSelector } from 'reselect';

export const selectFeatureState = makeSelectFeatureState('EditMetrics');
export const selectCommunication = makeCommunicationSelector(selectFeatureState);

export function selectMediaplan(state: IAppReduxState): M.Mediaplan | null {
  return selectFeatureState(state).data.mediaplan;
}

export function selectAttributes(state: IAppReduxState): SM.ServerAttribute[] {
  return selectFeatureState(state).data.attributes;
}

export function selectAttributeIdsForCSDictionaryLoading(state: IAppReduxState): number[] {
  return csDictionaryAttributesIDs.filter((x) => selectAttributes(state).map((x) => x.id).includes(x));
}

export function selectNeedCSDictionaryLoading(state: IAppReduxState): boolean {
  return selectAttributeIdsForCSDictionaryLoading(state).length > 0;
}

export function selectMetrics(state: IAppReduxState): EditableMetric[] {
  return selectFeatureState(state).data.metrics;
}

export function selectCabinets(state: IAppReduxState): M.Cabinet[] {
  return selectFeatureState(state).data.cabinets;
}

export function selectColumns(state: IAppReduxState): Column[] {
  return selectFeatureState(state).edit.columns;
}

export function selectCells(state: IAppReduxState): TableCell[][] {
  return selectFeatureState(state).edit.cells;
}

export function selectSettingsMetric(state: IAppReduxState): SettingsMetric | null {
  return selectFeatureState(state).edit.settingsMetric;
}

export function selectSeparatedWeeks(state: IAppReduxState): SeparatedWeeks | null {
  return selectFeatureState(state).edit.weeks;
}

export function selectMetricParameters(state: IAppReduxState): MetricsParameters {
  return selectFeatureState(state).edit.parameters;
}

export function selectParameterValue(state: IAppReduxState, parameter: keyof MetricsParameters) {
  return selectFeatureState(state).edit.parameters[parameter];
}

export function selectIsShowSettingsMetricModal(state: IAppReduxState) {
  return selectFeatureState(state).edit.isShowSettingsMetricModal;
}

export function selectUpdatingColumnsIDs(state: IAppReduxState) {
  return selectFeatureState(state).edit.updatingColumnIDs;
}

export function selectAllMetrics(state: IAppReduxState): EditableMetric[] {
  const budgetMetrics = selectBudgetMetrics(state);
  const standardMetrics = selectStandardMetrics(state);
  return [...budgetMetrics, ...standardMetrics];
}

export function selectOriginalBudgetMetric(state: IAppReduxState): EditableMetric | null {
  const metrics = selectMetrics(state);
  const originalBudgetMetric = metrics.find(x => x.id === markupBudgetMetricId);
  return originalBudgetMetric ? originalBudgetMetric : null;
}

export function selectMarkupBudgetMetricSubId(state: IAppReduxState): number | null {
  const originalBudgetMetric = selectOriginalBudgetMetric(state);
  return originalBudgetMetric ? originalBudgetMetric.subIds[0].subId : null;
}

export function selectSubIdDescriptionByMetricId(state: IAppReduxState, metricId: number): MetricSubId | null {
  const metrics = selectAllMetrics(state);
  const foundMetric = metrics.find(x => x.id === metricId);
  if (!foundMetric || !foundMetric.subIds.length) {
    return null;
  }
  const [subIdInfo] = foundMetric.subIds;
  return subIdInfo;
}

export const selectArchivedMetricsIDs = createSelector(
  selectMetrics,
  (metrics) => metrics.filter(x => x.isCustomArchived).map(x => x.id),
);

export const selectBudgetMetrics = createSelector(
  selectMetrics,
  selectArchivedMetricsIDs,
  (metrics, archivedMetricsIDs) => metrics.filter(x => x.metricGroups.includes('budget') && !archivedMetricsIDs.includes(x.id)),
);

export const selectCabinetMetrics = createSelector(
  selectMetrics,
  (metrics) => metrics.filter(x => x.cabinetId),
);

export const selectStandardMetrics = createSelector(
  selectMetrics,
  selectArchivedMetricsIDs,
  (metrics, archivedMetricsIDs) => metrics.filter(m =>
    !m.cabinetId
    && !m.isBudgetMetric
    && !archivedMetricsIDs.includes(m.id)
  ),
);

export const selectGAMetrics = createSelector(
  selectCabinetMetrics,
  selectCabinets,
  (cabinetMetrics, cabinets) => {
    return R.pipe(
      R.groupBy<EditableMetric>((metric) => String(metric.cabinetId)),
      R.toPairs,
      R.reduce<[string, EditableMetric[]], GAMetric[]>((acc, [cabinetId, metrics]) => {
        const cabinet = cabinets.find(c => c.id === +cabinetId);
        return cabinet ? [...acc, { cabinet, metrics }] : acc;
      }, []),
    )(cabinetMetrics);
  },
);
