import * as React from 'react';
import { block } from 'bem-cn';

import { Modal } from 'shared/view/elements';
import { connect } from 'react-redux';
import { IAppReduxState } from 'shared/types/app';
import { selectors, actionCreators } from 'services/ReloadPage/redux';

import './style.scss';

type StateProps = {
  isOpen: boolean;
  title: string;
};

type ActionProps = typeof containerActionCreators;
type Props = StateProps & ActionProps;

const b = block('reload-page-modal');

class ReloadPageModal extends React.PureComponent<Props> {

  public render() {
    const { isOpen, title } = this.props;
    return (
        <Modal
          modalClasses={{ root: b().toString() }}
          rootStyles={{ zIndex: 1500 }}
          title={title}
          titleType="error"
          accessButtonText="Перезагрузить страницу"
          isShow={isOpen}
          onClose={this.stub}
          onAccept={this.reloadPage}
        />
    );
  }

  private reloadPage() {
    window.location.reload();
  }

  private stub() {}
}

function mapState(state: IAppReduxState): StateProps {
  return {
    isOpen: selectors.selectIsOpen(state),
    title: selectors.selectModalTitle(state),
  };
}

const containerActionCreators = {
  closeModal: actionCreators.closeModal
};

export default connect(mapState, containerActionCreators)(ReloadPageModal);
