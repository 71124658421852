import * as R from 'ramda';

export function updateItemsByPath<T, P>(path: R.Path, updater: (byPathValue: P) => P, items: T[]): T[] {
  return items.map(item => updateItemByPath(path, updater, item));
}

export function updateItemByPath<T, P>(path: R.Path, updater: (byPathValue: P) => P, item: T): T {
  return R.over(
    R.lensPath(path),
    byPathValue => updater(byPathValue),
    item,
  );
}

export function getCurriedUpdateItemsByPath<T, P>() {
  return R.curry<(path: (string | number)[], updater: (byPathValue: P) => P, items: T[]) => T[]>(updateItemsByPath);
}

export function getCurriedUpdateItemByPath<T, P>() {
  return R.curry<(path: (string | number)[], updater: (byPathValue: P) => P, item: T) => T>(updateItemByPath);
}
