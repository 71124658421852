import { ReduxState } from '../types/ReduxState';
import { initialCommunicationField } from 'shared/helpers/redux';
import { initialCommunicationState } from 'shared/constants';
import * as services from 'services';

export const initialState: ReduxState = {
  data: {
    mediaplanID: services.URLSearchParams.helpers.getMediaplanID(),
    mediaplanVersionID: services.URLSearchParams.helpers.getMediaplanVersionID(),
    attributes: [],
    ampAttributes: [],
    metrics: [],
    ampMetrics: [],
    cabinets: [],
    mediaplan: null,
    csDictionariesMap: {},
    csCampaignInfo: null,
    csAmpCampaignInfo: null,
  },
  communication: {
    loadingAttributes: initialCommunicationField,
    loadingMetrics: initialCommunicationField,
    loadingMediaplan: initialCommunicationField,
    loadingCabinets: initialCommunicationField,
    loadingAmpAttributes: initialCommunicationState,
    loadingAmpMetrics: initialCommunicationState,
    loadingCSDictionaries: initialCommunicationState,
    loadingCsCampaignInfo: initialCommunicationState,
    loadingCsAmpCampaignInfo: initialCommunicationState,
  }
};
