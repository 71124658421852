import { initialState } from '../intialState';
import * as TS from '../../types';

export function edit(state: TS.ReduxState['edit'] = initialState.edit, action: TS.ServiceAction): TS.ReduxState['edit'] {
  switch (action.type) {
    case 'TEMPLATES_SERVICE:LOAD_DICTIONARIES_SUCCESS':
      return {
        ...state,
        dictionaries: action.payload.dictionaries,
      };

    case 'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY_SUCCESS':
      return {
        ...state,
        dictionaries: state.dictionaries.map(x =>
          x.advertiserId === action.payload.advertiserId ? { ...x, brands: action.payload.brands } : x,
        ),
      };

    case 'TEMPLATES_SERVICE:LOAD_TEMPLATES_SUCCESS':
      return {
        ...state,
        templates: action.payload.templates,
      };

    case 'TEMPLATES_SERVICE:DELETE_TEMPLATE_SUCCESS':
      return {
        ...state,
        templates: state.templates.map(x => x.id === action.payload.templateId ? { ...x, isDeleted: true } : x),
      };

    case 'TEMPLATES_SERVICE:SAVE_TEMPLATE_SUCCESS':
      return {
        ...state,
        templates: state.templates.concat(action.payload.template),
      };

    default:
      return state;
  }
}
