import * as R from 'ramda';
import { ColumnConditions } from 'handsontable/plugins/filters';

export function shiftRightFilterColumns(
  shift: number,
  conditions: ColumnConditions[],
): ColumnConditions[] {
  return conditions.map(cond => R.assoc('column', cond.column + shift, cond));
}

export function shiftLeftFilterColumns(
  shift: number,
  conditions: ColumnConditions[],
): ColumnConditions[] {
  return conditions.map(cond => R.assoc('column', cond.column - shift, cond));
}
