import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

import { convertServerSourceType, convertServerValueType, convertServerValidationType } from '../../helpers/converters';

export function convertServerAttribute(attr: SM.ServerAttribute): M.Attribute {
  const {
    dataGroup, description, id, isRequired, relParamId, sourceType, title, valueType, cabinetDictionaryId,
    isVisibleOnExtractDataPage, titleAmp, isEditableOnExportAmpFactPage, multipleValues, validationType, isVisibleOnEditDataPageIfNotEmpty,
  } = attr;
  return {
    kind: 'attribute',
    id,
    title,
    dataGroup,
    description,
    isRequired,
    relParamId,
    isVisibleOnExtractDataPage,
    titleAmp,
    isEditableOnExportAmpFactPage,
    isVisibleOnEditDataPageIfNotEmpty,
    cabinetDictionaryId,
    sourceType: convertServerSourceType(sourceType),
    valueType: convertServerValueType(valueType),
    multipleValues,
    validationType: validationType && convertServerValidationType(validationType),
  };
}

export function convertDictionary(dictionary: SM.ServerDictionary): M.Dictionary {
  return {
    id: dictionary.dictionaryId,
    values: dictionary.dictionaryValues.map(dictValue => ({
      id: dictValue.id,
      value: dictValue.value,
      ampID: dictValue.parameters?.amp_id ?? null,
      categoryID: dictValue.parameters?.category_id ?? null,
      categoryName: dictValue.parameters?.category_name ?? null,
      updatableAttributeID: dictValue.manualParameters?.updatable_attribute_id ?? null,
      valueForExcel: dictValue.manualParameters?.value_for_excel ?? null,
      externalId: dictValue.externalId,
    })),
    cascadeGroup: dictionary.cascadeGroup,
  };
}

export function convertDictionarySubjectData(x: SM.ServerDictionarySubject): M.DictionarySubject {
  return {
    id: x.id,
    text: x.name,
    decimalSigns: x.decimalSigns !== undefined ? x.decimalSigns : null,
  };
}

export function convertMetadataAmpAttribute(
  metadata: SM.MetadataForAttributeAMP,
  dictionaries: M.DictionarySubject[]
): M.AttributeAMP {
  return {
    attributeID: metadata.relParamId,
    id: metadata.id,
    subjects: dictionaries,
    isExportAMP: metadata.isExportAMP,
    isExportAMPRequired: metadata.isExportAMPRequired,
    ampCascadeGroup: metadata.ampCascadeGroup ?? null,
    title: metadata.title,
    titleAmp: metadata.titleAmp,
    isEditableOnExportAmpFactPage: metadata.isEditableOnExportAmpFactPage,
    valueType: convertAmpAttributeValueType(metadata.valueType),
    isEditableOnExportAmpPage: metadata.isEditableOnExportAmpPage !== false,
  }
}

export function convertAmpAttributeValueType(valueType: SM.MetadataForAttributeAMP['valueType']): M.AttributeValueType {
  return valueType === 'DICTIONARY' ? 'dictionary' : 'string';
}
