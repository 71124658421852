import * as M from 'shared/types/models';

export function getFormattedTitleAmp(column: M.ExportAMP.Column | M.ExportFactAMP.Column) {
  if ('subType' in column && column.subType !== 'fact') {
    return '';
  }
  return (column.kind === 'attribute' || column.kind === 'metric') && column.titleAmp
    ? `<br>${column.titleAmp.replaceAll('\\', '/<br>')}`
    : '';
}
