import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { Beforeunload } from 'react-beforeunload';

import { IAppReduxState } from 'shared/types/app';
import { ReloadPage } from 'services';

type StateProps = {
  isReloadPageModalOpen: boolean;
};

type Props = RouteProps & StateProps;

class RouteWithConfirmComponent extends React.PureComponent<Props> {
  render() {
    const { isReloadPageModalOpen, ...routeProps } = this.props;
    return (
      <>
        <Route {...routeProps} />
        {!isReloadPageModalOpen && <Beforeunload onBeforeunload={this.handleBeforeUnload} />}
      </>
    );
  }

  private handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // Starting in Chrome 51, a custom string will no longer be shown to the user.
    // See https://developer.chrome.com/blog/chrome-51-deprecations/#remove-custom-messages-in-onbeforeunload-dialogs
    return e.returnValue = 'Вы уверены? Все несохраненные изменения будут утеряны';
  };
}

function mapState(state: IAppReduxState): StateProps {
  return {
    isReloadPageModalOpen: ReloadPage.selectors.selectIsOpen(state),
  };
}

export const RouteWithConfirm = connect(mapState)(RouteWithConfirmComponent);
