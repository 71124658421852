import { put, select } from 'redux-saga/effects';
import { CommonData } from 'services';
import { selectors } from '../../index';

export function* loadAttributesComplete() {
  yield put(
    CommonData.actionCreators.loadCSDictionaries({
      attributeIds: yield select(selectors.selectAttributeIdsForCSDictionaryLoading),
    }),
  );
}
