import ApiError, { IApiError } from './ApiError';

export default class TimeoutApiError extends ApiError {
  public isTimeoutError = true;

  constructor({ response }: IApiError) {
    super({ response });
    this.message = 'Истечение времени запроса';
  }
}
