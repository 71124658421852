import { call, put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { CommonData } from 'services';
import { tryCatch } from 'services/ErrorTracking';
import * as actionCreators from '../../actionCreators';

export function* loadEditableMetrics({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanID: number | null = yield select(CommonData.selectors.selectMediaplanID);
      const mediaplanVersionID: number | null = yield select(CommonData.selectors.selectMediaplanVersionID);

      if (mediaplanID === null || mediaplanVersionID === null) {
        throw Error('not found mediaplanVersionId or mediaplanId');
      }

      const factualMetrics: ReturnPromisedType<typeof api.metric.loadEditableMetrics> =
        yield call(api.metric.loadEditableMetrics, mediaplanID, mediaplanVersionID);
      yield put(actionCreators.loadMetricsCompleted(factualMetrics));
    },
    *failed(_, message) {
      yield put(actionCreators.loadMetricsFailed(message));
    },
  });
}
