import { isHasLineBreaks } from './isNeedAddLineBreak';

export function getMostLongLineInTitle(title: string) {
  if (isHasLineBreaks(title)) {
    return getLongestString(title.split(/<br\s*\/?>/i));
  }
  return title;
}

export function getLongestString(arr: string[]) {
  return arr.reduce((maxLine, line) => line.length > maxLine.length  ? line : maxLine, '');
}
