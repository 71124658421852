import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

export function convertServerValidationType(type: SM.ServerValidationType): M.ValidationType {
  const convertMap: Record<SM.ServerValidationType, M.ValidationType> = {
    Url: 'url',
    '*': 'schedule', // TODO: replace '*'?
  };
  return convertMap[type];
}
