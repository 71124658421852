import { autobind } from 'core-decorators';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import BaseApi from '../../BaseApi';
import { convertServerDictionaries, convertServerBrandDictionary } from './converters';

class Template extends BaseApi {
  @autobind
  public async getTemplates(type: M.TemplateType): Promise<M.Template[]> {
    const response = await this.actions.get<M.Template[]>({
      url: '/mediaplan-template/all',
      options: { headers: this.authHeaders },
      data: { type },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getDictionaries(): Promise<M.TemplateDictionary[]> {
    const response = await this.actions.get<SM.TemplateDictionaries>({
      url: '/mediaplan-template/dictionaries',
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, convertServerDictionaries);
  }

  @autobind
  public async loadBrandDictionary(advertiserId: number): Promise<M.DictionaryItem[]> {
    const response = await this.actions.get<SM.TemplateDictionaries>({
      url: `mediaplan-template/dictionaries/${advertiserId}/`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, convertServerBrandDictionary);
  }

  @autobind
  public async getTemplate(id: number, type: M.TemplateType): Promise<M.TemplateData> {
    const response = await this.actions.get<M.TemplateData>({
      url: `/mediaplan-template/${id}`,
      options: { headers: this.authHeaders },
      data: { type },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async applyTemplate({ templateId, type, ...data }: ApplyTemplateArgs): Promise<any> {
    const response = await this.actions.post({
      url: `/mediaplan-template/${templateId}/apply?type=${type}`,
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }

  @autobind
  public async saveTemplate({ type, ...data }: SaveTemplateArgs): Promise<M.Template> {
    const response = await this.actions.post<M.Template>({
      url: `/mediaplan-template/create?type=${type}`,
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }

  @autobind
  public async deleteTemplate(id: number): Promise<any> {
    const response = await this.actions.del({
      url: `/mediaplan-template/${id}`,
      options: { headers: this.authHeaders }
    });
    return this.handleResponse(response);
  }
}

type ApplyTemplateArgs = {
  templateId: number;
  mediaplanId: number;
  mediaplanVersionId: number;
  type: M.TemplateType;
};

type SaveTemplateArgs = {
  name: string;
  year: number;
  templateJson: string;
  mediaplanId: number;
  mediaplanVersionId: number;
  type: M.TemplateType;
};

export default Template;
