import { combineReducers } from 'redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../types';

import { dataReducer } from './data';
import { editReducer } from './edit';
import { communicationReducer } from './communication';

export default combineReducers<NS.ReduxState>({
  data: dataReducer,
  edit: editReducer,
  communication: communicationReducer,
} as ReducersMap<NS.ReduxState>);
