import { AttributeColumn } from 'shared/types/models/EditMetrics';
import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import { isEmptyCellContent } from 'shared/helpers/cell/cell';

import { makeAttributeColumn } from '../makers';

export function initAttributeColumns(
  metaAttributes: SM.MetaAttribute[],
  attributes: SM.ServerAttribute[],
  mediaplan: M.Mediaplan,
): AttributeColumn[] {
  return metaAttributes.reduce<AttributeColumn[]>((acc, metaAttribute) => {
    const { id, columnNum } = metaAttribute;
    const foundAttribute = attributes.find(x => x.id === id);

    if (foundAttribute === undefined) {
      return acc;
    }

    if (foundAttribute.isVisibleOnEditDataPageIfNotEmpty) {
      const isEmpty = !mediaplan.content.some(row => row.some((cell, i) => i === columnNum && !isEmptyCellContent(cell)));
      if (isEmpty) {
        return acc;
      }
    }

    return [...acc, makeAttributeColumn(foundAttribute, columnNum)];
  }, []);
}
