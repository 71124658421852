import Handsontable from 'handsontable';

import { Column } from 'shared/types/models/EditMetrics';
import { IAppReduxState } from 'shared/types/app';

import { makeMetaByColumn } from '../cellsMeta';

type Arguments = {
  hotInstance: Handsontable;
  columns: Column[];
  rowsIndexes: number[];
  getState: () => IAppReduxState;
};

export function initColumnsMeta(args: Arguments) {
  const { getState, rowsIndexes, columns, hotInstance } = args;

  rowsIndexes.forEach(row => {
    columns.forEach((column, colIndex) => {
      const meta = makeMetaByColumn(column, getState);
      hotInstance.setCellMetaObject(row, colIndex, meta);
    });
  });
  hotInstance.render();
  hotInstance.validateCells();
}
