import * as React from 'react';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import { Events } from 'handsontable/pluginHooks';
import 'handsontable/dist/handsontable.full.css';
import 'handsontable/languages/ru-RU';
import block from 'bem-cn';
import { autobind } from 'core-decorators';

import { ReactTooltip } from 'shared/view/components';
import * as M from 'shared/types/models';
import { GeneralCellContent } from 'shared/types/models/Server';
import { defaultCellConverter } from 'shared/helpers/cell/defaultCellConverter';
import { getDefaultCellTooltip } from 'shared/helpers/cell/getDefaultCellTooltip';

import './style.scss';

const b = block('hot-table-wrapper');

export type HotTableWrapperProps = {
  initialSettings: Omit<Handsontable.GridSettings, 'data'>;
  initHotTable(x: HotTable | null): void
  cells: (M.AbstractCell | M.StandardContent)[][];
  cellConverter: 'standard' | ((cell: any) => GeneralCellContent);
  showDefaultTooltip?: boolean;
  id?: string;
  isNeedRerender?(prevProps: HotTableWrapperProps, nextProps: HotTableWrapperProps): boolean;
}

type Props = HotTableWrapperProps;

class HotTableWrapper extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: HotTableWrapperProps) {
    if (this.props.isNeedRerender) {
      return this.props.isNeedRerender(this.props, nextProps);
    }
    return false;
  }

  public render() {
    const { initialSettings, initHotTable, id, cells } = this.props;
    const cellConverter = this.props.cellConverter === 'standard' ? defaultCellConverter : this.props.cellConverter;

    return (
      <div className={b()}>
        <div id="date-editor-input-wrapper" />
        <ReactTooltip.Component />
        <HotTable
          settings={{
            ...initialSettings,
            language: 'ru-RU',
            beforeOnCellMouseOver: HotTableWrapper.beforeOnCellMouseOver,
            beforeOnCellMouseOut: HotTableWrapper.beforeOnCellMouseOut,
            beforeRenderer: this.beforeRenderer,
            afterRenderer: this.afterRenderer,
            data: cells.map(row => row.map(cellConverter))
          }}
          ref={initHotTable}
          id={id}
        />
      </div>
    );
  }

  private static beforeOnCellMouseOver(event, coords, TD) {
    if (TD.dataset.tip) {
      ReactTooltip.show(TD);
    }
  }

  private static beforeOnCellMouseOut(event, coords, TD) {
    if (TD.dataset.tip) {
      ReactTooltip.hide(TD);
    }
  }

  private beforeRenderer(TD: HTMLTableCellElement) {
    delete TD.dataset.tip;
  }

  @autobind
  private afterRenderer(...args: Parameters<NonNullable<Events['afterRenderer']>>) {
    const { initialSettings, cells, showDefaultTooltip = true } = this.props;
    initialSettings.afterRenderer?.(...args);

    const [TD, row, col] = args;
    const cell = cells[row]?.[col];

    if (!showDefaultTooltip || TD.dataset.tip || !M.typeGuards.isAbstractCell(cell)) return;
    const tooltip = getDefaultCellTooltip(cell);
    if (tooltip) {
      TD.dataset.tip = tooltip;
    }
  }
}

export { HotTableWrapper }
