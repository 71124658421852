import * as React from 'react';
import { bind } from 'decko';

import { block } from 'bem-cn';

import './Checkbox.scss';

interface IOwnProps {
  label: string;
  checked: boolean;
  onChange?(value: IOwnProps['value']): void;
  disabled?: boolean;
  value?: string | number;
}

const b = block('checkbox');

class Checkbox extends React.PureComponent<IOwnProps> {
  public render() {
    const { label, checked, value, disabled = false } = this.props;
    return (
      <label className={b({ disabled })}>
        <input
          className={b('real-input')}
          type="checkbox"
          onChange={this.onChange}
          checked={checked}
          value={value}
          disabled={disabled}
        />
        {label}
        <span className={b('checkmark', { checked })} />
      </label>
    );
  }

  @bind
  private onChange() {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange(value);
    }
  }
}

export { Checkbox };
