import * as M from 'shared/types/models';

import { IAppReduxState } from 'shared/types/app';
import makeSelectFeatureState from 'shared/helpers/redux/makeSelectFeatureState';
import { makeCommunicationSelector } from 'shared/helpers/redux';

export const selectFeatureState = makeSelectFeatureState('AmpCampaigns');
export const selectCommunication = makeCommunicationSelector(selectFeatureState);

export function selectAmpCampaigns(state: IAppReduxState): M.AmpCampaigns | null {
  return selectFeatureState(state).data.ampCampaigns;
}

export function selectDealAmpID(state: IAppReduxState): number | null {
  return selectFeatureState(state).edit.dealAmpId;
}

export function selectVisibilityAmpCampaignModal(state: IAppReduxState): boolean {
  return selectFeatureState(state).edit.isVisibleCampaignsModal;
}
