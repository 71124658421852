import * as React from 'react';
import cx from 'classnames';
import block from 'bem-cn';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Card, CardProps } from 'shared/view/elements';

import './style.scss';

type Props = CardProps & {
  className?: string;
  title?: string;
  list: {
    title: string;
    value: React.ReactNode;
  }[];
};

const b = block('campaign-info-card');

export type CampaignInfoCardList = Array<{ title: string; value: React.ReactNode }>;

const CampaignInfoCard: React.FC<Props> = (props) => {
  const { className, title, list, ...cardProps } = props;

  return (
    <Card
      className={cx(b().toString(), className)}
      title={title}
      {...cardProps}
    >
      <List dense disablePadding>
        {list.map(({ title, value }) => (
          <ListItem
            classes={{ root: b('list-item').toString() }}
            key={title}
            disableGutters
          >
            <ListItemText
              classes={{ root: b('text').toString() }}
              primary={
                <>
                  <b>{title}</b> {value}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export { CampaignInfoCard };
