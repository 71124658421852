import { put, call, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { Alert, CommonData } from 'services';
import { tryCatch } from 'services/ErrorTracking';
import { actionCreators } from '../../redux';

export function* loadAmpCampaigns({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanId: number | null = yield select(CommonData.selectors.selectMediaplanID);
      const mediaplanVersionId: number | null = yield select(CommonData.selectors.selectMediaplanVersionID);
      const ampCampaigns: ReturnPromisedType<typeof api.amp.loadAmpCampaigns>
        = yield call(api.amp.loadAmpCampaigns, mediaplanId!, mediaplanVersionId!);

      yield put(actionCreators.loadAmpCampaignsSuccess({ ampCampaigns }));
    },
    *failed(error, message) {
      yield put(actionCreators.loadAmpCampaignsFailed(message));
      yield put(Alert.actionCreators.openAlert({ type: 'error', message: 'Не удалось загрузить список кампаний' }));
    }
  });
}