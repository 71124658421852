import { call, put } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';

import { tryCatch } from 'services/ErrorTracking';

import * as actionCreators from '../../actionCreators';

export function* loadAttribures({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const attributes: ReturnPromisedType<typeof api.attribute.loadEditableAttributes> =
        yield call(api.attribute.loadEditableAttributes);
      yield put(actionCreators.loadAttributesCompleted(attributes));
    },
    *failed(_, message) {
      yield put(actionCreators.loadAttributesFailed(message));
    },
  });
}
