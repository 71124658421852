import * as React from 'react';

import Dialog, { DialogClassKey, DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CircularProgress } from '@material-ui/core';

import { block } from 'bem-cn';
import { bind } from 'decko';

import './Modal.scss';
import { Button } from '../Button/Button';

const b = block('modal');

interface IOwnProps {
  isShow: boolean;
  onClose?(): void;
  onAccept?(): void;
  title: string;
  children?: JSX.Element;
  error?: JSX.Element;
  accessButtonText?: string;
  disableAccessButton?: boolean;
  disableCancelButton?: boolean;
  modalContainerStyles?: React.CSSProperties;
  rootStyles?: React.CSSProperties;
  cancelButtonText?: string;
  maxWidth?: DialogProps['maxWidth'];
  isShowPreloader?: boolean;
  fullWidth?: boolean;
  titleType?: 'error' | 'standard';
  modalClasses?: Partial<ClassNameMap<DialogClassKey>>;
  subTitle?: string;
  isVisibleOverflow?: boolean;
  isBackdropClickDisabled?: boolean;
}

class Modal extends React.PureComponent<IOwnProps> {

  private ENTER_CODE = 13;

  public componentDidUpdate(prevProps: IOwnProps) {
    const { isShow } = this.props;

    if (!prevProps.isShow && isShow) {
      document.addEventListener('keypress', this.checkPressEnter);
    }

    if (prevProps.isShow && !isShow) {
      document.removeEventListener('keypress', this.checkPressEnter);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('keypress', this.checkPressEnter);
  }

  render() {
    const {
      isShow, onClose, title, children, accessButtonText, disableAccessButton, modalContainerStyles,
      cancelButtonText, maxWidth, disableCancelButton, fullWidth = true, isShowPreloader,
      titleType, modalClasses, subTitle, error, isVisibleOverflow, rootStyles, isBackdropClickDisabled = true,
    } = this.props;

    return (
      <Dialog
        maxWidth={maxWidth}
        style={rootStyles}
        PaperProps={{ style: { ...modalContainerStyles, overflowY: isVisibleOverflow ? 'visible' : undefined }}}
        classes={modalClasses}
        fullWidth={fullWidth}
        className={b()}
        open={isShow}
        onClose={onClose}
        disableBackdropClick={isBackdropClickDisabled}
        disableEscapeKeyDown
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {titleType === 'error'
            ? <Alert severity="error">{title}</Alert>
            : `${title}`
          }
        </DialogTitle>
        {subTitle ? (
          <DialogTitle id="responsive-dialog-sub-title" disableTypography={true}>
            {subTitle}
          </DialogTitle>
        )
        : null}
        {children
          && <DialogContent style={{ overflowY: isVisibleOverflow ? 'visible' : undefined }}>{children}</DialogContent>
        }
        {error && <DialogContent>{error}</DialogContent>}
        <DialogActions disableSpacing>
          {isShowPreloader &&
            (
              <div className={b('preloader')}>
                <CircularProgress size={22} color="primary" />
              </div>
            )
          }
          <div className={b('action')}>
            <Button disabled={disableAccessButton} onClick={this.handleAccept}>
              {accessButtonText ? accessButtonText : 'OK'}
            </Button>
          </div>
          {cancelButtonText
            ? (
              <div className={b('action', { cancel: true })}>
                <Button disabled={disableCancelButton} onClick={onClose}>
                  {cancelButtonText}
                </Button>
              </div>
            )
            : null
          }
        </DialogActions>
      </Dialog>
    );
  }

  @bind
  private handleAccept() {
    const { onAccept } = this.props;
    if (onAccept) {
      onAccept();
    }
  }

  @bind
  private checkPressEnter(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === this.ENTER_CODE) {
      this.handleAccept();
    }
  }
}

export { Modal };
