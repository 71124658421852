import { put, select } from 'redux-saga/effects';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import * as M from 'shared/types/models';
import { TableCell, Column, EditableMetric } from 'shared/types/models/EditMetrics';
import { IDependencies } from 'shared/types/app';
import { hotTable } from 'shared/helpers';

import { tryCatch } from 'services/ErrorTracking';

import { actionCreators, selectors } from '../../../redux';
import { checkboxMetricId } from '../../../constants';

import { withHotInstance } from '../../dependencies';

export function* addMetric(deps: IDependencies, { payload }: NS.AddMetric) {
  const { settingsMetric } = payload;
  const { type, subId, metric } = settingsMetric;
  yield tryCatch({
    *successed() {
      const columns: Column[] = yield select(selectors.selectColumns);
      const cells: TableCell[][] = yield select(selectors.selectCells);
      const mediaplan: M.Mediaplan = yield select(selectors.selectMediaplan);
      const metrics: EditableMetric[] = yield select(selectors.selectMetrics);
      const markupBudgetMetricSubId: number | null = yield select(selectors.selectMarkupBudgetMetricSubId);

      const newColumns = helpers.makeMetricColumns({
        periodType: settingsMetric.periodType || 'none',
        dates: settingsMetric.periodsDates || [],
        metric,
        subId,
        budgetMarkupSubId: markupBudgetMetricSubId,
        metricType: type,
      });

      const { diffIndexes, updatedColumns, diffColumns } = helpers.addNewColumns(newColumns, columns);
      const updatedCells = helpers.insertEmptyColumnsCells(cells, diffIndexes);

      const { updatedCells: mergedPlanContentCells, changes: planChanges } = helpers.mergePlanContentToCells({
        columns: updatedColumns,
        mediaplan,
        cells: updatedCells,
        planColumns: diffColumns.filter(x => x.kind === 'metric' && x.metricType === 'plan')
      });

      const { updatedCells: mergedRatioAndPlanCells, changes: ratioChanges } = helpers.setValuesToRatioCells({
        columns: updatedColumns,
        cells: mergedPlanContentCells,
        ratioColumns: diffColumns.filter(x => x.kind === 'ratio'),
      });

      const completedCells = helpers.updateCellsStyles({
        columns: updatedColumns,
        cells: mergedRatioAndPlanCells,
        columnsIndexes: diffIndexes,
        metrics,
      });

      yield put(actionCreators.addMetricCompleted({ columns: updatedColumns, cells: completedCells }));
      withHotInstance(ht => {
        hotTable.addColumns(ht, diffIndexes);
        helpers.hotTable.setColumnsMeta({
          hotInstance: ht,
          columnsIndexes: diffIndexes,
          columns: updatedColumns,
          getState: deps.getState,
        });

        const filterIndexesChanges = helpers.getFilterIndexesChanges(updatedColumns);
        if (filterIndexesChanges.length) {
          hotTable.updateFiltersIndexesByChanges({ hotInstance: ht, changes: filterIndexesChanges });
          hotTable.applyFilters(ht);
        }

        helpers.hotTable.applyChanges({
          hotInstance: ht,
          changes: [...planChanges, ...ratioChanges],
          isSetToSourceData: Boolean(hotTable.getFilters(ht).length),
        });
        const [colIndex] = diffIndexes;
        setTimeout(() => hotTable.scrollToColumn(ht, colIndex), 0);
        ht.clearUndo();
      });

      if (type !== 'plan' && metric.id !== checkboxMetricId) {
        const ids = helpers.getFactColumnsIDs(diffColumns);
        yield put(actionCreators.addLoadingFactColumnsIDs({ ids }));
        yield put(actionCreators.loadFactData({ columnsIDs: ids }));
      }
    },
    *failed(_, message) {
      yield put(actionCreators.addMetricFailed(message));
    }
  });
}
