import { Column, MetricColumn } from 'shared/types/models/EditMetrics';
import { getColumnsIndexesByHeaderID, getRatioColumnsIndexesByColumn } from '../common';

export function removeColumns(deletingColumnIndex: number, columns: Column[]) {
  const deletingColumn = columns[deletingColumnIndex] as MetricColumn;

  const commonHeaderColumnsIndexes = getColumnsIndexesByHeaderID(deletingColumn.markupID, columns);
  const relatedRatioColumnsIndexes = getRatioColumnsIndexesByColumn(deletingColumn, columns);

  const diffIndexes = [...commonHeaderColumnsIndexes, ...relatedRatioColumnsIndexes];
  const updatedColumns = columns.filter((_, index) => !diffIndexes.includes(index));

  return { diffIndexes, updatedColumns };
}
