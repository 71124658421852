import * as React from 'react';

import { block } from 'bem-cn';

import CircularProgress from '@material-ui/core/CircularProgress';

import './style.scss';

const b = block('global-preloader');

interface IOwnProps {
  isShow: boolean;
}

class GlobalPreloader extends React.Component<IOwnProps> {

  public render() {
    const { isShow } = this.props;
    return (
      <div className={b({ hidden: !isShow })}>
        <CircularProgress
          className={b('preloader', { hidden: !isShow }).toString()}
          size={100}
          style={{ color: '#129acc' }}
          thickness={2}
        />
      </div>
    );
  }
}

export { GlobalPreloader };
