import * as React from 'react';

import { bind } from 'decko';
import { block } from 'bem-cn';

import './FileInput.scss';

const b = block('file-input');

type IOwnProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onChange(file: File): void;
  forwardedRef?: React.Ref<HTMLInputElement>;
}

class FileInput extends React.Component<IOwnProps> {

  render() {
    const { forwardedRef, accept, title } = this.props;
    return (
      <div className={b()}>
        <input
          ref={forwardedRef}
          type="file"
          accept={accept}
          title={title}
          className={b('input')}
          onChange={this.onChange}
        />
      </div>
    );
  }

  @bind
  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    const { currentTarget: { files } } = event;

    if (files) {
      const [file] = Array.from(files);
      onChange(file);
    }
  }
}

const ForwardedFileInput = React.forwardRef((props: IOwnProps, ref: React.Ref<HTMLInputElement>) => (
  <FileInput {...props} forwardedRef={ref} />
)
);

export { ForwardedFileInput as FileInput };
