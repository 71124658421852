import * as React from 'react';

function ExportIcon() {
  return (
    <svg fill="#3fbd54" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M352 120h-37.059l31.03-31.029a24 24 0 10-33.942-33.942l-72 72a24 24 0 000 33.942l72 72a24 24 0 0033.942-33.942L314.941 168H352a88 88 0 010 176 24 24 0 000 48c74.99 0 136-61.01 136-136s-61.01-136-136-136zM200.971 279.029a24 24 0 00-33.942 33.942L198.059 344H160a88 88 0 010-176 24 24 0 000-48c-74.99 0-136 61.009-136 136s61.01 136 136 136h38.059l-31.03 31.029a24 24 0 0033.942 33.942l72-72a24 24 0 000-33.942z" />
    </svg>
  );
}

export { ExportIcon };
