import * as NS from '../../types';
import { initialState } from '../intialState';

// tslint:disable:max-line-length

export function data(state: NS.ReduxState['data'] = initialState.data, action: NS.Action): NS.ReduxState['data'] {
  switch (action.type) {

    case 'AMP_CAMPAIGNS_SERVICE:LOAD_AMP_CAMPAIGNS_SUCCESS':
    case 'AMP_CAMPAIGNS_SERVICE:SET_AMP_CAMPAIGNS': {
      return { ...state, ampCampaigns: action.payload.ampCampaigns };
    }

    default: return state;
  }
}
