import { Column, CellChange, TableCell, MetricColumn, RatioColumn } from 'shared/types/models/EditMetrics';

import { stylesInvalidRatioCell, stylesRatioCell } from '../../../constants';

import { getRatioColumnsIndexesByColumn, isFactMetric, getRelationColumnsIndexesByRatioColumn } from '../../columns';

import { calcRatioCellValue } from './calcRatioCellValue';
import { isInvalidRatioCell } from './isInvalidRatioCell';

export function makeRelatedRatioChanges(columns: Column[], changes: CellChange[], cells: TableCell[][]): CellChange[] {
  return changes
    .filter(change => change.cellProperties.content !== undefined)
    .flatMap(change => {
      const { coordinate: { column: colIndex, row } } = change;
      const changedColumn = columns[colIndex] as MetricColumn;
      const ratioColumnsIndexes = getRatioColumnsIndexesByColumn(changedColumn, columns);

      const ratioChanges = ratioColumnsIndexes.map<CellChange | null>(ratioColumnIndex => {
        const ratioColumn = columns[ratioColumnIndex] as RatioColumn;
        const relationColumnsIndexes = getRelationColumnsIndexesByRatioColumn(columns, ratioColumn);
        if (relationColumnsIndexes) {
          const { planColumnIndex, factColumnIndex } = relationColumnsIndexes;

          const plan = changedColumn.metricType === 'plan'
            ? change.cellProperties.content!
            : cells[row][planColumnIndex].content;
          const fact = isFactMetric(changedColumn.metricType)
            ? change.cellProperties.content!
            : cells[row][factColumnIndex].content;

          const ratioCellValue = calcRatioCellValue(plan, fact);
          return {
            coordinate: { row, column: ratioColumnIndex },
            cellProperties: {
              content: ratioCellValue,
              style: isInvalidRatioCell(plan, fact) ? stylesInvalidRatioCell : stylesRatioCell,
            }
          };
        }
        return null;
      }).filter(x => x !== null) as CellChange[];
      return ratioChanges;
    });
}
