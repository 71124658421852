import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

export function convertServerDictionaries(dictionaries: SM.TemplateDictionaries): M.TemplateDictionary[] {
  return dictionaries.advertisers.map(({ id, name }) => ({
    advertiserId: id,
    advertiserName: name,
    brands: dictionaries.brands.length ? dictionaries.brands.filter(x => x.advertiserIds.includes(id)) : null,
  }));
}

export function convertServerBrandDictionary(dictionaries: SM.TemplateDictionaries): M.DictionaryItem[] {
  return dictionaries.brands.map(brand => ({
    id: brand.id,
    name: brand.name,
  }));
}
