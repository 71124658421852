import Handsontable from 'handsontable';

type Arguments = {
  cellProperties: Handsontable.CellProperties;
  value: any;
};

export function positiveNumberValidator({ cellProperties, value }: Arguments) {
  return Number(value) >= 0
}
