import { isPlacementsAttribute } from 'features/ExportCabinet/helpers/columns/common';

import * as M from '..';

export function isAttributeOrMetricColumn(
  column: M.ExportCabinet.Column
): column is M.ExportCabinet.AttributeColumn | M.ExportCabinet.MetricColumn {
  return isAttributeColumn(column) || isMetricColumn(column);
}

export function isAttributeColumn(column: M.ExportCabinet.Column): column is M.ExportCabinet.AttributeColumn {
  return column.kind === 'attribute';
}

export function isMetricColumn(column: M.ExportCabinet.Column): column is M.ExportCabinet.MetricColumn {
  return column.kind === 'metric';
}

export function isDictionaryColumn(column: M.ExportCabinet.Column): column is M.ExportCabinet.AttributeColumn {
  return column.kind === 'attribute' && column.valueType === 'dictionary';
}

export function isBooleanColumn(column: M.ExportCabinet.Column): column is M.ExportCabinet.AttributeColumn {
  return column.kind === 'attribute' && column.valueType === 'bool';
}

export function isDateRangeColumn(column: M.ExportCabinet.Column): column is M.ExportCabinet.AttributeColumn {
  return column.kind === 'attribute' && ['date-start', 'date-end'].includes(column.type!);
}

export function isPlacementsColumn(column: M.ExportCabinet.Column):
  column is Omit<M.ExportCabinet.AttributeColumn, 'valueSeparator'> & { valueSeparator: string } {
  return isDictionaryColumn(column) && isPlacementsAttribute(column.attributeID);
}

export function isDailyBudgetCell(cell: M.ExportCabinet.Cell): cell is M.ExportCabinet.DailyBudgetCell {
  return cell.kind === 'daily-budget';
}
