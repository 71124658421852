import * as React from 'react';
import { block } from 'bem-cn';

import Flatpickr from 'react-flatpickr';
import { autobind } from 'core-decorators';
import moment, { Moment } from 'moment/moment';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

import { FilledInput, InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import 'bootstrap-daterangepicker/daterangepicker.css';

import './RangeDatepicker.scss';

type OwnProps = {
  onChange(startDate: number, endDate: number): void;
  rangeDate: RangeDate | null;
};

type State = {
  value: string;
};

export type RangeDate = {
  startDate: number;
  endDate: number;
};

const b = block('bootstrap-range-datepicker');

class RangeDatepicker extends React.PureComponent<OwnProps, State> {

  public state: State = {
    value: '',
  };

  public render() {
    const { rangeDate } = this.props;
    const value = rangeDate ?
      `${moment(rangeDate.startDate).format('DD/MM')}-${moment(rangeDate.endDate).format('DD/MM')}`
      : '';
    return (
      <div className={b()}>
        <Flatpickr
          onChange={this.handleChange as any}
          options={{
              dateFormat: 'd/m/y',
              locale: Russian,
              mode: 'range',
              allowInput: false,
              maxDate: new Date('12/31/2100'),
              minDate: new Date('01/01/1950'),
              wrap: true
          }}
          render={(props, ref) => {
            return (
              <FilledInput
                inputProps={{ 'data-input': true }}
                value={value}
                ref={ref}
                startAdornment={(
                  <InputAdornment position="start">
                    <CalendarToday />
                  </InputAdornment>
                )}
              />
            );
          }}
        />
      </div>
    );
  }

  @autobind
  private handleChange(dates: Moment[]) {
    if (dates.length === 2) {
      const [startDate, endDate] = dates;
      this.props.onChange(startDate.valueOf(), endDate.valueOf());
    }
  }
}


export default RangeDatepicker;
