import * as R from 'ramda';

import { v4 as uuid } from 'uuid';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

import { sortMetaMetricsByColumnNum } from '../sortMetaMetricsByColumnNum';

export type MetaMetricConverterArgs = {
  metaMetric: SM.MetaMetric;
  metricID: number;
  metric: M.Metric | null;
  markupID: string;
  titleAmp?: string;
};

export type MetaMetricConverter<T> = (args: MetaMetricConverterArgs) => T;

export function convertMetaMetricsToColumns<T>(
  metrics: M.Metric[],
  metaMetricsMap: Record<number, SM.MetaMetric[]>,
  converter: MetaMetricConverter<T>,
): T[] {
  const metaMetricsEntries = sortMetaMetricsByColumnNum(Object.entries(metaMetricsMap));
  return metaMetricsEntries.flatMap(([metricId, arr]) => {

    const grouppedMetaMetrics = R.groupWith(
      (a, b) => a.periodType === b.periodType && a.metricParamId === b.metricParamId && a.factualMetricId === b.factualMetricId,
      arr,
    );

    return grouppedMetaMetrics.flatMap(groupMetrics => {
      const metric = metrics.find(x => x.id === Number(metricId)) || null;
      const markupID = uuid();
      const titleAmp = metric?.relToAmpMetricID
        ? metrics.find(x => x.id === metric?.relToAmpMetricID)?.titleAmp
        : metric?.titleAmp;
      return groupMetrics.map<T>(metaMetric => converter({ metaMetric, metricID: Number(metricId), metric, markupID, titleAmp }))
    });
  });
}
