export function randomIntFactory() {
  const numbers: number[] = [];
  return (min: number, max: number) => {
    const number = getUniqRandomInt(min, max, numbers);
    numbers.push(number);
    return number;
  }
}

function getUniqRandomInt(min: number, max: number, numbers: number[]) {
  const number = Math.floor(Math.random() * (max - min)) + min;
  if (numbers.includes(number)) {
    return getUniqRandomInt(min, max, numbers);
  }
  return number;
}
