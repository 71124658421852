import Handsontable from 'handsontable';

import { styleForCenterAlignedCell } from 'shared/constants';

export function checkboxCellRenderer(
  instance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: Handsontable.CellValue,
  cellProperties: Handsontable.CellProperties
) {
  Object.assign(td.style, styleForCenterAlignedCell);
  Handsontable.renderers.CheckboxRenderer(instance, td, row, col, prop, value, cellProperties);
}
