export const dateStartAttrId = 58;
export const dateEndAttrId = 59;
export const interestsAttributeId = 72;
export const campaignNameAttributeId = 52;
export const variableOneAttributeId = 92;
export const variableTwoAttributeId = 93;
export const variableThreeAttributeId = 94;
export const variableFourAttributeId = 95;
export const displayScheduleAttributeId = 60;
export const ageAttributeId = 68;
export const utmTagAttributeId = 88;
export const dateStartAttributeId = 58;
export const dateEndAttributeId = 59;
export const mobileOSAttributeId = 82;
export const androidVersionAttributeId = 83;
export const iosVersionAttributeId = 84;
export const linkAttributeId = 56;
export const geoAttributeId = 74;
export const minusGeoAttributeId = 75;
export const placementsAttributeId = 57;

export const markupMetricId = 83;
export const dailyBudgetMetricId = 84;
export const totalBudgetMetricId = 86;
export const socialCharacteristicsAttributeId = 71;
export const unitVolumeMetricId = 91;
export const rateMetricId = 85;
export const rateWithoutMarkupMetricId = 92;

export const totalBudgetWithoutMarkup = 90;

export const modalEditorsTypes = ['display-schedule', 'categorized-list'];

export const dateFormat = 'DD.MM.YYYY';

export const defaultMantissa = 3;

export const topTitlesBackgrounds = [
  '#129acc',
  '#7030A0',
  '#7C7C7C',
  '#C55A11',
];
export const mainTitlesBackgrounds = [
  '#129acc',
  '#0070C0',
  '#00B050',
  '#00B0F0',
  '#FFC000',
  '#D60093',
  '#8497B0',
  '#8FAADC',
  '#0070C0',
  '#00B050',
  '#00B0F0',
];
