import * as NS from '../../types';
import { initialState } from '../initialState';

// tslint:disable:max-line-length

export function dataReducer(state: NS.ReduxState['data'] = initialState.data, action: NS.FeatureAction): NS.ReduxState['data'] {
  switch (action.type) {

    case 'EDIT_METRICS:LOAD_MEDIAPLAN_COMPLETE': {
      const mediaplan = action.payload;
      return { ...state, mediaplan };
    }

    case 'EDIT_METRICS:LOAD_ATTRIBUTES_COMPLETE': {
      const attributes = action.payload;
      return { ...state, attributes };
    }

    case 'EDIT_METRICS:LOAD_METRICS_COMPLETE': {
      const metrics = action.payload;
      return { ...state, metrics };
    }

    case 'EDIT_METRICS:LOAD_CABINETS_COMPLETE': {
      const { cabinets } = action.payload;
      return { ...state, cabinets };
    }

    default: return state;
  }
}
