import { changeStep, upperBound, bottomBound } from '../constants';
import * as TS from '../types';
import { initialState } from './intialState';

export function reducer(state: TS.ReduxState = initialState, action: TS.ServiceAction): TS.ReduxState {
  switch (action.type) {
    case 'SCALE:INCREASE_SCALE':
      return {
        scale: state.scale < upperBound ? state.scale + changeStep : state.scale,
      };
    case 'SCALE:DECREASE_SCALE':
      return {
        scale: state.scale > bottomBound ? state.scale - changeStep : state.scale,
      };
    case 'SCALE:SET_SCALE': {
      return {
        scale: action.payload,
      };
    }

    default:
      return state;
  }
}
