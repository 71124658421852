import { ReduxState } from '../types/ReduxState';
import { initialCommunicationState } from 'shared/constants';

export const initialState: ReduxState = {
  data: {
    ampCampaigns: null
  },
  edit: {
    isVisibleCampaignsModal: false,
    dealAmpId: null,
  },
  communication: {
    loadingAmpCampaigns: initialCommunicationState,
  },
};
