import Handsontable from 'handsontable';

import { select } from 'redux-saga/effects';

import { selectors } from '.';

import { hotTable } from '../index';

export function* setScale(getHotInstance: () => Handsontable | null) {
  const scale: ReturnType<typeof selectors.selectScale> =
    yield select(selectors.selectScale);

  const hotInstance = getHotInstance();
  if (hotInstance) {
    hotTable.setScale(hotInstance, scale);
  }
}
