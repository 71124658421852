import { call, put } from 'redux-saga/effects';

import { tryCatch } from 'services/ErrorTracking';
import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { actionCreators } from '../../../redux';
import * as A from '../../../types/actions';

export function* loadTemplate({ api }: IDependencies, { payload: { templateId, type, acceptTemplate } }: A.LoadTemplate) {
  yield tryCatch({
    *successed() {
      const template: ReturnPromisedType<typeof api.template.getTemplate> =
        yield call(api.template.getTemplate, templateId, type);
      yield put(actionCreators.loadTemplateSuccess());

      yield call(acceptTemplate, template, templateId);
    },
    *failed(error, message) {
      yield put(actionCreators.loadTemplateFail(message));
    }
  });
}
