import { takeLatest } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { downloadFile } from './downloadFile';
import * as NS from '../../types';

const downloadFileType: NS.DownloadFile['type'] = 'FILE_DOWNLOADING_SERVICE:DOWNLOAD';

export function* rootSaga(deps: IDependencies) {
  yield takeLatest(downloadFileType, downloadFile, deps);
}
