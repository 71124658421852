import { put, call, select } from 'redux-saga/effects';

import { Alert, CommonData } from 'services';
import { tryCatch } from 'services/ErrorTracking';
import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { actionCreators } from '../../../redux';
import * as A from '../../../types/actions';

export function* saveTemplate({ api }: IDependencies, { payload }: A.SaveTemplate) {
  yield tryCatch({
    *successed() {
      const mediaplanId: number | null = yield select(CommonData.selectors.selectMediaplanID);
      const mediaplanVersionId: number | null = yield select(CommonData.selectors.selectMediaplanVersionID);

      if (!mediaplanId || !mediaplanVersionId) {
        throw Error('mediaplanID or mediaplanVersionID not found');
      }

      const template: ReturnPromisedType<typeof api.template.saveTemplate> =
        yield call(api.template.saveTemplate, { ...payload, mediaplanId, mediaplanVersionId });
      yield put(Alert.actionCreators.openAlert({ message: 'Шаблон успешно сохранен!', type: 'success' }));
      yield put(actionCreators.saveTemplateSuccess({ template }));
    },
    *failed(error, message) {
      yield put(Alert.actionCreators.openAlert({ message: 'Ошибка сохранения шаблона', type: 'error' }));
      yield put(actionCreators.saveTemplateFail(message));
    }
  });
}
