
import { autobind } from 'core-decorators';

import * as SM from 'shared/types/models/Server';
import * as M from 'shared/types/models';

import { ServerExportFactAMPMediaplan } from 'shared/types/models/Server';
import { NullableBy } from 'shared/types/utils';

import BaseApi from '../../BaseApi';
import { convertServerMediaplan } from '../mediaplan/converters';

class AMP extends BaseApi {

  @autobind
  public async changeBriefStatuses(args: ChangeBriefStatuses): Promise<void> {
    const { mediaplanID, mediaplanVersionID, dealAmpId, briefIdList } = args;
    const response = await this.actions.post<SM.BriefStatus[]>({
      url: `/export-amp/fact/${mediaplanID}/change-brief-status`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID, dealAmpId, briefIdList },
    });
    this.handleResponse(response);
  }

  @autobind
  public async loadBriefStatuses(args: MediaplanIDs & { dealAmpId: number }): Promise<SM.BriefStatus[]> {
    const { mediaplanID, mediaplanVersionID, dealAmpId } = args;
    const response = await this.actions.get<SM.BriefStatus[]>({
      url: `/export-amp/fact/get-brief-status`,
      options: { headers: this.authHeaders },
      data: { mediaplanId: mediaplanID, mediaplanVersionId: mediaplanVersionID, dealAmpId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async checkExportAMPPlan(args: MediaplanIDs): Promise<SM.CheckExportAMPResponse> {
    const { mediaplanID, mediaplanVersionID } = args;
    const response = await this.actions.get<SM.CheckExportAMPResponse>({
      url: `/export-amp/check-export-amp-plan-fact`,
      options: { headers: this.authHeaders },
      data: { mediaplanId: mediaplanID, mediaplanVersionId: mediaplanVersionID },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async copyAmpRow(args: CopyAmpRowArgs): Promise<NullableBy<ServerExportFactAMPMediaplan, 'mediaplanCsAmpCampaignInfo' | 'content'>> {
    const { mediaplanID, mediaplanVersionID, date, allocId, dealAmpId, itemRowId } = args;
    const response = await this.actions.post<NullableBy<ServerExportFactAMPMediaplan, 'mediaplanCsAmpCampaignInfo' | 'content'>>({
      url: `/export-amp/fact/${mediaplanID}/copy-line`,
      options: { headers: this.authHeaders },
      data: {
        mediaplanVersionId: mediaplanVersionID,
        period: date,
        allocId,
        itemRowId,
        dealAmpId,
      },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async exportFactAmp(args: ExportFactAmpArgs): Promise<void> {
    const { mediaplanID, mediaplanVersionID, dealAmpId, ampContent, ampMetadata } = args;
    const response = await this.actions.post({
      url: `/export-amp/fact/${mediaplanID}?mediaplanVersionId=${mediaplanVersionID}`,
      options: { headers: this.authHeaders },
      data: {
        dealAmpId,
        mediaplan: {
          ampContent,
          ampMetadata,
          rowIdColumnNum: ampMetadata.rowIdColumnNum,
        },
        mediaplanVersionId: mediaplanVersionID,
      },
    });
    this.handleResponse(response);
  }

  @autobind
  public async loadExportFactMediaplan(args: MediaplanIDs & { dealAmpId: number }): Promise<M.ExportFactAMPMediaplan> {
    const { mediaplanID, mediaplanVersionID, dealAmpId } = args;
    const response = await this.actions.get<M.ExportFactAMPMediaplan>({
      url: '/export-amp/get-fact-export-amp',
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID, mediaplanId: mediaplanID, dealAmpId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getMediaplan(args: MediaplanIDs): Promise<M.Mediaplan> {
    const { mediaplanID, mediaplanVersionID } = args;
    const response = await this.actions.get<SM.ServerMediaplan>({
      url: `/export-amp/${mediaplanID}`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID },
    });
    return this.handleResponse(response, data => convertServerMediaplan(data));
  }

  @autobind
  public async getAmpMediaplan(args: GetAmpMediaplanArgs): Promise<M.AmpMediaplan> {
    const { mediaplanID, mediaplanVersionID, dealAmpId } = args;
    const response = await this.actions.get<SM.ServerAmpMediaplan>({
      url: `/export-amp/${mediaplanID}/current-amp-mediaplan?mediaplanVersionId=${mediaplanVersionID}&dealAmpId=${dealAmpId}`,
      options: { headers: this.authHeaders },
    });

    // TODO: add processing of missing AMP mediaplans
    return this.handleResponse(response);
  }

  @autobind
  public async exportAMP(args: ExportAMPArgs): Promise<void> {
    const { ampContent, dealAmpId, mediaplanID, mediaplanVersionID, ampMetadata } = args;
    const response = await this.actions.post({
      url: `/mediaplan/${mediaplanID}/export-amp`,
      options: { headers: this.authHeaders },
      data: {
        mediaplan: {
          ampContent,
          ampMetadata,
          rowIdColumnNum: ampMetadata.rowIdColumnNum,
        },
        dealAmpId,
        mediaplanVersionId: mediaplanVersionID,
      },
    });
    this.handleResponse(response);
  }

  @autobind
  public async saveAMP(id: number, data: any): Promise<any> {
    const response = await this.actions.post({
      url: `/mediaplan/${id}/export-amp`,
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }

  @autobind
  public async checkAccess(args: MediaplanIDs): Promise<void> {
    const { mediaplanID, mediaplanVersionID } = args;
    const response = await this.actions.get<SM.ServerCheckAccessResponse>({
      url: `/export-amp/${mediaplanID}/getAmpUserId`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID },
    });
    this.handleResponse(response);
  }

  @autobind
  public async loadAmpCampaigns(mediaplanId: number, mediaplanVersionId: number): Promise<M.AmpCampaigns> {
    const response = await this.actions.get<M.AmpCampaigns>({
      url: `/export-amp/${mediaplanId}/matched-amp-campaigns`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async checkAmpCsMatching(args: GetAmpMediaplanArgs): Promise<SM.CheckAmpCsRowsMatching> {
    const { mediaplanID, mediaplanVersionID, dealAmpId } = args;
    const response = await this.actions.get<SM.CheckAmpCsRowsMatching>({
      url: `/export-amp/check-amp-cs-items`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID, mediaplanId: mediaplanID, dealAmpId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async loadAmpCsRowsForMatching(args: GetAmpMediaplanArgs): Promise<SM.MatchingRow[]> {
    const { mediaplanID, mediaplanVersionID, dealAmpId } = args;
    const response = await this.actions.get<SM.MatchingRow[]>({
      url: `/export-amp/amp-cs-brief-matching`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID, mediaplanId: mediaplanID, dealAmpId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async saveRowsMatching(args: SaveRowsMatching): Promise<void> {
    const { mediaplanID, mediaplanVersionID, dealAmpId, matchedIds } = args;
    const response = await this.actions.post({
      url: `/export-amp/save-brief-matching?mediaplanVersionId=${mediaplanVersionID}&mediaplanId=${mediaplanID}&dealAmpId=${dealAmpId}`,
      options: { headers: this.authHeaders },
      data: matchedIds,
    });
    this.handleResponse(response);
  }

}

type CopyAmpRowArgs = {
  allocId: number;
  date: string;
  dealAmpId: number;
  itemRowId: number;
} & MediaplanIDs;

type SaveRowsMatching = GetAmpMediaplanArgs & { matchedIds: M.ExportFactAMP.MatchedIDs[] };

type ExportMediaplanArgs = {
  mediaplanID: number;
  mediaplanVersionID: number;
  ampContent: SM.GeneralCell[][];
  dealAmpId: number;
}

type ExportAMPArgs = ExportMediaplanArgs & { ampMetadata: M.Mediaplan['metadata'] };
type ExportFactAmpArgs = ExportMediaplanArgs & { ampMetadata: SM.ExportFactMetadata };

type MediaplanIDs = {
  mediaplanID: number;
  mediaplanVersionID: number;
};

type GetAmpMediaplanArgs = MediaplanIDs & {
  dealAmpId: number;
};

type ChangeBriefStatuses = MediaplanIDs & {
  briefIdList: number[];
  dealAmpId: number;
};

export default AMP;
