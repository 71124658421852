import { v4 as uuidv4 } from 'uuid';

import * as M from 'shared/types/models';
import { MetaMetricConverterArgs } from 'shared/helpers';

export function makeMetricColumn(args: MetaMetricConverterArgs): M.MetricColumn {
  const { markupID, metaMetric, metric, metricID } = args;
  return {
    id: uuidv4(),
    kind: 'metric',
    markupID,
    metricID,
    contentIndex: metaMetric.columnNum,
    date: metaMetric.periodStart && metaMetric.periodEnd
      ? { start: metaMetric.periodStart, end: metaMetric.periodEnd }
      : null,
    title: metric?.title || '',
  };
}
