import { call, put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { tryCatch } from 'services/ErrorTracking';
import { actionCreators, selectors } from '../../../redux';

export function* loadMediaplan({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanID: number | null = yield select(selectors.selectMediaplanID);
      const mediaplanVersionID: number | null = yield select(selectors.selectMediaplanVersionID);

      if (!mediaplanID || !mediaplanVersionID) {
        throw Error('mediaplanId or mediaplanVersionID not found');
      }

      const mediaplan: ReturnPromisedType<typeof api.mediaplan.getMediaplan> =
        yield call(api.mediaplan.getMediaplan, { mediaplanVersionID, mediaplanID });
      yield put(actionCreators.loadMediaplanCompleted({ mediaplan }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadMediaplanFailed(message));
    },
  });
}
