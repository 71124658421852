import { bind } from 'decko';

import HttpActions from './HttpActions';

import Auth from './resources/auth/auth';
import Mediaplan from './resources/mediaplan/mediaplan';
import Cookies from './resources/cookies/cookies';
import Cabinet from './resources/cabinet/cabinet';
import Template from './resources/template/template';
import Metric from './resources/metric/metric';
import Attribute from './resources/attribute/attribute';
import Amp from './resources/amp/amp';
import ExportCabinet from './resources/exportCabinet/exportCabinet';
import Autocorrect from './resources/autocorrect/autocorrect';
import PageSettings from './resources/pageSettings/pageSettings';
import MediafactManual from './resources/mediafactManual/mediafactManual';

import { ApiErrorInterceptor } from './interceptors/types';
import { TokenExpiredHandler } from './interceptors/handleTokenExpired';

class Api {

  public auth: Auth;
  public mediaplan: Mediaplan;
  public cabinet: Cabinet;
  public template: Template;
  public metric: Metric;
  public attribute: Attribute;
  public amp: Amp;
  public exportCabinet: ExportCabinet;
  public autocorrect: Autocorrect;
  public pageSettings: PageSettings;
  public mediafactManual: MediafactManual;

  public static instance: Api | null = null;

  public static getInstance() {
    if (!Api.instance) {
      const instance = new Api();
      Api.instance = instance;
    }
    return Api.instance;
  }

  public interceptors: ApiErrorInterceptor[] = [];

  private isPageInitialized: boolean = false;
  private httpActions: HttpActions | null = null;

  constructor() {
    this.httpActions = new HttpActions(this.interceptors);
    const cookies = new Cookies();

    this.auth = new Auth(this.httpActions, cookies, this.setTokenForEachApi);
    this.mediaplan = new Mediaplan(this.httpActions, cookies);
    this.cabinet = new Cabinet(this.httpActions, cookies);
    this.template = new Template(this.httpActions, cookies);
    this.metric = new Metric(this.httpActions, cookies);
    this.attribute = new Attribute(this.httpActions, cookies);
    this.amp = new Amp(this.httpActions, cookies);
    this.exportCabinet = new ExportCabinet(this.httpActions, cookies);
    this.autocorrect = new Autocorrect(this.httpActions, cookies);
    this.pageSettings = new PageSettings(this.httpActions, cookies);
    this.mediafactManual = new MediafactManual(this.httpActions, cookies);

    const token = this.auth.getToken();
    this.setTokenForEachApi(token);
  }

  @bind
  public checkPageIsInitialized() {
    return this.isPageInitialized;
  }

  @bind
  public markPageAsInitialized() {
    this.isPageInitialized = true;
  }

  @bind
  public addErrorInterceptor(errorInterceptor: ApiErrorInterceptor) {
    this.interceptors.push(errorInterceptor);
  }

  @bind
  public setNetworkErrorHandler(handler: () => void) {
    if (this.httpActions) {
      this.httpActions.networkErrorHandler = handler;
    }
  }

  @bind
  public setTokenExpiredInterceptor(interceptor: TokenExpiredHandler) {
    if (this.httpActions) {
      this.httpActions.handleTokenExpiredInterceptor = interceptor;
    }
  }

  @bind
  private setTokenForEachApi(token: string | null) {
    const entities: { token: string | null }[] = [
      this.auth,
      this.mediaplan,
      this.cabinet,
      this.template,
      this.metric,
      this.attribute,
      this.amp,
      this.exportCabinet,
      this.autocorrect,
      this.pageSettings,
      this.mediafactManual,
    ];
    entities.forEach((entity) => entity.token = token);
  }
}

export default Api;
