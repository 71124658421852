import * as React from 'react';

import { block } from 'bem-cn';
import { getEnvParams } from 'core/getEnvParams';

import './AppVersion.scss';

const b = block('version');

const version = getEnvParams().appVersion;

function AppVersion() {
  return (
    <div className={b()}>{version}</div>
  );
}

export { AppVersion };
