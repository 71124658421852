import { takeLatest, takeEvery, call } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';

import * as services from 'services';

import * as NS from '../../types';

import { getHotInstance } from '../dependencies';

import * as actionSagas from './actionSagas';
import * as loadingSagas from './loadingSagas';
import * as helperSagas from './helperSagas';

const loadMediaplanType: NS.LoadMediaplan['type'] = 'EDIT_METRICS:LOAD_MEDIAPLAN';
const loadAttributesType: NS.LoadAttributes['type'] = 'EDIT_METRICS:LOAD_ATTRIBUTES';
const loadFactualMetricsType: NS.LoadMetrics['type'] = 'EDIT_METRICS:LOAD_METRICS';
const loadCabinetsType: NS.LoadCabinets['type'] = 'EDIT_METRICS:LOAD_CABINETS';
const loadCalendarPeriodsType: NS.LoadCalendarPeriods['type'] = 'EDIT_METRICS:LOAD_CALENDAR_PERIODS';

const saveMediaplanType: NS.SaveMediaplan['type'] = 'EDIT_METRICS:SAVE_MEDIAPLAN';
const loadTemplateType: NS.LoadTemplate['type'] = 'EDIT_METRICS:LOAD_TEMPLATE';
const addMetricType: NS.AddMetric['type'] = 'EDIT_METRICS:ADD_METRIC';
const removeMetricType: NS.RemoveMetric['type'] = 'EDIT_METRICS:REMOVE_METRIC';
const editMetricType: NS.EditMetric['type'] = 'EDIT_METRICS:EDIT_METRIC';
const initTableType: NS.InitializeTable['type'] = 'EDIT_METRICS:INITIALIZE_TABLE';
const applyChangesType: NS.ApplyChanges['type'] = 'EDIT_METRICS:APPLY_CHANGES';

const loadMediaplanCompleteType: NS.LoadMediaplanCompleted['type'] = 'EDIT_METRICS:LOAD_MEDIAPLAN_COMPLETE';
const loadAttributesCompleteType: NS.LoadAttributesCompleted['type'] = 'EDIT_METRICS:LOAD_ATTRIBUTES_COMPLETE';
const loadMetricsCompleteType: NS.LoadMetricsCompleted['type'] = 'EDIT_METRICS:LOAD_METRICS_COMPLETE';
const loadCabinetsCompleteType: NS.LoadCabinetsCompleted['type'] = 'EDIT_METRICS:LOAD_CABINETS_COMPLETE';
const loadCSDictionariesCompleteType: services.CommonData.types.LoadCSDictionariesCompleted['type'] = 'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES_COMPLETE';

const initVisibleCellsMetaType: NS.InitVisibleCellsMeta['type'] = 'EDIT_METRICS:INIT_VISIBLE_CELLS_META';

export function getSaga(deps: IDependencies) {
  return function* rootSaga() {
    yield takeLatest(loadMediaplanType, loadingSagas.loadMediaplan, deps);
    yield takeLatest(initVisibleCellsMetaType, helperSagas.initVisibleCellsMeta, deps);
    yield takeLatest(loadAttributesType, loadingSagas.loadAttribures, deps);
    yield takeLatest(loadAttributesCompleteType, loadingSagas.loadAttributesComplete);
    yield takeLatest(loadFactualMetricsType, loadingSagas.loadEditableMetrics, deps);
    yield takeLatest(services.Scale.actionTypesThatAffectScale, services.Scale.sagas.setScale, getHotInstance);
    yield takeLatest(loadCabinetsType, loadingSagas.loadCabinets, deps);
    yield takeLatest(loadCalendarPeriodsType, loadingSagas.loadCalendarPeriods, deps);
    yield takeLatest(saveMediaplanType, actionSagas.saveMediaplan, deps);
    yield takeLatest(loadTemplateType, actionSagas.loadTemplate, deps);
    yield takeEvery(addMetricType, actionSagas.addMetric, deps);
    yield takeLatest(initTableType, actionSagas.initTable, deps);
    yield takeLatest(editMetricType, actionSagas.editMetric, deps);
    yield takeLatest(applyChangesType, actionSagas.applyChanges, deps);
    yield takeLatest(removeMetricType, actionSagas.removeMetric, deps);
    yield takeLatest(
      [
        loadMediaplanCompleteType,
        loadAttributesCompleteType,
        loadMetricsCompleteType,
        loadCabinetsCompleteType,
        loadCSDictionariesCompleteType,
      ],
      actionSagas.checkStartInitializeTable,
    );
    yield call(actionSagas.watchLoadFactData, deps);
  };
}

