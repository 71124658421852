import * as React from 'react';

import { block } from 'bem-cn';
import { bind } from 'decko';

import ReactTreeMenu, { Item, ItemComponent } from 'react-simple-tree-menu';

import 'react-simple-tree-menu/dist/main.css';

import './TreeMenu.scss';
import { Input } from 'shared/view/elements';

const b = block('tree-menu');

interface IOwnProps {
  data: ITreeItem[];
  searchByKey?: boolean;
  onClickChildItem?(item: IItemProps): void;
  onClickItem?(item: IItemProps): void;
  searchPlaceholder?: string;
}

export interface ITreeItem {
  key: string;
  label: string;
  nodes: ITreeItem[];
}


export interface IItemProps {
  key: string;
  value: string;
}

interface IState {
  activeKey: string;
  searchValue: string;
}

class TreeMenu extends React.PureComponent<IOwnProps, IState> {

  public state: IState = {
    activeKey: '',
    searchValue: '',
  };

  render() {
    const { data, searchByKey, searchPlaceholder } = this.props;
    const { activeKey, searchValue } = this.state;
    return (
      <div className={b()}>
        <ReactTreeMenu
          matchSearch={searchByKey ? this.searchByKey : undefined}
          focusKey="null"
          activeKey={activeKey}
          data={data}
          onClickItem={this.onClickItem}
        >
          {({ items, search }) => (
            <>
              <Input
                value={searchValue}
                placeholder={searchPlaceholder ? searchPlaceholder : 'Поиск...'}
                onChange={this.makeHandleChangeInput(search)}
                isClearable
              />
              <ul className="tree-item-group">
                {items.map(({ key, ...props }) => <ItemComponent  key={key} {...props} />)}
              </ul>
          </>
          )}
        </ReactTreeMenu>
      </div>
    );
  }

  @bind
  private makeHandleChangeInput(search: any) {
    return (value: string) => {
      search(value);
      this.setState({ searchValue: value });
    };
  }

  @bind
  private onClickItem({ key, label, parent }: Item) {
    const { onClickItem, onClickChildItem } = this.props;
    const item = { key, value: label } as IItemProps;
    if (parent) {
      this.setState({ activeKey: key });
      onClickChildItem && onClickChildItem(item);
    }
    onClickItem && onClickItem(item);
  }

  @bind
  private searchByKey(props) {
    const { key, searchTerm } = props;
    return key.includes(searchTerm);
  }
}

export { TreeMenu };
