import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

export function convertServerSourceType(type: SM.ServerSourceType): M.SourceType {
  const convertMap: Record<SM.ServerSourceType, M.SourceType> = {
    AMP: 'amp',
    CLIENTSPACE_WEB: 'clientspace-web',
    CABINET: 'cabinet',
  };
  return convertMap[type];
}
