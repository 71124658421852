
import { autobind } from 'core-decorators';

import * as SM from 'shared/types/models/Server';
import * as M from 'shared/types/models';


import BaseApi from '../../BaseApi';
import { convertServerAutocorrectDictionary, convertAutocorrectDictionaries } from './converters';

class Autocorrect extends BaseApi {
  @autobind
  public async getDictionaries(args: GetDictionariesArgs): Promise<M.AutocorrectDictionary[]> {
    const { mediaplanID, mediaplanVersionID: mediaplanVersionId, sourceType } = args;
    const response = await this.actions.get<SM.ServerAutocorrectDictionary>({
      url: `/autoreplace/${mediaplanID}/`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId, sourceType },
    });
    return this.handleResponse(response, convertServerAutocorrectDictionary);
  }

  @autobind
  public async saveDictionary(args: SaveDictionaryArgs) {
    const { mediaplanID, mediaplanVersionID, dictionaries } = args;
    const attributeMappings = convertAutocorrectDictionaries(dictionaries)
    const response = await this.actions.post<SM.ServerAutocorrectDictionary>({
      url: `/autoreplace/${mediaplanID}/?mediaplanVersionId=${mediaplanVersionID}`,
      options: { headers: this.authHeaders },
      data: { attributeMappings },
    });
    this.handleResponse(response);
  }
}

type GetDictionariesArgs = {
  mediaplanID: number;
  mediaplanVersionID: number;
  sourceType: SM.ServerSourceType;
};

type MediaplanIDs = {
  mediaplanID: number;
  mediaplanVersionID: number;
};

type SaveDictionaryArgs = MediaplanIDs & { dictionaries: M.AutocorrectDictionary[] };

export default Autocorrect;
