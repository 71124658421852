import { select } from 'redux-saga/effects';
import { IDependencies } from 'shared/types/app';

import { selectors } from '../../../redux';
import { initColumnsMeta } from '../../../helpers/hotTable';

import { withHotInstance } from '../../dependencies';

export function* initVisibleCellsMeta(deps: IDependencies) {
  const columns: ReturnType<typeof selectors.selectColumns> = yield select(selectors.selectColumns);

  withHotInstance(ht => {
    initColumnsMeta({
      hotInstance: ht,
      getState: deps.getState,
      columns,
      rowsIndexes: ht.rowIndexMapper.getNotTrimmedIndexes().map(index => ht.toVisualRow(index)),
    });
  });
}
