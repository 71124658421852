import * as React from 'react';

function BrokenIcon() {
  return (
    <svg 
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
	    viewBox="0 0 588.006 588.006"
      fill="#f77d76"
    >
      <g transform="translate(1 1)">
        <g>
          <g>
            <path d="M211.265,389.248l-60.331,60.331c-25.012,25.012-65.517,25.012-90.508,0.005c-24.996-24.996-24.996-65.505-0.005-90.496
              l120.683-120.683c24.991-24.992,65.5-24.992,90.491,0c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17
              c-41.654-41.654-109.177-41.654-150.831,0L30.252,328.918c-41.654,41.654-41.654,109.177,0,150.831
              c41.649,41.676,109.177,41.676,150.853,0l60.331-60.331c8.331-8.331,8.331-21.839,0-30.17S219.596,380.917,211.265,389.248z"/>
            <path d="M479.756,181.08c41.654-41.654,41.654-109.177,0-150.831c-41.653-41.653-109.173-41.653-150.848-0.005l-72.389,72.389
              c-8.331,8.331-8.331,21.839,0,30.17s21.839,8.331,30.17,0l72.384-72.384c25.008-24.992,65.521-24.992,90.513,0
              c24.991,24.991,24.991,65.5,0,90.491L316.85,283.646c-24.992,24.992-65.5,24.992-90.491,0c-8.331-8.331-21.839-8.331-30.17,0
              s-8.331,21.839,0,30.17c41.654,41.654,109.177,41.654,150.831,0L479.756,181.08z"/>
            <path d="M398.099,367.926c-8.331-8.331-21.839-8.331-30.17,0c-8.331,8.331-8.331,21.839,0,30.17l63.979,63.979
              c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17L398.099,367.926z"/>
            <path d="M111.946,142.113c8.331,8.331,21.839,8.331,30.17,0s8.331-21.839,0-30.17L78.073,47.9c-8.331-8.331-21.839-8.331-30.17,0
              c-8.331,8.331-8.331,21.839,0,30.17L111.946,142.113z"/>
            <path d="M489.67,319h-64c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h64
              c11.782,0,21.333-9.551,21.333-21.333C511.003,328.551,501.452,319,489.67,319z"/>
            <path d="M169.67,105.667c11.782,0,21.333-9.551,21.333-21.333v-64C191.003,8.551,181.452-1,169.67-1
              c-11.782,0-21.333,9.551-21.333,21.333v64C148.336,96.115,157.888,105.667,169.67,105.667z"/>
            <path d="M20.336,191h64c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-64
              c-11.782,0-21.333,9.551-21.333,21.333C-0.997,181.449,8.554,191,20.336,191z"/>
            <path d="M340.336,404.333c-11.782,0-21.333,9.551-21.333,21.333v64c0,11.782,9.551,21.333,21.333,21.333
              c11.782,0,21.333-9.551,21.333-21.333v-64C361.67,413.885,352.118,404.333,340.336,404.333z"/>
          </g>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </svg>
  );
}

export { BrokenIcon };
