import * as R from 'ramda';

import { MapFactSubIdData, TableCell, Column, CellChange } from 'shared/types/models/EditMetrics';

import { applyChangesToCells } from '../baseOperations';
import { makeChangesFromFactContent } from './makeChangesFromFactContent';

type Arguments = {
  factMetricsContent: MapFactSubIdData;
  columns: Column[];
  cells: TableCell[][];
};

export function mergeFactContent(args: Arguments) {
  const { factMetricsContent, cells, columns } = args;
  const changes = R.toPairs(factMetricsContent).flatMap<CellChange>(([subId, factMeta]) => {
    const columnIndexesForMerge = columns.reduce<number[]>((indexes, x, index) =>
      x.kind === 'metric' && x.subId === +subId ? [...indexes, index] : indexes
      , []);

    const rows = cells.map(([firstCell]) => {
      const row = factMeta.rows.find(x => x.rowId === firstCell.content);
      return row ?? {
        rowId: Number(firstCell.content),
        content: null,
      };
    });

    return makeChangesFromFactContent({ columnIndexes: columnIndexesForMerge, cells, rows });
  });

  const updatedCells = applyChangesToCells(changes, cells);

  return { updatedCells, changes };
}
