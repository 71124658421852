import Handsontable from 'handsontable';

import { CellChange } from 'shared/types/models/EditMetrics';

import { ChangeSource } from '../../types';

type Arguments = {
  hotInstance: Handsontable;
  changes: CellChange[];
  isSetToSourceData?: boolean;
};

export function applyChanges(args: Arguments) {
  const { hotInstance, changes, isSetToSourceData = false } = args;
  const contentChanges = changes
    .filter(x => x.cellProperties.content !== undefined)
    .map(x => [
      x.coordinate.row,
      x.coordinate.column,
      x.cellProperties.content!,
    ] as [number, number, string]);

  if (isSetToSourceData) {
    hotInstance.setSourceDataAtCell(contentChanges);
  } else {
    const changeSource: ChangeSource = 'externalEdit';
    hotInstance.setDataAtCell(contentChanges, changeSource);
  }
}
