import * as TS from '../types';
import { initialState } from './intialState';

export function reducer(state: TS.ReduxState = initialState, action: TS.ServiceAction): TS.ReduxState {
  switch (action.type) {

    case 'RELOAD_PAGE_SERVICE:CLOSE_MODAL':
      return { isOpen: false, title: '' };


    case 'RELOAD_PAGE_SERVICE:OPEN_MODAL':
      return { isOpen: true, title: action.payload.message };

    default:
      return state;
  }
}
