import * as R from 'ramda';

import * as M from 'shared/types/models';

export function fn<T extends M.AbstractCellChange, C>(changes: T[], cells: C[][]): C[][] {
  return R.map(change => R.reject(valueChange => valueChange === undefined, change), changes).reduce(
    (acc: C[][], { coordinate: { row, column }, ...rest }): C[][] =>
      R.over(
        R.lensPath([row, column]),
        (cell: C) => ({ ...cell, ...rest }),
        acc,
      ),
    cells,
  );
}

function getCurriedApplyChanges<T extends M.AbstractCellChange, C>() {
  return R.curry<(changes: T[], cells: C[][]) => C[][]>(fn);
}

export { fn as applyChanges, getCurriedApplyChanges };
