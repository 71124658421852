import { AxiosResponse } from 'axios';

import { ErrorResponse, ErrorsListResponse } from '../error';

export function isErrorResponse(response: AxiosResponse): response is AxiosResponse<ErrorResponse> {
  return response.data.userErrorMessage !== undefined  && response.data.userErrorMessage !== null;
}

export function isErrorsListResponse(response: AxiosResponse): response is AxiosResponse<ErrorsListResponse> {
  return response.data.userErrorMessageList !== undefined 
    && response.data.userErrorMessageList !== null
    && Array.isArray(response.data.userErrorMessageList)
    && response.data.userErrorMessageList.length;
}

export function isHandledErrorResponse(response: AxiosResponse): response is AxiosResponse<ErrorsListResponse | ErrorResponse> {
  return isErrorResponse(response) || isErrorsListResponse(response);
}
