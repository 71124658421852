import * as React from 'react';

import block from 'bem-cn';

import { Chip, Tooltip } from '@material-ui/core';

import './style.scss';

const b = block('label');

type OwnProps = {
  tooltip: string;
  label: string;
  color: 'green' | 'yellow';
};

export function Label({ tooltip, label, color }: OwnProps) {
  return (
    <Tooltip classes={{ tooltip: b('tooltip').toString() }} placement="left" title={tooltip}>
      <Chip classes={{ root: b({ color }).toString() }} label={label} />
    </Tooltip>
  );
}
