import * as React from 'react';

import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { isEnterKey } from 'shared/helpers';

import './Input.scss';

const b = block('input');

interface IOwnProps {
  onChange?(value: string): void;
  onEnter?(value: string): void;
  value: string;
  placeholder?: string;
  type?: string;
  isClearable?: boolean;
  autoFocus?: boolean;
  positiveNumbers?: boolean;
}

class Input extends React.Component<IOwnProps> {

  render() {
    const { placeholder, value, type, isClearable, autoFocus, positiveNumbers } = this.props;
    return (
      <div className={b()}>
        <input
          placeholder={placeholder}
          className={b('input')}
          onChange={this.onChange}
          value={value}
          type={type ? type : 'text'}
          min={type === 'number' && positiveNumbers ? '0' : undefined}
          autoFocus={autoFocus}
          onKeyPress={this.onKeyPress}
        />
        {isClearable && <div className={b('clear')} onClick={this.onClear}/>}
      </div>
    );
  }


  @autobind
  private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    const { onEnter } = this.props;
    if (isEnterKey(event) && onEnter) {
      onEnter(event.currentTarget.value);
    }
  }

  @autobind
  private onClear(event: React.MouseEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    if (onChange) {
      event.currentTarget.value = '';
      onChange('');
    }
  }

  @autobind
  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    if (onChange) {
      const value = event.currentTarget.value;
      onChange(value);
    }
  }
}

export { Input };
