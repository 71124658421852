import { Mediaplan } from 'shared/types/models';

import { BudgetMayExceedPlanType } from '../types';
import { checkboxMetricId } from '../constants';

export function getBudgetExceedParameter(mediaplan: Mediaplan): BudgetMayExceedPlanType {
  const budgetCannotExceedPlan = mediaplan.planFactSettings ? mediaplan.planFactSettings.budgetCannotExceedPlan : null;
  const metaMetrics = mediaplan.manualEditedDataMapping ? mediaplan.manualEditedDataMapping.metrics : {};
  const isHasBudgetExceedMetric = metaMetrics[checkboxMetricId];
  if (budgetCannotExceedPlan === null && isHasBudgetExceedMetric) {
    return 'setByLine';
  } else if (budgetCannotExceedPlan === null && !isHasBudgetExceedMetric) {
    return 'mayExceed';
  } else if (budgetCannotExceedPlan) {
    return 'cannotExceed';
  } else {
    return 'mayExceed';
  }
}
