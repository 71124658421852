import * as React from 'react';

import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './RadioList.scss';

type RadioOption<T> = {
  label: string;
  value: T;
};

type OwnProps<T> = {
  onChange(value: T, event: React.ChangeEvent<HTMLInputElement>): void;
  radioOptions: RadioOption<T>[];
  value: T | null;
  inline?: boolean;
};

const b = block('radio-list');

class RadioList<T> extends React.PureComponent<OwnProps<T>> {

  public render() {
    const { radioOptions, value, inline } = this.props;
    return (
      <div className={b({ inline: Boolean(inline) })}>
        <RadioGroup name="periodType" value={value} onChange={this.handleChange}>
          {radioOptions.map((radio, index) => (
            <FormControlLabel
              key={index}
              value={radio.value}
              control={<Radio color="primary" />}
              label={radio.label}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }

  @autobind
  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value as any;
    this.props.onChange(value, event);
  }
}

export default RadioList;
