import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

export function convertClientValueType(type: M.ValueType): SM.ServerValueType {
  const convertMap: Record<M.ValueType, SM.ServerValueType> = {
    date: 'DATE',
    double: 'DOUBLE',
    string: 'STRING',
    dictionary: 'DICTIONARY',
    integer: 'INTEGER',
    percent: 'PERCENT',
    bool: 'BOOL',
  };
  return convertMap[type];
}
