import * as React from 'react';

import ReactSelect, { ValueType, Props as SelectProps } from 'react-select';

import { bind } from 'decko';

import { block } from 'bem-cn';

import { IOption } from 'shared/types/utils';

import './style.scss';

interface IOwnProps extends Omit<SelectProps, 'onChange'> {
  options: IOption[];
  value: IOption['value'];
  onChange?(value: IOption['value']): void;
  placeholder?: string;
}

const b = block('select');

class Select extends React.PureComponent<IOwnProps> {

  public render() {
    const { options, value, ...restProps } = this.props;
    const valueOption = options.find(o => o.value === value);

    return (
      <div className={b()}>
        <ReactSelect
          {...restProps}
          value={valueOption ?? null}
          options={options}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={this.handleSelectChange}
        />
      </div>
    );
  }

  @bind
  private handleSelectChange(option: ValueType<IOption>) {
    const { onChange } = this.props;
    if (onChange && option) {
      onChange((option as any).value);
    }
  }

}

export { Select };
