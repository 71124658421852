import { call, select } from 'redux-saga/effects';

import * as M from 'shared/types/models';
import { Column, MapFactSubIdData, TableCell } from 'shared/types/models/EditMetrics';
import { IDependencies } from 'shared/types/app';

import { notRelevantFoundColumnsForMerge } from '../../../../constants';

import * as helpers from '../../../../helpers';
import { selectors } from '../../../../redux';

type Arguments = {
  columnsForGetData: Column[];
  api: IDependencies['api'];
};

export function* mergeFactContentToCells(args: Arguments) {
  const { api, columnsForGetData } = args;

  const factParams = helpers.makeFactParamsFromColumns(columnsForGetData);

  if (factParams.length) {
    const { mediaplanId, mediaplanVersionId }: M.Mediaplan = yield select(selectors.selectMediaplan);

    const factMetricsContent: MapFactSubIdData = yield call(
      api.metric.loadFact,
      mediaplanId,
      mediaplanVersionId,
      factParams,
    );

    const { cells: actualCells, columns: actualColumns } = yield call(getActualCellsAndColumns);

    const isHasNotFoundColumns = columnsForGetData
      .some(column => !actualColumns.find((x: Column) => x.id === column.id));

    if (isHasNotFoundColumns) {
      throw Error(notRelevantFoundColumnsForMerge);
    }

    return helpers.mergeFactContent({ cells: actualCells, factMetricsContent, columns: actualColumns });
  }

  const { cells } = yield call(getActualCellsAndColumns);
  return { updatedCells: cells, changes: [] };
}

function* getActualCellsAndColumns() {
  const cells: TableCell[][] = yield select(selectors.selectCells);
  const columns: Column[] = yield select(selectors.selectColumns);
  return { cells, columns };
}
