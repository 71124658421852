import * as M from 'shared/types/models';

import { getStringOffsetByTitle } from './calcStringOffset';
import { isNeedAddLineBreak, addLineBreaksToTitle, getMostLongLineInTitle } from '../lineBreaks';
import { HeadersMakerArguments } from '../models';

export function makeColumnTitleHeaders(args: HeadersMakerArguments) {
  const { columnRangeHeaders } = args;
  const columnIndexToTitles = columnRangeHeaders.reduce<M.MarkupMediaplan.ColumnIndexToTitle>((acc, x) =>
    x.columnIndexToTitle ? { ...acc, ...x.columnIndexToTitle } : acc,
    {},
  );
  const headers: string[] = [];
  Object.entries(columnIndexToTitles).forEach(([index, title]) => {
    const formattedTitle = title && isNeedAddLineBreak(title)
      ? addLineBreaksToTitle(title)
      : title || '';
    const mainTitle = getMostLongLineInTitle(formattedTitle);
    const stringTitleOffset = getStringOffsetByTitle(mainTitle);
    headers[index] = `${mainTitle}${stringTitleOffset}`;
  });
  return headers.map(x => x || '');
}
