import { bind } from 'decko';
import { AxiosRequestConfig } from 'axios';

import HttpActions from '../../HttpActions';
import BaseApi from '../../BaseApi';

import { ICookies } from '../cookies/types';

import { accessTokenCookie, refreshTokenCookie } from './constants';
import { convertRefreshTokenRequestKeyCloack } from './converters';
import { IAuthResponse } from './types';

class Auth extends BaseApi {
  public onTokenChanged: (token: string | null) => void;

  constructor(actions: HttpActions, cookies: ICookies, onTokenChanged: (token: string | null) => void) {
    super(actions, cookies);
    this.onTokenChanged = onTokenChanged;
  }

  @bind
  public getToken(): string | null {
    return this.cookies.getCookie(accessTokenCookie);
  }

  @bind
  public getRefreshToken(): string | null {
    return this.cookies.getCookie(refreshTokenCookie);
  }


  @bind
  public setRefreshToken(token: string) {
    this.cookies.setCookie(refreshTokenCookie, token);
  }

  @bind
  public setToken(token: string) {
    this.cookies.setCookie(accessTokenCookie, token);
    this.onTokenChanged(token);
  }

  @bind
  public async refreshToken(): Promise<IAuthResponse> {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      throw new Error('Refresh token has not found in cookies!');
    }

    const data = convertRefreshTokenRequestKeyCloack(refreshToken);

    const response = await this.actions.post<IAuthResponse>({
      url: '/auth/realms/ADV/protocol/openid-connect/token',
      data,
      domainType: 'keyCloackSSOApi',
      options: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
    });

    const responseData = this.handleResponse(response);
    this.setRefreshToken(responseData.refresh_token);
    this.setToken(responseData.access_token);
    return responseData;
  }

  @bind
  public updateConfigWithNewToken(config: AxiosRequestConfig, token: string): AxiosRequestConfig {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    };
  }

}

export default Auth;
