import * as React from 'react';

import { bind } from 'decko';
import { block } from 'bem-cn';

import './Input.scss';

const b = block('input');

interface IOwnProps {
  onChange?(event: React.KeyboardEvent<HTMLInputElement>): void;
  placeholder?: string;
  type?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

class InputRef extends React.Component<IOwnProps> {

  public componentDidMount() {
    this.props.inputRef && this.props.inputRef.current && this.props.inputRef.current.focus();
  }

  render() {
    const { placeholder, type, inputRef } = this.props;
    return (
      <div className={b()}>
        <input
          placeholder={placeholder}
          className={b('input')}
          onKeyUp={this.onChange}
          type={type ? type : 'text'}
          ref={inputRef}
        />
      </div>
    );
  }

  @bind
  private onChange(event: React.KeyboardEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }
  }
}

export { InputRef };
