import { put, call, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { Alert } from 'services';
import { tryCatch } from 'services/ErrorTracking';
import { actionCreators, selectors } from '../../../redux';

export function* loadAmpMetrics({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanId: number | null = yield select(selectors.selectMediaplanID);
      const mediaplanVersionId: number | null = yield select(selectors.selectMediaplanVersionID);
      
      if (mediaplanVersionId === null || mediaplanId === null) {
        throw Error('not correct mediaplan ids');
      }
      const metrics: ReturnPromisedType<typeof api.metric.getMetrics> =
        yield call(api.metric.getAMPMetrics);
      yield put(actionCreators.loadAmpMetricsCompleted({ metrics }));
    },
    *failed(_, message) {
      yield put(Alert.actionCreators.openAlert({ message: 'Ошибка при загрузке AMP метрик', type: 'error' }));
      yield put(actionCreators.loadAmpMetricsFailed(message));
    },
  });
}
