import * as React from 'react';
import { RouteProps } from 'react-router';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { withAsyncFeatures } from 'core';

import * as features from 'features';

import { GlobalPreloader } from 'shared/view/components';
import { IAppReduxState } from 'shared/types/app';

import { ErrorBoundary } from 'services/ErrorTracking';
import { Alert } from 'services/Alert';

import { selectors } from 'features/EditMetrics/redux';
import { getHelmetTale } from 'shared/helpers';

interface IFeatureProps {
  EditMetrics: features.EditMetrics.Entry;
}

interface StateProps {
  initializingTable: boolean;
  loadingMediaplan: boolean;
}

type IProps = RouteProps & IFeatureProps & StateProps;


class EditMetricsPage extends React.PureComponent<IProps> {
  public render() {
    const { EditMetrics, initializingTable, loadingMediaplan } = this.props;

    return (
      <>
        <Helmet defer={false}>
          <title>Редактирование метрик - {getHelmetTale()} Client Space</title>
        </Helmet>
        <ErrorBoundary>
          <GlobalPreloader isShow={initializingTable || loadingMediaplan} />
          <Alert />
          <EditMetrics.containers.EditMetrics />
        </ErrorBoundary>
      </>
    );
  }
}

function mapState(state: IAppReduxState): StateProps {
  return {
    initializingTable: selectors.selectCommunication(state, 'initializingTable').isRequesting,
    loadingMediaplan: selectors.selectCommunication(state, 'loadingMediaplan').isRequesting,
  };
}

export default (
  withAsyncFeatures({ EditMetrics: features.EditMetrics.loadEntry })(
    connect(mapState)(EditMetricsPage)
  )
);
