import Handsontable from 'handsontable';

import { hotTable } from 'shared/helpers';

type Arguments = {
  hotInstance: Handsontable;
  removedIndexes: number[];
  afterClearFiltersCallback?: () => void;
};

export function updateFiltersIndexesAfterRemove(args: Arguments) {
  const { removedIndexes, hotInstance, afterClearFiltersCallback } = args;
  const filters = hotTable.getFilters(hotInstance);
  const filtersForUpdate = filters.filter(x =>
    !removedIndexes.includes(x.column)
    && removedIndexes.some(diffIndex => x.column > diffIndex)
  );

  if (filtersForUpdate.length || filters.some(x => removedIndexes.includes(x.column))) {
    hotTable.clearFilters(hotInstance, [...removedIndexes, ...filtersForUpdate.map(x => x.column)]);
    if (afterClearFiltersCallback) {
      // used callback because handsontable inside can not stable work with not exist indexes
      // for example after delete columns
      afterClearFiltersCallback();
    }
    const updatedFilters = hotTable.shiftLeftFilterColumns(removedIndexes.length, filtersForUpdate);

    hotTable.addFilters(hotInstance, updatedFilters);

    return updatedFilters;
  }
  return [];
}
