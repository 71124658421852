import * as R from 'ramda';

import { MetricColumn, Column } from 'shared/types/models/EditMetrics';

import { DifferenceColumns } from '../../../types';

import { addNewColumns } from './addNewColumns';

export function mergeColumnsFromTemplate(templateColumns: MetricColumn[], columns: Column[]): DifferenceColumns {
  const grouppedTemplateColumns = R.values(R.groupBy(x => x.markupID, templateColumns));

  const columnsForAdd = grouppedTemplateColumns.reduce<MetricColumn[][]>((acc, curTemplateColumns) => {
    const [templateColumn] = curTemplateColumns;
    const foundColumn = columns.find(column =>
      column.kind === 'metric'
      && column.metricID === templateColumn.metricID
      && column.metricType === templateColumn.metricType
    );
    return foundColumn ? acc : [...acc, curTemplateColumns];
  }, []);

  return columnsForAdd.reduce<DifferenceColumns>((acc, newColumns) => {
    const { updatedColumns, diffIndexes } = addNewColumns(newColumns, acc.updatedColumns);
    const diffColumns = diffIndexes.map(index => updatedColumns[index]);
    return {
      updatedColumns,
      diffIndexes: [...acc.diffIndexes, ...diffIndexes],
      diffColumns: [...acc.diffColumns, ...diffColumns]
    };
  }, { updatedColumns: columns, diffIndexes: [], diffColumns: [] });
}
