import Handsontable from 'handsontable';

export function metricValidator(this, value: string, resolve: (valid: boolean) => void) {
  const isNumberMoreZero = Number(value) >= 0;

  let isNumber: boolean = true;
  const getValidStateByHTValidator = (valid: boolean) => { isNumber = valid; };

  Handsontable.validators.NumericValidator.bind(this)(value, getValidStateByHTValidator);

  resolve(isNumber && isNumberMoreZero);
}
