import * as M from 'shared/types/models';

export function isDictionaryCell(cell: M.ExportAMP.Cell): cell is M.ExportAMP.DictionaryCell {
  return cell.kind === 'dictionary';
}

export function isAutocorrectedCell(cell: M.ExportAMP.Cell): cell is M.ExportAMP.DictionaryCell {
  return isDictionaryCell(cell) && cell.isAutocorrected;
}

export function isDictionaryColumn(column: M.ExportAMP.Column): column is M.ExportAMP.AttributeColumn {
  return column.kind === 'attribute' && column.valueType === 'dictionary';
}

export function isMetricColumn(column: M.ExportAMP.Column): column is M.ExportAMP.MetricColumn {
  return column.kind === 'metric'
}
