import Handsontable from 'handsontable';
import { hotTable } from 'shared/helpers';

export function clearFilters(ht: Handsontable, columnsIndexes?: number[]) {
  const filters = hotTable.getFilters(ht);
  const filtersPlugin = ht.getPlugin('filters');
  if (filters.length) {
    if (columnsIndexes) {
      columnsIndexes
        .filter(index => filters.some(x => x.column === index))
        .forEach(index => filtersPlugin.removeConditions(index));
    } else {
      filtersPlugin.clearConditions();
    }
  }
}
