import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { compose, applyMiddleware, combineReducers, createStore, Reducer, Middleware, Store } from 'redux';

import { IAppReduxState } from 'shared/types/app';
import { ReducersMap } from 'shared/types/redux';

import * as services from '../services';
import { ErrorTracking } from 'services/ErrorTracking';

interface IStoreData {
  store: Store<IAppReduxState>;
  runSaga: SagaMiddleware<any>['run'];
}

function handleSagasError(this: any, error: Error) {
  const dispatch = this.dispatch;
  ErrorTracking.getInstance().captureException(error);
  dispatch(services.Alert.actionCreators.openAlert({ type: 'error', message: 'Возникла программная ошибка' }));
  console.error(error);
}

function configureStore(): IStoreData {
  const sagaMiddleware = createSagaMiddleware({ onError: handleSagasError });
  const sentryMiddleware = ErrorTracking.getInstance().makeReduxMiddleware();
  const middlewares: Middleware[] = [sagaMiddleware, sentryMiddleware];

  const composeEnhancers = process.env.NODE_ENV === 'development'
    ? composeWithDevTools({})
    : compose;

  // TODO fix type when legacy gets removed
  const store: Store<any> =
    createStore(
      combineReducers({ ...servicesReducersMap }),
      composeEnhancers(applyMiddleware(...middlewares))
    );


  return { store, runSaga: sagaMiddleware.run };
}

const servicesReducersMap: Partial<ReducersMap<IAppReduxState>> = {
  Scale: services.Scale.reducer,
  ReloadPage: services.ReloadPage.reducer,
  Alert: services.Alert.reducer,
  CommonData: services.CommonData.reducer,
  Warning: services.Warning.reducer,
  AmpCampaigns: services.AmpCampaigns.reducer,
  DownloadFile: services.DownloadFile.reducer,
  Templates: services.Templates.reducer,
};

function createReducer(reducers: ReducersMap<IAppReduxState>): Reducer<IAppReduxState> {
  return combineReducers<IAppReduxState>({ ...servicesReducersMap, ...reducers });
}

export { createReducer, configureStore };
