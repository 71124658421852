import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';
import { TextField } from '@material-ui/core';

import { Button } from 'shared/view/elements';

import './style.scss';

type Props = {
  isDisabled: boolean;
  saveTemplate(name: string, year: number): void;
};

type State = {
  name: string;
  year: number;
};

const b = block('save-template');

class SaveTemplate extends React.PureComponent<Props, State> {
  public state: State = {
    name: '',
    year: (new Date()).getFullYear(),
  };

  public render() {
    const { isDisabled } = this.props;
    const { name, year } = this.state;

    return (
      <div className={b()}>
        <div className={b('field-wrapper')}>
          <TextField
            label="Имя шаблона"
            value={name}
            onChange={this.handleNameInputChange}
            onBlur={this.handleNameInputBlur}
          />
          <TextField
            type="number"
            label="Год"
            value={year}
            onChange={this.handleYearInputChange}
          />
        </div>
        <Button
          fullWidth
          disabled={isDisabled || !name || String(year).length < 4}
          onClick={this.handleSaveButtonClick}
        >
          Сохранить
        </Button>
      </div>
    );
  }

  @autobind
  private handleNameInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.value;
    this.setState({ name });
  }

  @autobind
  private handleNameInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    const name = event.target.value.trim();
    this.setState({ name });
  }

  @autobind
  private handleYearInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const year = event.target.value.replace(/[^0-9]/g, '');
    this.setState({ year: Number(year) });
  };

  @autobind
  private handleSaveButtonClick(): void {
    const { name, year } = this.state;
    this.props.saveTemplate(name, year);
  }
}

export default SaveTemplate;
