import { autobind } from 'core-decorators';

import * as M from 'shared/types/models';
import BaseApi from '../../BaseApi';

class MediafactManual extends BaseApi {
  @autobind
  public async generateTemplate(data: GenerateTemplateArgs): Promise<M.File> {
    const response = await this.actions.post<M.File>({
      url: '/mediafact-manual/xlsx/template/generate',
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }
}

type GenerateTemplateArgs = {
  campaignName: string;
  mediaplan: Pick<M.Mediaplan, 'mediaplanId' | 'mediaplanVersionId' | 'metadata'> & {
    mediafactManualTemplateContent: M.Mediaplan['content'];
  };
}
  
export default MediafactManual;
