import React from 'react';
import block from 'bem-cn';

import './styles.scss';

const b = block('error-overlay');

export const ErrorOverlay: React.FC = () => {
  return (
    <div className={b()}>
      <div className={b('message-wrapper')}>
        <div className={b('message')}>Неизвестная ошибка</div>
        <div className={b('message')}>
          Обратитесь в службу поддержки{' '}
        </div>
        <div className={b('message')}>
          <a className={b('link')} href="mailto:support@aizek.io">support@aizek.io</a>
        </div>
      </div>
    </div>
  );
};
