import { makeCommunicationActionCreators } from 'shared/helpers/redux';
import * as NS from '../types';

export const { execute: loadMetrics, completed: loadMetricsCompleted, failed: loadMetricsFailed } =
  makeCommunicationActionCreators<NS.LoadMetrics, NS.LoadMetricsCompleted, NS.LoadMetricsFailed>(
    'COMMON_DATA_SERVICE:LOAD_METRICS', 'COMMON_DATA_SERVICE:LOAD_METRICS_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_METRICS_FAILED',
  );

export const { execute: loadAttributes, completed: loadAttributesCompleted, failed: loadAttributesFailed } =
  makeCommunicationActionCreators<NS.LoadAttributes, NS.LoadAttributesCompleted, NS.LoadAttributesFailed>(
    'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES', 'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES_FAILED',
  );

export const { execute: loadMediaplan, completed: loadMediaplanCompleted, failed: loadMediaplanFailed } =
  makeCommunicationActionCreators<NS.LoadMediaplan, NS.LoadMediaplanCompleted, NS.LoadMediaplanFailed>(
    'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN', 'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN_FAILED',
  );

export const { execute: loadCabinets, completed: loadCabinetsCompleted, failed: loadCabinetsFailed } =
  makeCommunicationActionCreators<NS.LoadCabinets, NS.LoadCabinetsCompleted, NS.LoadCabinetsFailed>(
    'COMMON_DATA_SERVICE:LOAD_CABINETS', 'COMMON_DATA_SERVICE:LOAD_CABINETS_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_CABINETS_FAILED',
  );

export const { execute: loadAmpAttributes, completed: loadAmpAttributesCompleted, failed: loadAmpAttributesFailed } =
  makeCommunicationActionCreators<NS.LoadAmpAttributes, NS.LoadAmpAttributesCompleted, NS.LoadAmpAttributesFailed>(
    'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES', 'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES_FAILED',
  );

export const { execute: loadAmpMetrics, completed: loadAmpMetricsCompleted, failed: loadAmpMetricsFailed } =
  makeCommunicationActionCreators<NS.LoadAMPMetrics, NS.LoadAMPMetricsCompleted, NS.LoadAMPMetricsFailed>(
    'COMMON_DATA_SERVICE:LOAD_AMP_METRICS', 'COMMON_DATA_SERVICE:LOAD_AMP_METRICS_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_AMP_METRICS_FAILED',
  );

export const { execute: loadCSDictionaries, completed: loadCSDictionariesCompleted, failed: loadCSDictionariesFailed } =
  makeCommunicationActionCreators<NS.LoadCSDictionaries, NS.LoadCSDictionariesCompleted, NS.LoadCSDictionariesFailed>(
    'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES', 'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES_FAILED',
  );

export const { execute: loadCsCampaignInfo, completed: loadCsCampaignInfoCompleted, failed: loadCsCampaignInfoFailed } =
  makeCommunicationActionCreators<NS.LoadCsCampaignInfo, NS.LoadCsCampaignInfoCompleted, NS.LoadCsCampaignInfoFailed>(
    'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO', 'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO_FAILED',
  );

export const { execute: loadCsAmpCampaignInfo, completed: loadCsAmpCampaignInfoCompleted, failed: loadCsAmpCampaignInfoFailed } =
  makeCommunicationActionCreators<NS.LoadCsAmpCampaignInfo, NS.LoadCsAmpCampaignInfoCompleted, NS.LoadCsAmpCampaignInfoFailed>(
    'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO', 'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO_COMPLETE', 'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO_FAILED',
  );
