import { call, put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { tryCatch } from 'services/ErrorTracking';
import { actionCreators, selectors } from '../../../redux';

export function* loadCsCampaignInfo({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanId: number | null = yield select(selectors.selectMediaplanID);
      const mediaplanVersionId: number | null = yield select(selectors.selectMediaplanVersionID);

      if (!mediaplanId || !mediaplanVersionId) {
        throw Error('mediaplanId not found');
      }
      const campaignInfo: ReturnPromisedType<typeof api.mediaplan.getCsCampaignInfo> =
        yield call(api.mediaplan.getCsCampaignInfo, { 
          mediaplanVersionID: mediaplanVersionId,
          mediaplanID: mediaplanId,
        });
      yield put(actionCreators.loadCsCampaignInfoCompleted({ csCampaignInfo: campaignInfo }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadCsCampaignInfoFailed(message));
    },
  });
}
