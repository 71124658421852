import { AxiosResponse } from 'axios';

import { ErrorsListResponse, ErrorResponse } from '../model';

import { formatErrorTemplates, isErrorResponse, isErrorsListResponse } from '../../helpers';

import ApiError from './ApiError';

export default class HandledApiError extends ApiError {

  public errorsMessages: string[] = [];

  constructor(response: AxiosResponse<ErrorsListResponse | ErrorResponse>) {
    super({ response });
    this.errorsMessages = HandledApiError.getErrorMessages(response);
  }

  static getErrorMessages(response: AxiosResponse<ErrorsListResponse | ErrorResponse>): string[] {
    if (isErrorsListResponse(response)) {
      return response.data.userErrorMessageList.map(({ errorMessage, errorParameters }) =>
        formatErrorTemplates(errorMessage, errorParameters)
      );
    }
    if (isErrorResponse(response)) {
      const { errorMessage, errorParameters } = response.data.userErrorMessage;
      return [formatErrorTemplates(errorMessage, errorParameters)];
    }
    return [];
  }
}

export function isHandledApiError(error: any): error is HandledApiError {
  return error && error.isApiError && error instanceof HandledApiError;
}
