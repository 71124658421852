import * as React from 'react';
import { Switch, Route } from 'react-router';

import { RouteWithConfirm } from 'shared/view/components';
import { ReloadPage } from 'services';
import * as pages from './pages';

export class App extends React.PureComponent {
  public render() {
    return (
      <>
        <ReloadPage.ReloadPageModal />
        <Switch>
          <RouteWithConfirm path="/markup-mediaplan" component={pages.MarkupMediaplanPage} />
          <RouteWithConfirm path="/export-amp" component={pages.ExportAMP} />
          <RouteWithConfirm path="/export-fact-amp" component={pages.ExportFactAMP} />
          <RouteWithConfirm path="/edit-metrics" component={pages.EditMetricsPage} />
          <RouteWithConfirm path="/link-cabinets" component={pages.MatchingCabinetsPage} />
          <RouteWithConfirm path="/export-cabinet/select-rows" component={pages.SelectingRowsToExportCabinet} />
          <RouteWithConfirm path="/export-cabinet/prepare-mediaplan" component={pages.PrepareMediaplanToExportCabinet} />
          <RouteWithConfirm path="/fact-template" component={pages.CabinetNoApiFactTemplate} />
          <Route component={pages.PageNotFound} />
        </Switch>
      </>
    );
  }
}
