import * as R from 'ramda';
import Handsontable from 'handsontable';

import { Column } from 'shared/types/models/EditMetrics';
import { IAppReduxState } from 'shared/types/app';
import { getNumericFormatting, checkboxCellRenderer} from 'shared/helpers/handsontableHelpers';

import { WithoutRoundingEditor } from 'features/EditMetrics/view/containers/MetricsTable/WithoutRoundingEditor';

import { selectMetrics } from '../../redux/selectors';
import { metricsWithoutRounding, checkboxMetricId } from '../../constants';

import { isMarkupBudgetColumn } from '../columns';
import { metricValidator } from '../validators';

import { makeCellRenderer } from './makeCellRenderer';
import { isMetricWithPercentFormat } from '../common';

const numericFormat = getNumericFormatting(2);

export function makeMetaByColumn(
  column: Column,
  getState: () => IAppReduxState
): Handsontable.ColumnSettings {
  const makeRenderer = R.curry(makeCellRenderer)(R.__, getState);
  const metrics = selectMetrics(getState());

  switch (column.kind) {

    case 'attribute': {
      return {
        readOnly: true,
        renderer: makeRenderer(Handsontable.renderers.TextRenderer)
      };
    }

    case 'ratio': {
      return {
        type: 'numeric',
        readOnly: true,
        numericFormat: {
          ...numericFormat,
          pattern: { ...numericFormat.pattern as any, output: 'percent' },
        },
        renderer: makeRenderer(Handsontable.renderers.NumericRenderer),
      };
    }

    case 'id': {
      return {
        readOnly: true,
        renderer: makeRenderer(Handsontable.renderers.TextRenderer)
      };
    }

    case 'metric': {

      if (column.metricID === checkboxMetricId) {
        return { type: 'checkbox', renderer: makeRenderer(checkboxCellRenderer) };
      }

      const isMetricWithoutRounding = metrics.find(metric =>
        metric.id === column.metricID
        && metricsWithoutRounding.includes(column.metricID)
        );

      const readOnly = (() => {
        const foundMetric = metrics.find(metric => metric.id === column.metricID);
        if (foundMetric) {
          return column.metricType === 'plan'
            ? !foundMetric.isPlanEditable
            : !foundMetric.isFactEditable || foundMetric.isCustomArchived;
        }
        return true;
      })();

      return {
        type: 'numeric',
        validator: metricValidator,
        editor: isMetricWithoutRounding ? WithoutRoundingEditor : undefined,
        numericFormat: {
          ...numericFormat,
          pattern: {
            ...numericFormat.pattern as any,
            output: isMetricWithPercentFormat(column.metricID) ? 'percent' : 'number',
          },
        },
        renderer: makeRenderer(Handsontable.renderers.NumericRenderer),
        readOnly: isMarkupBudgetColumn(column) || readOnly,
      };
    }
  }
}