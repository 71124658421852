import * as React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { block } from 'bem-cn';

import { Alert as AlertService, CommonData } from 'services';
import withCheckAccessAction from 'services/AccessActionModal/withCheckAction';
import * as M from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { Button, Card } from 'shared/view/elements';
import { SaveTemplate, TemplateSelection } from '../../components';
import { actionCreators, selectors } from '../../../redux';

import './style.scss';

type OwnProps = {
  templateType: M.TemplateType;
  hasMarkedUpColumns: boolean;
  applicationConfirmText: string;
  acceptTemplate: (template: M.TemplateData, templateId: number) => void;
  saveTemplate: (args: { name: string, year: number }) => void;
  isApplyBtnDisabled?: boolean;
  isSaveBtnDisabled?: boolean;
};

type StateProps = {
  mediaplanId: number | null;
  templates: M.Template[];
  dictionaries: M.TemplateDictionary[];
  isLoading: boolean;
  isLoadingBrandDictionary: boolean;
};

type ActionProps = typeof containerActionCreators;

type Props = OwnProps & StateProps & ActionProps;

type State = {
  currentTemplateId: number | null;
};

const b = block('templates');

class Templates extends React.Component<Props, State> {
  private ApplyButton = withCheckAccessAction({
    WrappedComponent: Button,
    checkedAction: 'onClick',
    conditionShowWarning: () => this.props.hasMarkedUpColumns,
    getTitle: () => this.props.applicationConfirmText,
  });

  public state: State = {
    currentTemplateId: null,
  };

  public componentDidMount() {
    const { templateType, loadTemplates, loadDictionaries } = this.props;
    loadTemplates({ type: templateType });
    loadDictionaries();
  }

  public render() {
    const {
      mediaplanId,
      dictionaries,
      templates,
      isApplyBtnDisabled,
      isSaveBtnDisabled,
      isLoading,
      isLoadingBrandDictionary,
      loadBrandDictionary,
    } = this.props;
    const { currentTemplateId } = this.state;

    return (
      <div className={b()}>
        <Card variant="clear">
          <TemplateSelection
            mediaplanId={mediaplanId}
            dictionaries={dictionaries}
            templates={templates}
            currentTemplateId={currentTemplateId}
            makeHandleTemplateItemChange={this.makeHandleTemplateItemChange}
            makeHandleDeleteButtonClick={this.makeHandleDeleteButtonClick}
            loadBrandDictionary={loadBrandDictionary}
            isLoadingBrandDictionary={isLoadingBrandDictionary}
          />
          <this.ApplyButton onClick={this.handleApplyButtonClick} disabled={isApplyBtnDisabled || currentTemplateId === null} fullWidth>
            Применить шаблон
          </this.ApplyButton>
        </Card>
        <Card title="Сохранить текущую разметку">
          <SaveTemplate
            isDisabled={isSaveBtnDisabled || isLoading}
            saveTemplate={this.handleSaveButtonClick}
          />
        </Card>
        <AlertService.Alert />
      </div >
    );
  }

  @autobind
  private makeHandleTemplateItemChange(currentTemplateId: number) {
    return () => {
      this.setState({ currentTemplateId });
    };
  };

  @autobind
  private makeHandleDeleteButtonClick(templateId: number) {
    return () => {
      this.props.deleteTemplate({ templateId });
      if (this.state.currentTemplateId === templateId) {
        this.setState({ currentTemplateId: null });
      }
    };
  };

  @autobind
  private handleApplyButtonClick() {
    const { templateType: type, loadTemplate, acceptTemplate } = this.props;
    const { currentTemplateId: templateId } = this.state;
    if (templateId) {
      loadTemplate({ templateId, type, acceptTemplate });
    }
  }

  @autobind
  private async handleSaveButtonClick(name: string, year: number) {
    const { templates, openAlert, saveTemplate } = this.props;
    const isNotUnique = templates.some(template => template.name === name);
    if (isNotUnique) {
      openAlert({ message: 'Шаблон с таким именем существует', type: 'error' });
      return;
    }
    saveTemplate({ name, year });
  }
}

const containerActionCreators = {
  loadTemplates: actionCreators.loadTemplates,
  loadTemplate: actionCreators.loadTemplate,
  loadDictionaries: actionCreators.loadDictionaries,
  loadBrandDictionary: actionCreators.loadBrandDictionary,
  deleteTemplate: actionCreators.deleteTemplate,
  openAlert: AlertService.actionCreators.openAlert,
};

function mapState(state: IAppReduxState): StateProps {
  return {
    mediaplanId: CommonData.selectors.selectMediaplanID(state),
    templates: selectors.selectTemplates(state),
    dictionaries: selectors.selectTemplateDictionaries(state),
    isLoading: selectors.selectCommunication(state, 'loadingTemplate').isRequesting
      || selectors.selectCommunication(state, 'applyingTemplate').isRequesting
      || selectors.selectCommunication(state, 'deletingTemplate').isRequesting
      || selectors.selectCommunication(state, 'savingTemplate').isRequesting,
    isLoadingBrandDictionary: selectors.selectCommunication(state, 'loadingBrandDictionary').isRequesting,
  };
}

export default connect(mapState, containerActionCreators)(Templates);
