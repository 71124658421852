import Handsontable from 'handsontable';

import * as M from 'shared/types/models';

type Arguments = {
  hotInstance: Handsontable;
  changes: M.CellContentChange<M.StandardContent>[];
  isSetToSourceData?: boolean;
  source?: M.ChangeSource;
};

export function applyChanges(args: Arguments) {
  const { hotInstance, changes, isSetToSourceData = false, source = 'appDriven' } = args;
  const contentChanges = changes
    .filter(x => x.content !== undefined)
    .map(x => [
      x.coordinate.row,
      x.coordinate.column,
      x.content!,
    ] as [number, number, string]);

  if (isSetToSourceData) {
    hotInstance.setSourceDataAtCell(contentChanges);
  } else {
    hotInstance.setDataAtCell(contentChanges, source);
  }
}
