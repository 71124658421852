import * as M from 'shared/types/models';

import { v4 as uuidv4 } from 'uuid';

export function makeCSIDColumn(contentIndex: number): M.CSIDColumn {
  const id = uuidv4();
  return {
    id,
    markupID: id,
    kind: 'csid',
    title: 'CSID',
    contentIndex,
  };
}
