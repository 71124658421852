import block from 'bem-cn';
import * as React from 'react';

import * as M from 'shared/types/models';
import { AmpCampaignInfo, CsCampaignInfo } from 'shared/view/components/index';

import './style.scss';

type Props = { info: M.CsAmpCampaignInfo };

const b = block('cs-amp-campaign-info');

function CsAmpCampaignInfo(props: Props) {
  const { info } = props;
  return (
    <div className={b()}>
      <CsCampaignInfo info={info} />
      <AmpCampaignInfo info={info} />
    </div>
  );
}

export { CsAmpCampaignInfo };
