import * as R from 'ramda';

import { MetricColumn, Column, RatioColumn } from 'shared/types/models/EditMetrics';

import { addRatioColumns } from './addRatioColumns';
import { sortMetricColumnsByOrder } from './sortMetricColumnsByOrder';

export function addNewColumns(newColumns: MetricColumn[], columns: Column[], startColumnIndex?: number) {
  const [newColumn] = newColumns;
  const startRelatedColumnIndex = startColumnIndex ?? columns.findIndex(x => x.kind === 'metric' && x.metricID === newColumn.metricID);

  if (startRelatedColumnIndex === -1) {
    return {
      updatedColumns: [...columns, ...newColumns],
      diffIndexes: newColumns.map((_, i) => i + columns.length),
      diffColumns: newColumns,
    };
  }

  const relatedColumns = columns
    .filter(x => (x.kind === 'metric' || x.kind === 'ratio') && x.metricID === newColumn.metricID) as MetricColumn[];

  const updatedNewColumns = sortMetricColumnsByOrder([...relatedColumns, ...newColumns]);
  const withRatioColumns = addRatioColumns(updatedNewColumns) as (MetricColumn | RatioColumn)[];

  const updatedColumns = R.insertAll<Column>(startRelatedColumnIndex, withRatioColumns)(
    columns.filter(column => !relatedColumns.find(x => x.markupID === column.markupID))
  );

  const diffIndexes = withRatioColumns
    .reduce<number[]>((acc, column, index) => {
      const foundColumn = relatedColumns.find(x => x.kind === column.kind && x.metricType === column.metricType);
      return foundColumn ? acc : [...acc, index];
    }, [])
    .map(x => x + startRelatedColumnIndex);

  const diffColumns = diffIndexes.map(index => updatedColumns[index]);

  return { updatedColumns, diffIndexes, diffColumns };
}
