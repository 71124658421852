import { call, put } from 'redux-saga/effects';

import { tryCatch } from 'services/ErrorTracking';
import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { actionCreators } from '../../../redux';
import * as A from '../../../types/actions';

export function* loadTemplates({ api }: IDependencies, { payload: { type } }: A.LoadTemplates) {
  yield tryCatch({
    *successed() {
      const templates: ReturnPromisedType<typeof api.template.getTemplates> = yield call(api.template.getTemplates, type);
      yield put(actionCreators.loadTemplatesSuccess({ templates }));
    },
    *failed(error, message) {
      yield put(actionCreators.loadTemplatesFail(message));
    }
  });
}
