import * as React from 'react';
import block from 'bem-cn';
import Tooltip from 'react-tooltip';
import { throttle } from 'throttle-debounce';

import './style.scss';

const b = block('react-tooltip');

const ReactTooltip = () => (
  <Tooltip className={b()} place="top" effect="solid" offset={{ top: -4 }} />
);

const { show, hide, rebuild } = Tooltip;

const refresh = throttle(100, false, () => {
  rebuild();
  hide();
});

export { ReactTooltip as Component, show, hide, rebuild, refresh };
