export function getMatrixByOrderedIndexes<T>(data: T[][], columnsIndexes: number[]): T[][] {
  return data.reduce<T[][]>((acc, row) => {
    const rowData = columnsIndexes.map(index => row[index]);
    return [...acc, rowData];
  }, []);
}

export function getMatrixByOrderedIndexesWithEmptyColumns<T>(data: T[][], columnsIndexes: (number | null)[]): (T | null)[][] {
  return data.reduce<(T | null)[][]>((acc, row) => {
    const rowData = columnsIndexes.map(index => index === null ? null : row[index]);
    return [...acc, rowData];
  }, []);
}
