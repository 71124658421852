import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'redux-make-communication';

import { initialState } from '../intialState';
import * as TS from '../../types';

export const communication = combineReducers<TS.ReduxState['communication']>({
  loadingTemplates: makeCommunicationReducer<TS.LoadTemplates, TS.LoadTemplatesSuccess, TS.LoadTemplatesFail>(
    'TEMPLATES_SERVICE:LOAD_TEMPLATES',
    'TEMPLATES_SERVICE:LOAD_TEMPLATES_SUCCESS',
    'TEMPLATES_SERVICE:LOAD_TEMPLATES_FAIL',
    initialState.communication.loadingTemplates,
  ) as any,

  loadingDictionaries: makeCommunicationReducer<TS.LoadDictionaries, TS.LoadDictionariesSuccess, TS.LoadDictionariesFail>(
    'TEMPLATES_SERVICE:LOAD_DICTIONARIES',
    'TEMPLATES_SERVICE:LOAD_DICTIONARIES_SUCCESS',
    'TEMPLATES_SERVICE:LOAD_DICTIONARIES_FAIL',
    initialState.communication.loadingDictionaries,
  ) as any,

  loadingBrandDictionary: makeCommunicationReducer<TS.LoadBrandDictionary, TS.LoadBrandDictionarySuccess, TS.LoadBrandDictionaryFail>(
    'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY',
    'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY_SUCCESS',
    'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY_FAIL',
    initialState.communication.loadingBrandDictionary,
  ) as any,

  loadingTemplate: makeCommunicationReducer<TS.LoadTemplate, TS.LoadTemplateSuccess, TS.LoadTemplateFail>(
    'TEMPLATES_SERVICE:LOAD_TEMPLATE',
    'TEMPLATES_SERVICE:LOAD_TEMPLATE_SUCCESS',
    'TEMPLATES_SERVICE:LOAD_TEMPLATE_FAIL',
    initialState.communication.loadingTemplate,
  ) as any,

  applyingTemplate: makeCommunicationReducer<TS.ApplyTemplate, TS.ApplyTemplateSuccess, TS.ApplyTemplateFail>(
    'TEMPLATES_SERVICE:APPLY_TEMPLATE',
    'TEMPLATES_SERVICE:APPLY_TEMPLATE_SUCCESS',
    'TEMPLATES_SERVICE:APPLY_TEMPLATE_FAIL',
    initialState.communication.applyingTemplate,
  ) as any,

  deletingTemplate: makeCommunicationReducer<TS.DeleteTemplate, TS.DeleteTemplateSuccess, TS.DeleteTemplateFail>(
    'TEMPLATES_SERVICE:DELETE_TEMPLATE',
    'TEMPLATES_SERVICE:DELETE_TEMPLATE_SUCCESS',
    'TEMPLATES_SERVICE:DELETE_TEMPLATE_FAIL',
    initialState.communication.deletingTemplate,
  ) as any,

  savingTemplate: makeCommunicationReducer<TS.SaveTemplate, TS.SaveTemplateSuccess, TS.SaveTemplateFail>(
    'TEMPLATES_SERVICE:SAVE_TEMPLATE',
    'TEMPLATES_SERVICE:SAVE_TEMPLATE_SUCCESS',
    'TEMPLATES_SERVICE:SAVE_TEMPLATE_FAIL',
    initialState.communication.savingTemplate,
  ) as any,
});
