import * as R from 'ramda';
import { IAppReduxState } from 'shared/types/app';
import makeSelectFeatureState from 'shared/helpers/redux/makeSelectFeatureState';
import { makeCommunicationSelector } from 'shared/helpers/redux';
import {
  Attribute,
  Metric,
  Mediaplan,
  Cabinet,
  AttributeAMP,
  DictionaryValue,
  AttributeIdToCSDictionaryMap,
  CsCampaignInfo,
  CsAmpCampaignInfo,
} from 'shared/types/models';
import { createSelector } from 'reselect';

export const selectFeatureState = makeSelectFeatureState('CommonData');
export const selectCommunication = makeCommunicationSelector(selectFeatureState);

export function selectMediaplanID(state: IAppReduxState): number | null {
  return selectFeatureState(state).data.mediaplanID;
}

export function selectMediaplanVersionID(state: IAppReduxState): number | null {
  return selectFeatureState(state).data.mediaplanVersionID;
}

export function selectAttributes(state: IAppReduxState): Attribute[] {
  return selectFeatureState(state).data.attributes;
}

export function selectCSMetrics(state: IAppReduxState): Metric[] {
  return selectFeatureState(state).data.metrics;
}

export function selectAmpMetrics(state: IAppReduxState): Metric[] {
  return selectFeatureState(state).data.ampMetrics;
}

export function selectMediaplan(state: IAppReduxState): Mediaplan | null {
  return selectFeatureState(state).data.mediaplan;
}

export const selectMediaplanMetadata = createSelector(
  selectMediaplan,
  mediaplan => mediaplan?.metadata ?? null,
);

export function selectCabinets(state: IAppReduxState): Cabinet[] {
  return selectFeatureState(state).data.cabinets;
}

export function selectAmpAttributes(state: IAppReduxState): AttributeAMP[] {
  return selectFeatureState(state).data.ampAttributes;
}

export function selectAmpAttributeByID(state: IAppReduxState, ampAttributeID: number) {
  const ampAttributes = selectAmpAttributes(state);
  return ampAttributes.find(x => x.id === ampAttributeID) ?? null;
}

export const selectMetrics = createSelector(
  selectCSMetrics,
  selectAmpMetrics,
  (csMetrics, ampMetrics) => [...csMetrics, ...ampMetrics],
);

export function selectCSDictionariesMap(state: IAppReduxState): AttributeIdToCSDictionaryMap {
  return selectFeatureState(state).data.csDictionariesMap;
}

export function selectHasCSDictionaries(state: IAppReduxState): boolean {
  return !R.isEmpty(selectCSDictionariesMap(state));
}

export function selectCSDictionaryValuesByAttributeId(state: IAppReduxState, attributeId: number): DictionaryValue[] {
  return selectFeatureState(state).data.csDictionariesMap[attributeId]?.values ?? [];
}

export function selectCsCampaignInfo(state: IAppReduxState): CsCampaignInfo | null {
  return selectFeatureState(state).data.csCampaignInfo;
}

export function selectCsAmpCampaignInfo(state: IAppReduxState): CsAmpCampaignInfo | null {
  return selectFeatureState(state).data.csAmpCampaignInfo;
}
