import * as R from 'ramda';

import { maxAmountErrorMessages } from 'shared/constants';

import { cropAndFormatItems } from './cropAndFormatItems';

function cropErrorMessages(errorMessages: string[]) {
  return errorMessages.length > maxAmountErrorMessages
    ? errorMessages.slice(0, maxAmountErrorMessages)
    : errorMessages;
}

export const formatErrorMessages: (msgs: string[]) => string
  = R.partial(cropAndFormatItems, [cropErrorMessages, R.identity, '<br>']);
