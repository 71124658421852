import * as R from 'ramda';

import { concatMatrices, getMatrixByOrderedIndexes } from 'shared/helpers';
import { getInitialCellValue } from 'shared/helpers/cell/getInitialCellValue';
import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import { Column, EditableMetric, TableCell, CellContent, AttributeColumn, MetricColumn } from 'shared/types/models/EditMetrics';
import { IAppReduxState } from 'shared/types/app';
import { getStandardContent } from 'shared/helpers/cell/getStandardContent';

import { checkboxMetricId, stylesChangedCell, stylesReadonlyCell } from '../../constants';

import { getRatioColumnsIndexes, getMarkupBudgetColumnsIndexes } from '../columns/updates';
import { isMarkupBudgetColumn } from '../columns';

import { insertEmptyColumnsCells } from './baseOperations';
import { updateCellsStyles } from './updates';

type Arguments = {
  mediaplan: M.Mediaplan;
  columns: Column[];
  metrics: EditableMetric[];
  getState: () => IAppReduxState;
};

export function initializeCells(args: Arguments): TableCell[][] {
  const {
    mediaplan: { manualEditedDataMapping, manualEditedDataContent },
    columns, mediaplan, metrics, getState,
  } = args;

  const idContent = getIdContent(mediaplan.metadata.rowIdColumnNum, mediaplan.content);
  const attributesContent = getAttributesContent(columns, mediaplan.content);

  const amountMetricsColumns = calcAmountMetricsColumns(manualEditedDataMapping.metrics);
  const metricsContent = getMetricsContent(columns, manualEditedDataContent);

  const checkboxMetricIndexes = getCheckboxMetricColumnIndexes(manualEditedDataMapping.metrics);
  const mergedContent = mergeMetricsContentToMediaplan(
    concatMatrices([idContent, attributesContent]),
    metricsContent,
    amountMetricsColumns,
    checkboxMetricIndexes,
  );

  const attributesColumns = columns.filter(markup => markup.kind === 'attribute');
  const attributesOffset = attributesColumns.length;
  const cells = convertContentToCells(mergedContent, attributesOffset, columns, getState);

  const ratioColumnsIndexes = getRatioColumnsIndexes(columns);
  const markupBudgetColumnsIndexes = getMarkupBudgetColumnsIndexes(columns);
  const diffIndexes = [...ratioColumnsIndexes, ...markupBudgetColumnsIndexes].sort((a, b) => a - b);
  const updatedCells = insertEmptyColumnsCells(cells, diffIndexes);

  return updateCellsStyles({ cells: updatedCells, metrics, columns, columnsIndexes: diffIndexes });
}

function convertContentToCells(
  content: CellContent[][],
  attributesOffset: number,
  columns: Column[],
  getState: () => IAppReduxState,
): TableCell[][] {
  return content.map(row =>
    row.map<M.EditMetrics.TableCell>((cellValue, columnIndex) => {
      const column = columns[columnIndex];
      const content = M.typeGuards.isTypedColumn(column, cellValue)
        ? getInitialCellValue(cellValue, (column as M.AttributeColumn).attributeID, getState)
        : getStandardContent(cellValue);
      const fullContent = M.typeGuards.isTypedContent(cellValue) ? cellValue : null;

      if (columnIndex > attributesOffset) {
        return content !== null
          ? { content, style: stylesChangedCell, isChanged: true, fullContent }
          : { content: null, style: {}, fullContent };
      } else {
        return { content, style: stylesReadonlyCell, fullContent };
      }
    })
  );
}

function getCheckboxMetricColumnIndexes(metricsMap: Record<number, SM.MetaMetric[]>) {
  return (metricsMap[checkboxMetricId] || []).map(x => x.columnNum);
}

function calcAmountMetricsColumns(metricsMap: Record<number, SM.MetaMetric[]>) {
  return Object.values(metricsMap).reduce((sum, metaMetrics) => sum + metaMetrics.length, 0);
}

function getIdContent(idColumnIndex: number, content: SM.GeneralCell[][]) {
  return getMatrixByOrderedIndexes(content, [idColumnIndex]);
}

function getAttributesContent(columns: Column[], content: SM.GeneralCell[][]) {
  const attributesColumns = columns.filter(x => x.kind === 'attribute') as AttributeColumn[];
  const attributesColumnsIndexes = attributesColumns.map(x => x.contentIndex as number);
  return getMatrixByOrderedIndexes(content, attributesColumnsIndexes);
}

function getMetricsContent(columns: Column[], content: SM.GeneralCell[][]) {
  const metricsColumns = columns.filter(column =>
    !isMarkupBudgetColumn(column)
    && column.kind === 'metric'
    && column.contentIndex !== null
  ) as MetricColumn[];
  const metricsColumnsIndexes = metricsColumns.map(column => column.contentIndex) as number[];
  return getMatrixByOrderedIndexes(content, [0, ...metricsColumnsIndexes]);
}

function mergeMetricsContentToMediaplan(
  mediaplanContent: SM.GeneralCell[][],
  metricsContent: SM.GeneralCell[][],
  amountMetricsColumns: number,
  checkboxMetricIndexes: number[],
): CellContent[][] {
  return mediaplanContent.map((mediaplanRow) => {
    const [rowId] = mediaplanRow;
    const metricsRowContent = metricsContent.find(([metricsContentRowId]) => rowId === metricsContentRowId);
    if (metricsRowContent) {
      const [, ...contentForMerge] = metricsRowContent;
      const formattedContent = contentForMerge.map((cellValue, colIndex) =>
        convertCheckboxValue(cellValue, colIndex + 1, checkboxMetricIndexes)
      );
      return [...mediaplanRow, ...formattedContent];
    }
    const emptyMetricsRow = R.repeat(null, amountMetricsColumns);
    return [...mediaplanRow, ...emptyMetricsRow];
  });
}

function convertCheckboxValue(value: SM.GeneralCell, columnNum: number, checkboxMetricPositions: number[]) {
  const isCheckboxColumn = checkboxMetricPositions.includes(columnNum);
  if (isCheckboxColumn) {
    return value === 1 ? true : null;
  }
  return value;
}
