import { v4 as uuidv4 } from 'uuid';

import { AttributeColumn } from 'shared/types/models/EditMetrics';
import { ServerAttribute } from 'shared/types/models/Server';

export function makeAttributeColumn(attribute: ServerAttribute, contentIndex: number): AttributeColumn {
  const id = uuidv4();
  return {
    id,
    contentIndex,
    kind: 'attribute',
    attributeID: attribute.id,
    filterColumnIndex: null,
    markupID: id,
    title: attribute.title,
  };
}
