import { MetricColumn, MetricType } from 'shared/types/models/EditMetrics';

import { orderMetricsTypes } from '../../../constants';

export function sortMetricColumnsByOrder(columns: MetricColumn[]) {
  return columns.sort((x, y) => getOrderMetricIndex(x.metricType) - getOrderMetricIndex(y.metricType));
}

export function getOrderMetricIndex(type: MetricType): number {
  return orderMetricsTypes.findIndex(metricType => metricType === type);
}
