import { initialCommunicationState } from 'shared/constants';
import { ReduxState } from '../types/ReduxState';

export const initialState: ReduxState = {
  edit: {
    dictionaries: [],
    templates: [],
  },
  communication: {
    loadingTemplates: initialCommunicationState,
    loadingDictionaries: initialCommunicationState,
    loadingBrandDictionary: initialCommunicationState,
    loadingTemplate: initialCommunicationState,
    applyingTemplate: initialCommunicationState,
    deletingTemplate: initialCommunicationState,
    savingTemplate: initialCommunicationState,
  },
};
