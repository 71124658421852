import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import { EditableMetric, Column } from 'shared/types/models/EditMetrics';

import { initMetricsColumns } from './initMetricsColumns';
import { initAttributeColumns } from './initAttributeColumns';
import { addRatioColumns } from '../updates';
import { makeIDColumn } from '../makers';

type Arguments = {
  attributes: SM.ServerAttribute[];
  metrics: EditableMetric[];
  mediaplan: M.Mediaplan;
};

export function initializeColumns(args: Arguments): Column[] {
  const { attributes, metrics, mediaplan } = args;
  const metaAttributes = mediaplan.metadata.attributes;
  const attributesColumns = initAttributeColumns(metaAttributes, attributes, mediaplan);
  const metricsColumns = initMetricsColumns(mediaplan.manualEditedDataMapping.metrics, metrics);
  const columns = [makeIDColumn(), ...attributesColumns, ...metricsColumns];
  return addRatioColumns(columns);
}
