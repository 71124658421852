import * as React from 'react';
import { bind } from 'decko';

import { block } from 'bem-cn';

import './RadioButton.scss';

interface IOwnProps {
  label: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  value?: string | number;
  onChange?(value: IOwnProps['value']): void;
}

const b = block('radio');

class RadioButton extends React.PureComponent<IOwnProps> {

  public render() {
    const { label, name, disabled = false, value, checked } = this.props;
    return (
      <label className={b({ disabled })}>
        <input
          type="radio"
          name={name}
          disabled={disabled}
          value={value}
          onChange={this.onChange}
          checked={checked}
        />
        <span className={b('label')}>{label}</span>
      </label>
    );
  }

  @bind
  private onChange() {
    const { onChange, value } = this.props;
    if (onChange && value) {
      onChange(value);
    }
  }
}

export { RadioButton };
