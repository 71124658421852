import * as R from 'ramda';

import { v4 as uuidv4 } from 'uuid';

import { Omit } from 'react-redux';

import * as M from 'shared/types/models';
import { MetricColumn, EditableMetric, MetricType } from 'shared/types/models/EditMetrics';

import { compareBudgetMetricId } from '../../../constants';
import { isFactMetric } from '../common';
import { isMarkupBudgetColumn } from '../budget';
import { makeMarkupBudgetColumnFromColumn } from './makeMarkupBudgetColumnFromColumn';

type Arguments = {
  metric: EditableMetric;
  metricType: MetricType;
  periodType: M.PeriodType;
  dates: M.DateRange[];
  subId: number | null;
  budgetMarkupSubId: number | null;
};

export function makeMetricColumns(args: Arguments): MetricColumn[] {
  const { dates, metric, metricType, periodType, subId, budgetMarkupSubId } = args;
  const markupID = uuidv4();
  const columnParams: Omit<MetricColumn, 'date' | 'id'> = {
    markupID,
    title: metric.title,
    metricID: metric.id,
    metricType,
    periodType,
    subId,
    filterColumnIndex: null,
    contentIndex: null,
    columnTitle: null,
    kind: 'metric',
  };

  const spreadedColumns = dates.length
    ? R.uniqBy(x => x.start, dates).map<MetricColumn>(date => ({ ...columnParams, date, id: uuidv4() }))
    : [{ ...columnParams, date: null, id: uuidv4() }];

  const newColumns = R.reject(x => isMarkupBudgetColumn(x), spreadedColumns);

  const markupBudgetColumns = metric.id === compareBudgetMetricId && isFactMetric(metricType)
    ? newColumns.map(column => makeMarkupBudgetColumnFromColumn(column, budgetMarkupSubId))
    : [];

  // TODO: return when did markup on the back
  return markupBudgetColumns.length && false
    ? R.zip(newColumns, markupBudgetColumns).flat()
    : newColumns;
}
