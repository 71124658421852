import { makeCommunicationActionCreators } from 'redux-make-communication';
import * as A from '../types/actions';

export const { execute: loadTemplates, completed: loadTemplatesSuccess, failed: loadTemplatesFail } =
  makeCommunicationActionCreators<A.LoadTemplates, A.LoadTemplatesSuccess, A.LoadTemplatesFail>(
    'TEMPLATES_SERVICE:LOAD_TEMPLATES', 'TEMPLATES_SERVICE:LOAD_TEMPLATES_SUCCESS', 'TEMPLATES_SERVICE:LOAD_TEMPLATES_FAIL',
  );

export const { execute: loadDictionaries, completed: loadDictionariesSuccess, failed: loadDictionariesFail } =
  makeCommunicationActionCreators<A.LoadDictionaries, A.LoadDictionariesSuccess, A.LoadDictionariesFail>(
    'TEMPLATES_SERVICE:LOAD_DICTIONARIES', 'TEMPLATES_SERVICE:LOAD_DICTIONARIES_SUCCESS', 'TEMPLATES_SERVICE:LOAD_DICTIONARIES_FAIL',
  );

export const { execute: loadBrandDictionary, completed: loadBrandDictionarySuccess, failed: loadBrandDictionaryFail } =
  makeCommunicationActionCreators<A.LoadBrandDictionary, A.LoadBrandDictionarySuccess, A.LoadBrandDictionaryFail>(
    'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY', 'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY_SUCCESS', 'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY_FAIL',
  );

export const { execute: loadTemplate, completed: loadTemplateSuccess, failed: loadTemplateFail } =
  makeCommunicationActionCreators<A.LoadTemplate, A.LoadTemplateSuccess, A.LoadTemplateFail>(
    'TEMPLATES_SERVICE:LOAD_TEMPLATE', 'TEMPLATES_SERVICE:LOAD_TEMPLATE_SUCCESS', 'TEMPLATES_SERVICE:LOAD_TEMPLATE_FAIL',
  );

export const { execute: applyTemplate, completed: applyTemplateSuccess, failed: applyTemplateFail } = 
  makeCommunicationActionCreators<A.ApplyTemplate, A.ApplyTemplateSuccess, A.ApplyTemplateFail>(
    'TEMPLATES_SERVICE:APPLY_TEMPLATE', 'TEMPLATES_SERVICE:APPLY_TEMPLATE_SUCCESS', 'TEMPLATES_SERVICE:APPLY_TEMPLATE_FAIL',
  );

export const { execute: deleteTemplate, completed: deleteTemplateSuccess, failed: deleteTemplateFail } =
  makeCommunicationActionCreators<A.DeleteTemplate, A.DeleteTemplateSuccess, A.DeleteTemplateFail>(
    'TEMPLATES_SERVICE:DELETE_TEMPLATE', 'TEMPLATES_SERVICE:DELETE_TEMPLATE_SUCCESS', 'TEMPLATES_SERVICE:DELETE_TEMPLATE_FAIL',
  );

export const { execute: saveTemplate, completed: saveTemplateSuccess, failed: saveTemplateFail } =
  makeCommunicationActionCreators<A.SaveTemplate, A.SaveTemplateSuccess, A.SaveTemplateFail>(
    'TEMPLATES_SERVICE:SAVE_TEMPLATE', 'TEMPLATES_SERVICE:SAVE_TEMPLATE_SUCCESS', 'TEMPLATES_SERVICE:SAVE_TEMPLATE_FAIL',
  );
