import isNumber from 'is-number';

import { CellContent } from 'shared/types/models/EditMetrics';

export function calcRatioCellValue(plan: CellContent, fact: CellContent): number | null {
  if (plan && fact && isNumber(fact) && isNumber(plan)) {
    const planValue = +plan;
    const factValue = +fact;
    if ((planValue === 0 && factValue === 0) || (!planValue || !factValue)) {
      return null;
    }
    return factValue / planValue;
  }
  return null;
}
