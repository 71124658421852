import * as NS from '../../types';
import { initialState } from '../intialState';

// tslint:disable:max-line-length

export function data(state: NS.ReduxState['data'] = initialState.data, action: NS.ServiceAction): NS.ReduxState['data'] {
  switch (action.type) {

    case 'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES_COMPLETE': {
      return { ...state, attributes: action.payload.attributes };
    }

    case 'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN_COMPLETE': {
      return { ...state, mediaplan: action.payload.mediaplan };
    }

    case 'COMMON_DATA_SERVICE:LOAD_CABINETS_COMPLETE': {
      return { ...state, cabinets: action.payload.cabinets };
    }

    case 'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES_COMPLETE': {
      return { ...state, ampAttributes: action.payload.ampAttributes };
    }

    case 'COMMON_DATA_SERVICE:LOAD_AMP_METRICS_COMPLETE': {
      return { ...state, ampMetrics: action.payload.metrics }
    }

    case 'COMMON_DATA_SERVICE:LOAD_METRICS_COMPLETE': {
      return { ...state, metrics: action.payload.metrics };
    }

    case 'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES_COMPLETE': {
      return { ...state, csDictionariesMap: action.payload.csDictionariesMap };
    }

    case 'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO_COMPLETE': {
      return { ...state, csCampaignInfo: action.payload.csCampaignInfo };
    }

    case 'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO_COMPLETE': {
      return { ...state, csAmpCampaignInfo: action.payload.csAmpCampaignInfo };
    }

    default: return state;
  }
}
