import { v4 as uuidv4 } from 'uuid';

import * as M from 'shared/types/models';

export function makeCheckboxColumn(contentIndex: number | null): M.CheckboxColumn {
  const id = uuidv4();
  return {
    id,
    markupID: id,
    title: '&#10004;',
    kind: 'checkbox',
    contentIndex,
  };
}
