import * as NS from '../types';
import { initialCommunicationField } from 'shared/helpers/redux';

export const initialState: NS.ReduxState = {
  communication: {
    loadingMediaplan: initialCommunicationField,
    loadingAttributes: initialCommunicationField,
    loadingMetrics: initialCommunicationField,
    loadingTemplate: initialCommunicationField,
    loadingCabinets: initialCommunicationField,
    loadingCalendarPeriods: initialCommunicationField,
    savingMediaplan: initialCommunicationField,
    addingMetric: initialCommunicationField,
    initializingTable: initialCommunicationField,
    editingMetric: initialCommunicationField,
  },
  data: {
    mediaplan: null,
    attributes: [],
    metrics: [],
    cabinets: [],
  },
  edit: {
    parameters: {
      budgetMayExceedPlan: 'cannotExceed',
    },
    isShowSettingsMetricModal: false,
    columns: [],
    cells: [],
    updatingColumnIDs: [],
    settingsMetric: null,
    weeks: null,
  }
};
