import * as M from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { makeCommunicationSelector } from 'shared/helpers/redux';
import makeSelectFeatureState from 'shared/helpers/redux/makeSelectFeatureState';

export const selectFeatureState = makeSelectFeatureState('Templates');
export const selectCommunication = makeCommunicationSelector(selectFeatureState);

export function selectApplyTemplateCommunication(state: IAppReduxState) {
  return selectFeatureState(state).communication.applyingTemplate;
}

export function selectTemplateDictionaries(state: IAppReduxState): M.TemplateDictionary[] {
  return selectFeatureState(state).edit.dictionaries;
}

export function selectTemplates(state: IAppReduxState): M.Template[] {
  return selectFeatureState(state).edit.templates;
}
