import * as R from 'ramda';

import * as M from 'shared/types/models';

export function getRangesIntersection(x: M.Range, y: M.Range): M.Range | null {
  if (y.start > x.end || x.start > y.end) {
    return null;
  }

  return {
    start: R.max(x.start, y.start),
    end: R.min(x.end, y.end),
  };
}
