import { put, select } from 'redux-saga/effects';
import { selectors as commonDataSelectors } from 'services/CommonData';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import { actionCreators, selectors } from '../../../redux';

export function* checkStartInitializeTable() {
  const featureState: NS.ReduxState = yield select(selectors.selectFeatureState);
  const { data: { attributes, metrics, mediaplan, cabinets } } = featureState;
  const hasCSDictionaries = yield select(commonDataSelectors.selectHasCSDictionaries);
  const needCSDictionaryLoading = yield select(selectors.selectNeedCSDictionaryLoading);

  if (
    attributes.length &&
    metrics.length &&
    mediaplan &&
    cabinets.length &&
    (!needCSDictionaryLoading || hasCSDictionaries)
  ) {
    yield put(actionCreators.initTable({ attributes, metrics, mediaplan }));
    yield put(actionCreators.setParameterValue({
      type: 'budgetMayExceedPlan',
      value: helpers.getBudgetExceedParameter(mediaplan),
    }));
  }
}
