import * as R from 'ramda';

import { Column } from 'shared/types/models/EditMetrics';

import * as NS from '../../types';

import { initialState } from '../initialState';

export function editReducer(
  state: NS.ReduxState['edit'] = initialState.edit,
  action: NS.FeatureAction,
): NS.ReduxState['edit'] {
  switch (action.type) {

    case 'EDIT_METRICS:SET_CELLS': {
      return { ...state, cells: action.payload.cells };
    }

    case 'EDIT_METRICS:SET_COLUMNS': {
      return { ...state, columns: action.payload.columns };
    }

    case 'EDIT_METRICS:SET_IS_OPEN_METRIC_SETTINGS_MODAL': {
      return {...state,  isShowSettingsMetricModal: action.payload };
    }

    case 'EDIT_METRICS:SET_METRIC_SETTINGS': {
      return { ...state, settingsMetric: action.payload };
    }

    case 'EDIT_METRICS:ADD_METRIC_COMPLETE': {
      const { cells, columns } = action.payload;
      return { ...state, columns, cells };
    }

    case 'EDIT_METRICS:ADD_LOADING_FACT_COLUMNS_IDS': {
      const { ids } = action.payload;
      return {...state, updatingColumnIDs: R.uniq([...state.updatingColumnIDs, ...ids]) };
    }

    case 'EDIT_METRICS:REMOVE_LOADING_FACT_COLUMNS_IDS': {
      const { ids } = action.payload;
      return {...state, updatingColumnIDs: R.reject(x => ids.includes(x), state.updatingColumnIDs) };
    }

    case 'EDIT_METRICS:LOAD_TEMPLATE_COMPLETE' :
    case 'EDIT_METRICS:EDIT_METRIC_COMPLETE' :
    case 'EDIT_METRICS:INITIALIZE_TABLE_COMPLETE': {
      const { columns, cells } = action.payload;
      return { ...state, columns, cells };
    }

    case 'EDIT_METRICS:LOAD_CALENDAR_PERIODS_COMPLETE': {
      return { ...state, weeks: action.payload.weeks };
    }

    case 'EDIT_METRICS:SET_PARAMETER': {
      const { type, value } = action.payload;
      return {
        ...state,
        parameters: {
          ...state.parameters,
          [type]: value,
        }
      };
    }

    case 'EDIT_METRICS:SET_COLUMNS_FILTERS': {
      const { columnsIndexes } = action.payload;
      const updatedColumns = state.columns.map<Column>((column, columnIndex) => {
        if (columnsIndexes.includes(columnIndex)) {
          return { ...column, filterColumnIndex: columnIndex };
        }
        return column.filterColumnIndex !== null ? { ...column, filterColumnIndex: null } : column;
      });

      return { ...state, columns: updatedColumns };
    }

    default: return state;
  }
}


