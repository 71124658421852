import * as Sentry from '@sentry/browser';

import createSentryMiddleware from 'redux-sentry-middleware';
import { autobind } from 'core-decorators';

import { isServerError, isApiError } from 'services/api/error';
import { getSentryConfig } from 'sentryConfig';

class ErrorTracking {

  public static instance: ErrorTracking | null = null;

  public static getInstance() {
    if (!ErrorTracking.instance) {
      const instance = new ErrorTracking();
      ErrorTracking.instance = instance;
    }
    return ErrorTracking.instance;
  }

  public tracker: typeof Sentry;

  constructor() {
    this.tracker = Sentry;
    this.tracker.init(getSentryConfig());
  }

  @autobind
  public captureException(error: Error) {
    if (isApiError(error) && !isServerError(error)) {
      return;
    }
    this.tracker.captureException(error);
  }

  @autobind
  public makeReduxMiddleware() {
    return createSentryMiddleware(this.tracker);
  }

}

export { ErrorTracking };
