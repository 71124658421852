import * as R from 'ramda';

import { NestedHeader, HeadersMakerArguments } from '../models';

import { getStringOffsetByTitle } from './calcStringOffset';
import { getLongestTitleFromHeader } from './getLongestTitleFromHeader';

import { isColSpanHeader } from './isColSpanHeader';

export function makeRangeTitleHeaders(args: HeadersMakerArguments) {
  const { columnRangeHeaders, autoWrap } = args;
  return columnRangeHeaders.reduce<NestedHeader[]>((acc, header) => {
    const colIndex = header.columnRange.start;
    const diff = Math.abs(header.columnRange.end - header.columnRange.start);
    const offset = acc.length
      ? colIndex - acc.reduce((sum, cur) => isColSpanHeader(cur) ? sum + cur.colspan : sum + 1, 0)
      : colIndex;
    const offsetArr = R.range(0, offset).map(_ => '');
    const title = autoWrap ? getLongestTitleFromHeader(header) : header.title;
    const stringTitleOffset = getStringOffsetByTitle(title);
    return acc
      .concat(offset ? offsetArr : [])
      .concat([{ 
        label: `${title}${stringTitleOffset}`,
        colspan: diff + 1,
      }]);
  }, []);
}
