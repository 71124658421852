import * as React from 'react';
import block from 'bem-cn';

import './style.scss';

const b = block('page-not-found');

class PageNotFound extends React.PureComponent {
  public render() {

    return (
      <div className={b()}>
        Данной страницы не существует
      </div>
    );
  }
}

export default PageNotFound;
