import * as R from 'ramda';

import { AutocorrectDictionary } from 'shared/types/models';
import { ServerAutocorrectDictionary } from 'shared/types/models/Server';

export function convertServerAutocorrectDictionary(dict: ServerAutocorrectDictionary): AutocorrectDictionary[] {
  return Object.entries(dict.attributeMappings).flatMap(([attributeId, items]) => 
    items.map<AutocorrectDictionary>(x => ({
      attributeId: +attributeId,
      correctedDictionaryID: x.itemId,
      srcValue: x.sourceValue,
    }))
  );
}

export function convertAutocorrectDictionaries(dictionaries: AutocorrectDictionary[]): ServerAutocorrectDictionary['attributeMappings'] {
  return R.pipe(
    R.groupBy<AutocorrectDictionary>(x => String(x.attributeId)),
    R.toPairs,
    R.map<[string, AutocorrectDictionary[]], [number, { sourceValue: string; itemId: number }[]]>(([attributeId, items]) => {
      return [Number(attributeId), items.map(x => ({ sourceValue: x.srcValue, itemId: x.correctedDictionaryID }))];
    }),
    R.fromPairs,
  )(dictionaries) as ServerAutocorrectDictionary['attributeMappings'];
}
