import * as R from 'ramda';

import { getMatrixByOrderedIndexes } from 'shared/helpers';
import * as M from 'shared/types/models';
import { Column, TableCell, MetricColumn, FactRow, CellContent } from 'shared/types/models/EditMetrics';
import { MetaMetric, ServerPeriodType } from 'shared/types/models/Server';
import { applyChangesToCells } from '../baseOperations';
import { makeChangesFromFactContent } from './makeChangesFromFactContent';

type ContentForMerge = { columnIndex: number; rows: FactRow[] };

type Arguments = {
  planColumns: Column[];
  columns: Column[];
  cells: TableCell[][];
  mediaplan: M.Mediaplan;
};

export function mergePlanContentToCells(args: Arguments) {
  const { planColumns, cells, columns, mediaplan } = args;
  const contentForMerge = preparePlanContentForMerge(planColumns, columns, mediaplan);
  const changes = contentForMerge.flatMap(({ columnIndex, rows }) => {
    return makeChangesFromFactContent({ columnIndexes: [columnIndex], rows, cells });
  });
  const updatedCells = applyChangesToCells(changes, cells);
  return { updatedCells, changes };
}

function preparePlanContentForMerge(
  planColumns: Column[],
  columns: Column[],
  mediaplan: M.Mediaplan,
): ContentForMerge[] {
  const planColumnsEntries = Object.entries(columns)
    .filter(([, column]) => planColumns.find(x => x.id === column.id)) as [string, MetricColumn][];

  const orderedPeriodsTypes: ServerPeriodType[] = ['WEEK', 'MONTH', 'CUSTOM', 'NONE'];

  return planColumnsEntries.map<ContentForMerge | null>(([index, column]) => {
    const metaMetrics = mediaplan.metadata.metrics[column.metricID];

    if (!metaMetrics) {
      return null;
    }

    const grouppedMetrics = R.groupWith(
      (a, b) => a.periodType === b.periodType && a.metricParamId === b.metricParamId,
      metaMetrics,
    );
    const filteredMetricsByPeriodAndMetricParamId = orderedPeriodsTypes.reduce<MetaMetric[]>((acc, period) => {
      if (!acc.length) {
        const meta = grouppedMetrics.find(group =>
          group.find(x =>
            x.periodType === period
            && (x.metricParamId === undefined || x.metricParamId === null)
          )
        );
        return meta ? meta : acc;
      }
      return acc;
    }, []);

    const columnIndexes = filteredMetricsByPeriodAndMetricParamId.map(x => x.columnNum);
    const planMatrixContent = getMatrixByOrderedIndexes(mediaplan.content, [mediaplan.metadata.rowIdColumnNum, ...columnIndexes]);
    const rows = planMatrixContent.map<FactRow>(([rowId, ...planContent]) => {
      const summedContent = planContent.reduce(sumCellValues, null);
      return { rowId: rowId as number, content: [summedContent] };
    });
    return { columnIndex: +index, rows };
  }).filter(x => x !== null) as ContentForMerge[];
}

function sumCellValues(prev: CellContent, cur: CellContent) {
  if (cur !== null && typeof cur !== 'object') {
    return prev === null ? cur : +prev + +cur;
  }
  return prev !== null ? prev : null;
}
