import * as NS from '../../types';
import { initialState } from '../intialState';

// tslint:disable:max-line-length

export function edit(state: NS.ReduxState['edit'] = initialState.edit, action: NS.Action): NS.ReduxState['edit'] {
  switch (action.type) {

    case 'AMP_CAMPAIGNS_SERVICE:SET_DEAL_AMP_ID': {
      return { ...state, dealAmpId: action.payload.dealAmpID };
    }

    case 'AMP_CAMPAIGNS_SERVICE:SET_VISIBILITY_AMP_CAMPAIGNS_MODAL': {
      return { ...state, isVisibleCampaignsModal: action.payload };
    }

    case 'AMP_CAMPAIGNS_SERVICE:OPEN_AMP_CAMPAIGNS_MODAL': {
      return { ...state, isVisibleCampaignsModal: true };
    }

    default: return state;
  }
}
