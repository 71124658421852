import { call, select } from 'redux-saga/effects';

import { Column, EditableMetric } from 'shared/types/models/EditMetrics';
import { IDependencies } from 'shared/types/app';

import * as helpers from '../../../../helpers';

import { selectors } from '../../..';

import { mergeFactContentToCells } from './mergeFactContentToCells';

type Arguments = {
  columnsForMerge: Column[];
  metrics: EditableMetric[];
  deps: IDependencies;
};

export function* mergeFactToCells(args: Arguments) {
  const { deps, columnsForMerge, metrics } = args;

  const { updatedCells: mergedFactCells, changes: factChanges } = yield call(mergeFactContentToCells, {
    api: deps.api,
    columnsForGetData: columnsForMerge
  });

  const columns: Column[] = yield select(selectors.selectColumns);

  const ratioColumns = columns.filter(x =>
    x.kind === 'ratio'
    && columnsForMerge.some(columnForMerge =>
      (columnForMerge.kind === 'ratio' || columnForMerge.kind === 'metric')
      && columnForMerge.metricType === x.metricType
      && columnForMerge.metricID === x.metricID
    )
  );

  const { updatedCells: mergedRatioAndFactCells, changes: ratioChanges } = helpers.setValuesToRatioCells({
    columns,
    cells: mergedFactCells,
    ratioColumns,
  });

  const updatedCells = helpers.updateCellsStyles({
    columns,
    cells: mergedRatioAndFactCells,
    columnsIndexes: columnsForMerge.map(column => helpers.getIndexColumn(column, columns)),
    metrics,
  });

  return {
    cells: updatedCells,
    changes: [
      ...factChanges,
      ...ratioChanges,
    ]
  };
}
