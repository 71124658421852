export async function loadJsonFromFile(file: File) {
  const reader = new FileReader();
  reader.readAsBinaryString(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      try {
        resolve(JSON.parse(reader.result as string));
      } catch {
        reject(new Error('Ошибка чтения шаблона разметки'));
      }
    };
    reader.onerror = () => reject(new Error('Ошибка чтения шаблона разметки'));
  });
}
