import { isTypedAttribute } from 'shared/helpers';
import * as constants from 'shared/constants';

import {
  StatusFactContent,
  TimelineContent,
  TimelineCustomPeriodContent,
  TimelineFullPeriodContent,
  TypedContent,
  UnitTypeContent,
  GeneralCell,
} from '../Server';

import * as M from '.';

export function isCellWithRounding(arg: any): arg is M.Cell & M.RoundingCellProps {
  return arg !== undefined && arg.kind !== undefined && arg.decimalSigns !== undefined;
}

export function isTimelineFullPeriodContent(arg: any): arg is TimelineFullPeriodContent {
  return Boolean(arg !== null && typeof arg === 'object' && arg.fullPeriod);
}

export function isTimelineCustomPeriodContent(arg: any): arg is TimelineCustomPeriodContent {
  return Boolean(arg !== null && typeof arg === 'object' && arg.periodStart && arg.periodEnd);
}

export function isTimelineContent(arg: any): arg is TimelineContent {
  return isTimelineFullPeriodContent(arg) || isTimelineCustomPeriodContent(arg);
}

export function isTypedContent(content: GeneralCell): content is TypedContent {
  return (content !== null
    && typeof content === 'object'
    && 'type' in content
    && typeof content.type === 'string'
    && content.type !== 'timeline');
}

export function isTypedColumn(column: any, content: GeneralCell): content is TypedContent | string {
  return column?.kind === 'attribute' && isTypedAttribute(column.attributeID);
}

export function isAbstractCell(cell: any): cell is M.AbstractCell {
  return cell !== null && typeof cell === 'object' && 'content' in cell;
}

export function isTypedCell(cell: M.AbstractCell): cell is M.TypedCell {
  return 'fullContent' in cell;
}

export function isTimelineCell(cell: M.PageCell): cell is M.TimelineCell {
  return 'kind' in cell && cell.kind === 'timeline';
}

export function isUnitTypeContent(content: GeneralCell): content is UnitTypeContent {
  return isTypedContent(content) && content.type === 'unit-type';
}

export function isUnitTypeColumn(column: any, content: GeneralCell): content is UnitTypeContent | string {
  return isTypedColumn(column, content) && column.attributeID === constants.unitTypeAttributeID;
}

export function isTypeOfActivityColumn(column: any, content: GeneralCell): content is UnitTypeContent | string {
  return isTypedColumn(column, content) && column.attributeID === constants.typeOfActivityAttributeID;
}

export function isCheckboxColumn(column: M.Column): column is M.CheckboxColumn {
  return column.kind === 'checkbox';
}

export function isCSIDColumn(column: M.Column): column is M.CSIDColumn {
  return column.kind === 'csid';
}

export function isStatusFactContent(arg: any): arg is StatusFactContent {
  return arg !== null
    && typeof arg === 'object'
    && arg.closed !== undefined
    && arg.factExported !== undefined
    && arg.copied !== undefined
    && arg.exportMessage !== undefined;
}

export function isCabinetsTemplate(template: M.TemplateData): template is M.TemplateCabinets {
  return 'cabinetMapping' in template;
}
