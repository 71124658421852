import Handsontable from 'handsontable';

export function scrollToColumn(hotInstance: Handsontable, colIndex: number) {
  const plugin = hotInstance.getPlugin('autoColumnSize');
  const firstVisibleColumn = plugin.getFirstVisibleColumn();
  const lastVisibleColumn = plugin.getLastVisibleColumn();
  const isColumnVisibleInViewport = firstVisibleColumn <= colIndex && colIndex <= lastVisibleColumn;
  if (!isColumnVisibleInViewport) {
    hotInstance.scrollViewportTo(0, colIndex);
  }
}
