import * as React from 'react';
import block from 'bem-cn';
import { RouteProps } from 'react-router';
import { Helmet } from 'react-helmet';

import * as features from 'features';
import { withAsyncFeatures } from 'core';

import { ErrorBoundary } from 'services/ErrorTracking';

import './style.scss';
import { getHelmetTale } from 'shared/helpers';

interface IFeatureProps {
  MatchingCabinets: features.MatchingCabinets.Entry;
}

type IProps = RouteProps & IFeatureProps;

const b = block('markup-mediaplan-page');

class MatchingCabinetsPageComponent extends React.PureComponent<IProps> {
  public render() {
    const { MatchingCabinets } = this.props;

    return (
      <div className={b()}>
        <Helmet defer={false}>
          <title>Сопоставление с кабинетами - {getHelmetTale()} Client Space</title>
        </Helmet>
        <ErrorBoundary>
          <MatchingCabinets.containers.MatchingCabinets />
        </ErrorBoundary>
      </div>
    );
  }
}

export const MatchingCabinetsPage = (
  withAsyncFeatures({ MatchingCabinets: features.MatchingCabinets.loadEntry })(
    MatchingCabinetsPageComponent
  ));
