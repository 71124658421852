import { call, put } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { tryCatch } from 'services/ErrorTracking';
import * as actionCreators from '../../actionCreators';
import * as A from '../../../types/actions';

export function* deleteTemplate({ api }: IDependencies, { payload: { templateId } }: A.DeleteTemplate) {
  yield tryCatch({
    *successed() {
      yield call(api.template.deleteTemplate, templateId);
      yield put(actionCreators.deleteTemplateSuccess({ templateId }));
    },
    *failed(error, message) {
      yield put(actionCreators.deleteTemplateFail(message));
    }
  });
}
