import { DiffColumnType, FactMetricType, ColumnMetricType, MetricType } from 'shared/types/models/EditMetrics';

export const metricsWithoutRounding = [66];
export const metricsWithPercentFormat = [7];
export const checkboxMetricId = 70;
export const markupBudgetMetricId = 72;
export const compareBudgetMetricId = 20;

export const notRelevantFoundColumnsForMerge = 'not found relevant columns for merge';

export const autoDiffColumnsTypes: DiffColumnType[] = ['plan/publisher', 'plan/adserving'];
export const factualMetricsTypes: FactMetricType[] = ['publisher', 'adserving'];
export const orderMetricsTypes: ColumnMetricType[] = [
  'plan',
  'publisher',
  'plan/publisher',
  'adserving',
  'plan/adserving',
];

export const orderMetrics: MetricType[] = [
  'plan',
  'publisher',
  'adserving',
];

export const ratioHeaderColor = '#38a99d';
export const standardHeaderColor = '#5885AF';
export const attributeHeaderColor = '#8592ad';

export const stylesChangedCell: Partial<CSSStyleDeclaration> = {
  backgroundColor: '#cce5ff',
};

export const stylesRatioCell: Partial<CSSStyleDeclaration> = {
  backgroundColor: '#d4edda',
};

export const stylesInvalidRatioCell: Partial<CSSStyleDeclaration> = {
  backgroundColor: '#fff3cd',
};

export const stylesReadonlyCell: Partial<CSSStyleDeclaration> = {
  backgroundColor: '#fbfcfc',
};

export const stylesCheckboxCell: Partial<CSSStyleDeclaration> = {
  textAlign: 'center',
  verticalAlign: 'middle',
};

export const stylesNotEqualMarkupBudgetCell: Partial<CSSStyleDeclaration> = {
  backgroundColor: '#fed8b1',
};
