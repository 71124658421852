import { call, put } from 'redux-saga/effects';

import { tryCatch } from 'services/ErrorTracking';
import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import * as actionCreators from '../../actionCreators';

export function* loadDictionaries({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const dictionaries: ReturnPromisedType<typeof api.template.getDictionaries> =
        yield call(api.template.getDictionaries);
      yield put(actionCreators.loadDictionariesSuccess({ dictionaries }));
    },
    *failed(error, message) {
      yield put(actionCreators.loadDictionariesFail(message));
    }
  });
}
