import { call, put } from 'redux-saga/effects';

import { tryCatch } from 'services/ErrorTracking';
import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { actionCreators } from '../../../redux';
import * as A from '../../../types/actions';

export function* loadBrandDictionary({ api }: IDependencies, { payload: { advertiserId } }: A.LoadBrandDictionary) {
  yield tryCatch({
    *successed() {
      const brands: ReturnPromisedType<typeof api.template.loadBrandDictionary> =
        yield call(api.template.loadBrandDictionary, advertiserId);
      yield put(actionCreators.loadBrandDictionarySuccess({ advertiserId, brands }));
    },
    *failed(error, message) {
      yield put(actionCreators.loadDictionariesFail(message));
    }
  });
}
