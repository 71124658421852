import * as React from 'react';

import { Modal } from 'shared/view/elements';

interface IOwnProps {
  isShow: boolean;
  onClose(): void;
  onAccept(): void;
  title: string;
  children?: JSX.Element;
  cancelButtonText?: string;
  accessButtonText?: string;
  disableAccessButton?: boolean;
}

class AccessActionModal extends React.PureComponent<IOwnProps> {

  public render() {
    const { 
      isShow, onClose, onAccept, title, cancelButtonText, accessButtonText, disableAccessButton, children,
    } = this.props;
    return (
      <Modal
        title={title}
        cancelButtonText={cancelButtonText ? cancelButtonText : 'Нет'}
        accessButtonText={accessButtonText ? accessButtonText : 'Да'}
        isShow={isShow}
        onClose={onClose}
        onAccept={onAccept}
        disableAccessButton={disableAccessButton}
      >
        {children}
      </Modal>
    );
  }
}

export default AccessActionModal;
