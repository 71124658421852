import { AxiosResponse } from 'axios';
import * as R from 'ramda';

import * as M from 'shared/types/models';
import { MarkupForColumnRange, MetricMarkupForColumnRange } from 'shared/types/models/MarkupMediaplan';
import * as SM from 'shared/types/models/Server';
import { convertClientPeriodType } from '../metric/converters';
import { convertClientValueType } from '../../helpers/converters';

export function convertRowIdColumnNum(sortedMarkupForColumnRanges: MarkupForColumnRange[]) {
  function loop(markup: MarkupForColumnRange[], column: number) {
    if (markup.length === 0) {
      console.error('could not find column with ID');
      return -1;
    }
    const [x, ...xs] = markup;

    if (x.kind === 'id') {
      return column;
    }

    const columnsInX = x.columnRange.end - x.columnRange.start + 1;

    return loop(xs, column + columnsInX);
  }

  return loop(sortedMarkupForColumnRanges, 0);
}

export function convertMetaMetrics(
  sortedMarkupForColumnRange: MarkupForColumnRange[],
): SM.MetricIDToServerMetricColumnsMetadataMap {

  function loop(
    markup: MarkupForColumnRange[],
    acc: SM.MetricIDToServerMetricColumnsMetadataMap,
    translatedColumn: number,
  ): SM.MetricIDToServerMetricColumnsMetadataMap {

    if (markup.length === 0) {
      return acc;
    }

    const [x, ...xs] = markup;

    const columnsInX = x.columnRange.end - x.columnRange.start + 1;

    if (x.kind === 'metric') {
      const { metricData: { metricID } } = x;

      return loop(
        xs,
        {
          ...acc,
          [metricID]: [
            ...R.defaultTo([], acc[metricID]),
            ...convertToServerMetricColumnsMetadata(x, translatedColumn),
          ],
        },
        translatedColumn + columnsInX,
      );
    }

    return loop(xs, acc, translatedColumn + columnsInX);
  }

  return loop(sortedMarkupForColumnRange, {}, 0);
}

export function convertToServerMetricColumnsMetadata(
  markup: MetricMarkupForColumnRange, translatedColumn: number,
): SM.ServerMetricColumnMetadata[] {
  const { columnRange: { end, start } } = markup;

  return R.range(start, end + 1).map(makeToServerMetricColumnMetadataConverter(markup, translatedColumn));
}

export function makeToServerMetricColumnMetadataConverter(
  markup: MetricMarkupForColumnRange,
  translatedStartColumn: number
  ) {
  const { columnRange: { start }, metricData: { columnToPeriodMap, periodType } } = markup;

  return (originalColumn: number): SM.ServerMetricColumnMetadata => {
    const period = columnToPeriodMap[originalColumn];

    return {
      columnNum: translatedStartColumn + (originalColumn - start),
      originalColumnNum: originalColumn,
      periodEnd: period && period.end,
      periodStart: period && period.start,
      periodType: periodType === null
        ? 'NONE'
        : convertClientPeriodType(periodType),
      valueType: 'DOUBLE',
      metricParamId: markup.metricData.metricParamId !== null ? markup.metricData.metricParamId : undefined,
    };
  };
}

export function convertMetaAttributes(
  markedUpColumns: MarkupForColumnRange[],
  attributes: M.Attribute[],
): SM.ServerAttributeSaveData[] {
  return markedUpColumns.reduce<SM.ServerAttributeSaveData[]>((acc, column, index, columns) => {
    if (column.kind === 'attribute') {
      const attribute = attributes.find(attr => attr.id === column.attributeID);
      const columnNum = getColumnNumForMeta(columns, index);

      const metaAttribute = {
        originalColumnNum: column.columnRange.start,
        id: column.attributeID,
        valueType: attribute ? convertClientValueType(attribute.valueType) : 'STRING',
        columnNum,
      };
      return [...acc, metaAttribute];
    }
    return acc;
  }, []);
}

export function getColumnNumForMeta(markedUpColumns: MarkupForColumnRange[], markedColumnIndex: number) {
  return markedUpColumns
    .filter((_, idx) => idx < markedColumnIndex)
    .reduce((sum, x) => sum + x.columnRange.end - x.columnRange.start + 1, 0);
}

export function convertSheetToSheetName(sheet: SM.ServerSheet): string {
  return sheet.sheetName;
}

export function convertServerMediaplan(mediaplan: SM.ServerMediaplan): M.Mediaplan {
  return {
    ...mediaplan,
    dealAmpId: mediaplan.dealAmpId || mediaplan.previousVersion?.dealAmpId || null,
    ampContent: mediaplan.ampContent || mediaplan.previousVersion?.ampContent,
    ampMetadata: mediaplan.ampMetadata || mediaplan.previousVersion?.ampMetadata,
    content: mediaplan.content || mediaplan.previousVersion?.content,
    metadata: mediaplan.metadata || mediaplan.previousVersion?.metadata,
    dateStart: mediaplan.dateStart || mediaplan.previousVersion?.dateStart,
    dateEnd: mediaplan.dateEnd || mediaplan.previousVersion?.dateEnd,
    changedRowsIds: mediaplan.changedRows || [],
    cabinetContent: mediaplan.cabinetContent || [],
    cabinetMapping: mediaplan.cabinetMapping || [],
    manualEditedDataContent: mediaplan.manualEditedDataContent ? mediaplan.manualEditedDataContent : [],
    manualEditedDataMapping: mediaplan.manualEditedDataMapping
      ? mediaplan.manualEditedDataMapping
      : { metrics: {} },
  };
}

export function convertFileResponse(blob: Blob, response: AxiosResponse) {
  const contentDisposition = response.headers['content-disposition'];
  const filenameData = contentDisposition ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) : null;
  const filename = filenameData
    ? decodeURIComponent(filenameData[1].replace(/['"]+/g, '').replace(/\+/g, ' '))
    : null;

  return { blob, filename };
}
