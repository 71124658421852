import * as R from 'ramda';

import { autobind } from 'core-decorators';

import BaseApi from '../../BaseApi';

import * as c from './converters';
import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

type SaveMediaplanArguments = {
  mediaplanId: number;
  mediaplanVersionId: number;
  content: any[];
  dateEnd: string;
  dateStart: string;
  markupForColumnRanges: M.MarkupMediaplan.MarkupForColumnRange[];
  attributes: M.Attribute[];
  columnWithMediaplanIds: number | null;
  topBorder: number | null;
  bottomBorder: number | null;
};

class Mediaplan extends BaseApi {

  @autobind
  public async getSheetsNames(mediaplanId: number, mediaplanVersionId: number, fileId: number | string) {
    const response = await this.actions.get<{ [fileId: string]: { sheets: SM.ServerSheet[] } }>({
      url: `/excel/mediaplan/${mediaplanId}/sheet`,
      options: {
        headers: this.authHeaders,
      },
      data: { mediaplanVersionId }
    });

    return this.handleResponse(response, x => x[fileId].sheets.map(c.convertSheetToSheetName));
  }

  @autobind
  public async getSheets(mediaplanId: number, mediaplanVersionId: number, sheetId: number) {
    const response = await this.actions.get<ArrayBuffer>({
      url: `/excel/mediaplan/${mediaplanId}/data/protobuf`,
      options: {
        headers: this.authHeaders,
        responseType: 'arraybuffer',
      },
      data: { sheetId, mediaplanVersionId }
    });

    return this.handleResponse(response);
  }

  @autobind
  public async getCalendar(year: number = 2019): Promise<any> {
    const response = await this.actions.get({
      url: `/calendar/${year}`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getCalendarPeriod(yearRange: M.YearRange): Promise<M.CalendarPeriods> {
    const response = await this.actions.get<M.CalendarPeriods>({
      url: `/calendar/`,
      options: { headers: this.authHeaders },
      data: { yearStart: yearRange.start, yearEnd: yearRange.end },
    });

    return this.handleResponse(response);
  }

  @autobind
  public async getSheetsMediaplan(data: any): Promise<any> {
    const response = await this.actions.get({
      url: `/excel/sheet-db`,
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }

  @autobind
  public async saveMediaplan(args: SaveMediaplanArguments): Promise<void> {
    const {
      content, dateEnd, dateStart, mediaplanId, mediaplanVersionId, markupForColumnRanges,
      attributes, columnWithMediaplanIds, topBorder, bottomBorder,
    } = args;

    const sortedMarkup = R.sortBy((x: M.MarkupMediaplan.MarkupForColumnRange) => x.columnRange.start, markupForColumnRanges);

    const response = await this.actions.post({
      url: `/mediaplan/${mediaplanId}/save`,
      options: { headers: this.authHeaders },
      data: {
        mediaplan: {
          metadata: {
            columnWithMediaplanIds,
            topBorder,
            bottomBorder,
            rowIdColumnNum: c.convertRowIdColumnNum(sortedMarkup),
            metrics: c.convertMetaMetrics(sortedMarkup),
            attributes: c.convertMetaAttributes(sortedMarkup, attributes),
          },
          content,
          dateEnd,
          dateStart,
        },
        mediaplanVersionId,
      },
    });
    this.handleResponse(response);
  }

  @autobind
  public async getMediaplan(args: GetMediaplanArgs): Promise<M.Mediaplan> {
    const { mediaplanID, mediaplanVersionID } = args;
    const response = await this.actions.get<SM.ServerMediaplan>({
      url: `/mediaplan/${mediaplanID}`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID },
    });
    return this.handleResponse(response, data => c.convertServerMediaplan(data));
  }

  @autobind
  public async download(fileId: string, fileType: M.FileType): Promise<{ blob: Blob, filename: string | null }> {
    const response = await this.actions.get<Blob>({
      url: '/file/download',
      domainType: 'portalApi',
      options: { headers: this.authHeaders, responseType: 'blob' },
      data: { fileId, fileType },
    });
    return this.handleResponse(response, data => c.convertFileResponse(data, response));
  }

  @autobind
  public async getCsCampaignInfo(args: GetMediaplanArgs): Promise<M.CsCampaignInfo> {
    const { mediaplanID, mediaplanVersionID } = args;
    const response = await this.actions.get<M.CsCampaignInfo>({
      url: `/mediaplan/${mediaplanID}/campaign-info`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getCsAmpCampaignInfo(args: GetCsAmpCampaignInfoArgs): Promise<M.CsAmpCampaignInfo> {
    const { mediaplanID, mediaplanVersionID, dealAmpId } = args;
    const response = await this.actions.get<M.CsAmpCampaignInfo>({
      url: `/export-amp/${mediaplanID}/campaign-info`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId: mediaplanVersionID, dealAmpId },
    });
    return this.handleResponse(response);
  }
}

type GetMediaplanArgs = {
  mediaplanID: number;
  mediaplanVersionID: number;
};

type GetCsAmpCampaignInfoArgs = GetMediaplanArgs & { dealAmpId: number };

export default Mediaplan;
