import { InterceptorPayload } from './types';

function makeHandleServerError(handleError: () => void) {

  return ({ response: axiosResponse }: InterceptorPayload): void => {
    const { status } = axiosResponse;
    if (status >= 300 && status !== 401 && status !== 400) {
      handleError();
    }
  };
}

export { makeHandleServerError };
