import { put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { Column, TableCell } from 'shared/types/models/EditMetrics';
import { hotTable } from 'shared/helpers';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import { actionCreators, selectors } from '../../../redux';
import { withHotInstance } from '../../dependencies';

export function* removeMetric(deps: IDependencies, { payload }: NS.RemoveMetric) {
  const { columnIndex } = payload;

  const columns: Column[] = yield select(selectors.selectColumns);
  const cells: TableCell[][] = yield select(selectors.selectCells);
  const { diffIndexes, updatedColumns } = helpers.removeColumns(columnIndex, columns);

  const updatedCells = helpers.removeCellsByColumnsIndexes(cells, diffIndexes);

  yield put(actionCreators.setColumns({ columns: updatedColumns }));
  yield put(actionCreators.setCells({ cells: updatedCells }));

  withHotInstance(ht => {
    const isDeletingColumnsHasFilters = diffIndexes.some(index => columns[index].filterColumnIndex !== null);

    if (isDeletingColumnsHasFilters) {
      hotTable.clearFilters(ht, diffIndexes);
    }

    const filterIndexesChanges = helpers.getFilterIndexesChanges(updatedColumns);

    if (filterIndexesChanges.length) {
      hotTable.updateFiltersIndexesByChanges({
        hotInstance: ht,
        changes: filterIndexesChanges,
        afterClearFiltersCallback: () => {
          hotTable.removeColumns(ht, diffIndexes);
        },
      });
    } else {
      hotTable.removeColumns(ht, diffIndexes);
    }

    if (isDeletingColumnsHasFilters || filterIndexesChanges.length) {
      hotTable.applyFilters(ht);
    }
    ht.clearUndo();
  });
}
