import qs from 'querystring';

function convertRefreshTokenRequestKeyCloack(refreshToken: string) {
  const body = {
    grant_type: 'refresh_token',
    client_id: 'clientspace',
    refresh_token: refreshToken,
  };
  return qs.stringify(body);
}


export { convertRefreshTokenRequestKeyCloack };
