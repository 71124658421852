import { autobind } from 'core-decorators';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

import * as converters from './converters';
import BaseApi from '../../BaseApi';

class ExportCabinet extends BaseApi {
  @autobind
  public async validateDependenciesInRows(rowDependencies: SM.ExportCabinetRowDependencies[]):
  Promise<M.ExportCabinet.ValidateResponseCoords[]> {
    const response = await this.actions.post<SM.ExportCabinetValidationResults>({
      url: '/export-cabinet/validate-dependencies',
      options: { headers: this.authHeaders },
      data: { rowDependencies },
    });
    return this.handleResponse(response, converters.convertInvalidRows);
  }

  @autobind
  public async getGeneratedFiles({ mediaplanId, mediaplanVersionId }: MediaplanArgs): Promise<M.File[]> {
    const response = await this.actions.get<SM.File[]>({
      url: `/export-cabinet/${mediaplanId}/list-of-generated-files`,
      options: { headers: this.authHeaders },
      data: { mediaplanVersionId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getTemplate(args: GetMediaplanArgs): Promise<M.ExportCabinet.Mediaplan> {
    const { mediaplanId, mediaplanVersionId, cabinetId, rowIds } = args;
    const response = await this.actions.post<SM.ServerMediaplan>({
      url: `/export-cabinet/${mediaplanId}/get-data-for-pattern?mediaplanVersionId=${mediaplanVersionId}`,
      options: { headers: this.authHeaders },
      data: { cabinetId, rowIds },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async saveTemplate({ mediaplanVersionId, mediaplanId, content, metadata }: SaveTemplateArgs): Promise<M.File> {
    const response = await this.actions.post<M.File>({
      url: `/export-cabinet/${mediaplanId}/process_cabinet_excel?mediaplanVersionId=${mediaplanVersionId}`,
      options: { headers: this.authHeaders },
      data: { content, metadata },
    });
    return this.handleResponse(response);
  }
}

type MediaplanArgs = {
  mediaplanId: number;
  mediaplanVersionId: number;
};

type GetMediaplanArgs = MediaplanArgs & {
  cabinetId: number;
  rowIds: Record<string, number>;
}

type SaveTemplateArgs = MediaplanArgs & M.ExportCabinet.Mediaplan;

export default ExportCabinet;
