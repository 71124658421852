import Handsontable from 'handsontable';

import { Column } from 'shared/types/models/EditMetrics';
import { IAppReduxState } from 'shared/types/app';

import { selectCells } from '../../redux/selectors';

import { makeMetaByColumn } from '../cellsMeta';

type Arguments = {
  hotInstance: Handsontable;
  columnsIndexes: number[];
  columns: Column[];
  getState: () => IAppReduxState;
};

export function setColumnsMeta(args: Arguments) {
  const { columns, columnsIndexes, getState, hotInstance } = args;
  const cells = selectCells(getState());

  cells.forEach((_, row) => {
    columnsIndexes.forEach(colIndex => {
      const column = columns[colIndex];
      const meta = makeMetaByColumn(column, getState);
      hotInstance.setCellMetaObject(row, colIndex, meta);
    });
  });

  hotInstance.render();
  hotInstance.validateColumns(columnsIndexes);
}

