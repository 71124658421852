// @ts-nocheck

import Handsontable from 'handsontable';
import moment from 'moment/moment';
import Flatpickr from 'flatpickr';
import { Options } from 'flatpickr/dist/types/options.d';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

/**
 * Handsontable editor for cells of type "placement-period" with date range value.
 * DateRangePicker has some bugs with mouse events hence customizations of Handsontable events.
 */

type Arguments = {
  mode?: Options['mode'];
  dateFormat?: Options['dateFormat'];
  checkEditModeIsDatepicker?: () => boolean;
}

export function dateEditor({
  mode = 'range',
  dateFormat = 'DD/MM/YY',
  checkEditModeIsDatepicker = () => true,
}: Arguments): typeof Handsontable._editors.Base {
  return class CustomEditor extends Handsontable.editors.TextEditor {

    constructor(props) {
      super(props);
      this.isCellEdited = false;
      this.parentDestroyed = false;
    }

    createElements() {
      super.createElements();
      if (checkEditModeIsDatepicker()) {
          const fakeInput = document.createElement('input');
          fakeInput.classList.add('date-editor-input');
          this.TEXTAREA_PARENT.appendChild(fakeInput);

          this.datePicker = new Flatpickr(fakeInput, {
              mode,
              appendTo: document.getElementById('date-editor-input-wrapper') ?? undefined,
              dateFormat: dateFormat === 'DD.MM.YYYY' ? 'd.m.Y' : 'd/m/Y',
              locale: Russian,
              clickOpens: true,
              maxDate: new Date('12/31/2100'),
              minDate: new Date('01/01/1950'),
              onClose: () => this.finishEditing()
          });
          const eventManager = new Handsontable.EventManager(this);
          eventManager.addEventListener(
            this.datePicker.calendarContainer,
            'mousedown',
            event => event.stopPropagation(),
          );
          eventManager.addEventListener(
            document.documentElement,
            'mousedown',
            event => {
              const classList = Array.from(event.target.classList);
              if (classList.some((x) => ['htAutocompleteArrow', 'handsontableInput'].includes(x))) {
                event.stopPropagation();
              }
            },
          );
      }
    }

    prepare(row, col, prop, td, originalValue, cellProperties) {
      super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    close() {
      super.close();
    }

    finishEditing (isCancelled = false, ctrlDown = false) {
      if (checkEditModeIsDatepicker()) {
          if (isCancelled !== null) {
              if (isCancelled) {
                  const value = this.originalValue;

                  if (value !== void 0) {
                      this.setValue(value);
                  }
              } else {
                  const startDate = this.datePicker.selectedDates[0];
                  const endDate = this.datePicker.selectedDates[1];

                  if (startDate && endDate) {
                      const dateValue = `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`;
                      this.setValue(dateValue);
                    } else if (startDate) {
                      const dateValue = `${moment(startDate).format(dateFormat)}`;
                      this.setValue(dateValue);
                  }
              }
          }
      }

      super.finishEditing(isCancelled, ctrlDown);
    }

    open(e = null) {
      super.open();
      if (checkEditModeIsDatepicker()) {
          this.datePicker.open(e);
      }
    }
  }
}
