import { put, select } from 'redux-saga/effects';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import { IDependencies } from 'shared/types/app';
import * as M from 'shared/types/models';
import {
  Column, TableCell, EditableMetric, MetricColumn, MetricType, CellChange,
} from 'shared/types/models/EditMetrics';

import { tryCatch } from 'services/ErrorTracking';

import { actionCreators, selectors } from '../..';
import { withHotInstance } from '../../dependencies';
import { hotTable } from 'shared/helpers';

export function* editMetric(deps: IDependencies, { payload: { settingsMetric } }: NS.EditMetric) {
  const { metric, type, subId } = settingsMetric;
  yield tryCatch({
    *successed() {
      const columns: Column[] = yield select(selectors.selectColumns);
      const cells: TableCell[][] = yield select(selectors.selectCells);
      const mediaplan: M.Mediaplan = yield select(selectors.selectMediaplan);
      const markupBudgetMetricSubId: number | null = yield select(selectors.selectMarkupBudgetMetricSubId);
      const metrics: EditableMetric[] = yield select(selectors.selectMetrics);

      const relatedColumns = helpers.getColumnsByMetric(columns, metric, type) as MetricColumn[];
      const relatedColumnsIndexes = relatedColumns.map(x => helpers.getIndexColumn(x, columns));

      const columnCellsByDate = makeColumnCellsByDate(relatedColumns, columns, cells);

      const [startColIndex] = relatedColumnsIndexes;
      const {
        diffIndexes: indexesForRemove,
        updatedColumns: removedColumns,
      } = helpers.removeColumns(startColIndex, columns);

      const newColumns = helpers.makeMetricColumns({
        periodType: settingsMetric.periodType || 'none',
        dates: settingsMetric.periodsDates || [],
        metric,
        subId,
        budgetMarkupSubId: markupBudgetMetricSubId,
        metricType: type,
      });

      const {
        diffIndexes: indexesForAdd,
        diffColumns,
        updatedColumns: withNewColumns,
      } = helpers.addNewColumns(newColumns, removedColumns, startColIndex);

      const prevCellsChanges = makeChangesFromColumnCells(withNewColumns, indexesForAdd, columnCellsByDate);

      const cellsAfterRemove = helpers.removeCellsByColumnsIndexes(cells, indexesForRemove);
      const updatedCells = helpers.insertEmptyColumnsCells(cellsAfterRemove, indexesForAdd);

      const newCells = helpers.applyChangesToCells(prevCellsChanges, updatedCells);

      const { updatedCells: mergedPlanContentCells, changes } = helpers.mergePlanContentToCells({
        columns: withNewColumns,
        mediaplan,
        cells: newCells,
        planColumns: diffColumns.filter(column => column.kind === 'metric' && column.metricType === 'plan'),
      });

      const completedCells = helpers.updateCellsStyles({
        columns: withNewColumns,
        cells: mergedPlanContentCells,
        columnsIndexes: indexesForAdd,
        metrics,
      });

      const isRemovingColumnsHasFilters = indexesForRemove.some(index => columns[index].filterColumnIndex !== null);

      withHotInstance(ht => {
        if (isRemovingColumnsHasFilters) {
          hotTable.clearFilters(ht, indexesForRemove);
        }
        hotTable.removeColumns(ht, indexesForRemove);
      });

      yield put(actionCreators.editMetricCompleted({ cells: completedCells, columns: withNewColumns }));

      withHotInstance(ht => {
        hotTable.addColumns(ht, indexesForAdd);
        const filterIndexesChanges = helpers.getFilterIndexesChanges(withNewColumns);

        if (filterIndexesChanges.length) {
          hotTable.updateFiltersIndexesByChanges({ hotInstance: ht, changes: filterIndexesChanges });
        }
        if (isRemovingColumnsHasFilters || filterIndexesChanges.length) {
          hotTable.applyFilters(ht);
        }
        helpers.hotTable.setColumnsMeta({
          hotInstance: ht,
          columnsIndexes: indexesForAdd,
          columns: withNewColumns,
          getState: deps.getState,
        });
        helpers.hotTable.applyChanges({
          hotInstance: ht,
          changes: [...changes, ...prevCellsChanges],
          isSetToSourceData: Boolean(hotTable.getFilters(ht).length),
        });
        const [scrollColumnIndex] = indexesForAdd;
        setTimeout(() => hotTable.scrollToColumn(ht, scrollColumnIndex), 0);
      });

      if (type !== 'plan') {
        const ids = helpers.getFactColumnsIDs(diffColumns);
        yield put(actionCreators.addLoadingFactColumnsIDs({ ids }));
        yield put(actionCreators.loadFactData({ columnsIDs: ids }));
      }
    },
    *failed(_, message) {
      yield put(actionCreators.editMetricFailed(message));
    },
  });
}

type ColumnCellsWithData = {
  date: M.DateRange;
  columnCells: TableCell[];
  metricID: number;
  metricType: MetricType;
};

function makeColumnCellsByDate(
  relatedColumns: MetricColumn[],
  columns: Column[],
  cells: TableCell[][]
): ColumnCellsWithData[] {
  return relatedColumns
    .filter(column => column.date !== null)
    .map(column => {
      const colIndex = helpers.getIndexColumn(column, columns);
      return {
        date: column.date!,
        columnCells: helpers.getCellsByColumnIndex(cells, colIndex),
        metricID: column.metricID,
        metricType: column.metricType,
      };
    });
}

function makeChangesFromColumnCells(
  columns: Column[],
  indexes: number[],
  columnCellsByDate: ColumnCellsWithData[],
): CellChange[] {
  const diffColumns = indexes.map(index => columns[index]);

  const matchedColumnsCells = columnCellsByDate.filter(({ date, metricID, metricType }) =>
    diffColumns.find(column =>
      column.kind === 'metric'
      && column.date
      && column.date.start === date.start
      && column.date.end === date.end
      && column.metricID === metricID
      && column.metricType === metricType
    )
  );

  return matchedColumnsCells.flatMap(({ columnCells, date, metricID, metricType }) => {
    const colIndex = helpers.getIndexColumnByProperties({ date, metricID, metricType }, columns);
    return columnCells
      .map<CellChange>((cell, rowIndex) => ({
        coordinate: { row: rowIndex, column: colIndex },
        cellProperties: { ...cell }
      }))
      .filter(({ cellProperties }) => cellProperties.content !== undefined && !helpers.isEmptyValue(cellProperties.content));
  });
}
