import { BrowserOptions } from '@sentry/browser';

import { getEnvParams } from 'core/getEnvParams';

export function getSentryConfig(): BrowserOptions {
  const envParams = getEnvParams();
  return {
    enabled: envParams.isProduction,
    dsn: envParams.sentryDSN,
    attachStacktrace: true,
    environment: envParams.environment,
    beforeSend: (event) => {
      if (event.breadcrumbs) {
        event.breadcrumbs = event.breadcrumbs.filter(x => x.level !== 'warning');
      }
      return event;
    },
    blacklistUrls: [
      'localhost',
      '127.0.0.1',
    ],
  };
}
