import { C } from 'ts-toolbelt';
import { call, put, all } from 'redux-saga/effects';

import * as M from 'shared/types/models';
import { IDependencies } from 'shared/types/app';
import { tryCatch } from 'services/ErrorTracking';

import { actionCreators } from '../../../redux';
import { LoadCSDictionaries } from '../../../types';

export function* loadCSDictionaries({ api }: IDependencies, { payload: { attributeIds } }: LoadCSDictionaries) {
  yield tryCatch({
    *successed() {
      const loadDictionary = async (attributeId: number) => ({
        id: attributeId,
        dictionary: await api.attribute.getDictionaryByAttributeId(attributeId),
      });

      const dictionariesData: Array<C.PromiseOf<ReturnType<typeof loadDictionary>>> = yield all(
        attributeIds.map(x => call(loadDictionary, x))
      );

      const csDictionariesMap = dictionariesData
        .reduce<Record<string, M.Dictionary>>((acc, x) => ({ ...acc, [x.id]: x.dictionary }), {});

      yield put(actionCreators.loadCSDictionariesCompleted({ csDictionariesMap }))
    },
    *failed(_, message) {
      yield put(actionCreators.loadCSDictionariesFailed(message));
    },
  });
}
