import { Dispatch } from 'redux';

import { actionCreators as alertActionCreators } from 'services/Alert/redux';

import { formatErrorMessages } from 'shared/helpers';

import { isHandledErrorResponse } from '../helpers';
import { HandledApiError } from '../error';

import { InterceptorPayload } from './types';

export function makeHandleCustomServerErrors(getDispatch: () => Dispatch) {
  return ({ response }: InterceptorPayload) => {
    const dispatch = getDispatch();
    if (isHandledErrorResponse(response)) {
      const errorsMessages = HandledApiError.getErrorMessages(response);
      dispatch(alertActionCreators.openAlert({ type: 'error', message: formatErrorMessages(errorsMessages) }));
    }
  };
}
