import { autobind } from 'core-decorators';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

import BaseApi from '../../BaseApi';

import * as converters from './converters';

class Attribute extends BaseApi {

  @autobind
  public async loadEditableAttributes(): Promise<SM.ServerAttribute[]> {
    const response = await this.actions.get<SM.ServerAttribute[]>({
      url: '/attribute/clientspace-web/edit-data',
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getAttributeValues(data: SM.AttributesValuesRequest, performLatestOnly = true): Promise<number[] | null> {
    const response = await this.actions.post<number[]>({
      url: '/attribute/amp/filter',
      options: { headers: this.authHeaders },
      data,
      performLatestOnly,
    });
    this.actions.resetPostCancelTokenSource();

    if (response === undefined) {
      return null;
    }

    return this.handleResponse(response);
  }

  @autobind
  public async validateRows(data: SM.ValidateAttributesRequest): Promise<SM.AttributesErrorData[]> {
    const response = await this.actions.post<SM.AttributesErrorData[]>({
      url: '/attribute/amp/validate-rows',
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getAttributes(): Promise<M.Attribute[]> {
    const response = await this.actions.get<SM.ServerAttribute[]>({
      url: `/attribute/clientspace-web`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, data => data.map(converters.convertServerAttribute));
  }

  @autobind
  public async getAttributesToCabinet(cabinetId: number): Promise<M.Attribute[]> {
    const response = await this.actions.get<SM.ServerAttribute[]>({
      url: '/attribute/cabinet',
      options: { headers: this.authHeaders },
      data: { cabinetId }
    });
    return this.handleResponse(response, data => data.map(converters.convertServerAttribute));
  }

  @autobind
  public async getAMPRelations(): Promise<any> {
    const response = await this.actions.get({
      url: `/attribute/amp/relations`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getMetadataForAttributesAMP(): Promise<SM.MetadataForAttributeAMP[]> {
    const response = await this.actions.get<SM.MetadataForAttributeAMP[]>({
      url: `/attribute/amp`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getAMPDictionary(url: string): Promise<any> {
    const response = await this.actions.get({
      url: `${url}/`,
      options: { headers: this.authHeaders },

    });
    return this.handleResponse(response);
  }

  @autobind
  public async getAttributeDictionaryData(url: string): Promise<M.DictionarySubject[]> {
    const response = await this.actions.get<SM.ServerDictionarySubject[]>({
      url: `${url}/`,
      options: { headers: this.authHeaders },

    });
    return this.handleResponse(response, xs => xs.map(converters.convertDictionarySubjectData));
  }

  @autobind
  public async getDictionaryByAttributeId(attributeId: number): Promise<M.Dictionary> {
    const response = await this.actions.get<SM.ServerDictionary>({
      url: '/dictionaries/',
      options: { headers: this.authHeaders },
      data: { attributeId },
    });
    return this.handleResponse(response, converters.convertDictionary);
  }

  @autobind
  public async getDictionariesByCabinetId(cabinetId: number): Promise<M.Dictionary[]> {
    const response = await this.actions.get<SM.ServerDictionary[]>({
      url: '/dictionaries/cabinet',
      options: { headers: this.authHeaders },
      data: { cabinetId },
    });
    return this.handleResponse(response, dictionaries => dictionaries.map(converters.convertDictionary));
  }

  @autobind
  public async getDictionaryAttributeValues(data: GetDictAttributeValuesArgs): Promise<string[]> {
    const response = await this.actions.post<string[]>({
      url: '/dictionaries/dependencies',
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }
}

type GetDictAttributeValuesArgs = {
  dictionaryIdSrc: number;
  dictionaryIdDest: number;
  value: string | null;
};

export default Attribute;
