import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import { CabinetParamsValues } from 'shared/types/models';
import { DecodedToken } from '../../types/decodedToken';
import { hasAdminRole } from '../../helpers/hasAdminRole';

export function convertServerCabinet(cabinets: SM.ServerCabinet[], decodedToken: DecodedToken | null): M.Cabinet[] {
  return cabinets.flatMap<M.Cabinet>(serverCabinet =>
    serverCabinet.positionTypes.map<M.Cabinet>(positionType => ({
      cabinetType: serverCabinet.cabinetType,
      id: serverCabinet.id,
      name: serverCabinet.name,
      positionType: {
        dataType: convertServerCabinetIdType(positionType.idType),
        hasParams: positionType.hasParams,
        id: positionType.id,
        name: positionType.name,
        parameters: positionType.parameters,
        isAutoGenerated: !!positionType.isAutoGenerated,
        readOnly: !hasAdminRole(decodedToken) && !!positionType.isAutoGenerated,
      },
    }))
  );
}

export function convertServerCabinetForExport(cabinets: SM.ServerCabinet[]): M.ExportCabinet.Cabinet[] {
  return cabinets
    .filter(cabinet => cabinet.isAbleToCreateCampaign)
    .map(cabinet => ({
      id: cabinet.id,
      name: cabinet.name,
    }));
}

export function convertServerCabinetIdType(idType: SM.ServerCabinetDataType) {
  const map: Record<SM.ServerCabinetDataType, M.CabinetDataType> = {
    INTEGER: 'integer',
    STRING: 'string',
  };
  return map[idType];
}

export function convertStatisticsSource(data: (string | null)[]): string [] {
  return data.filter(x => x !== null) as string[];
}

export function convertAppsFlyerCabinetParameters(data: SM.ServerDictionary[]): CabinetParamsValues[] {
  return data.flatMap(dict => dict.dictionaryValues.map(x => ({
    id: x.id,
    value: x.value,
    cabinetParamId: dict.dictionaryId,
  })));
}
