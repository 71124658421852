import * as M from 'shared/types/models';

export function getMergedRegionColumnRange(x: M.MarkupMediaplan.MergedRegion): M.Range {
  return {
    start: x.coordinate.column,
    end: x.coordinate.column + x.colspan - 1,
  }
}

export function getMergedRegionRowRange(x: M.MarkupMediaplan.MergedRegion): M.Range {
  return {
    start: x.coordinate.row,
    end: x.coordinate.row + x.rowspan - 1,
  }
}
