export function getEnvParams() {
  const isProduction = process.env.NODE_ENV === 'production';
  const isDevelopment = process.env.NODE_ENV === 'development';

  const appVersion = window.env.appVersion;
  const sentryDSN = window.env.sentryDSN;
  const baseAPI = window.env.apiUrl;
  const mockApiURL = window.env.mockApiUrl;
  const keyCloackSSOAPI = window.env.keycloakSsoApiUrl;
  const portalURL = window.env.portalURL;
  const portalBackURL = window.env.portalBackURL;
  const environment = window.env.environment;

  return {
    isProduction,
    isDevelopment,
    appVersion,
    sentryDSN,
    baseAPI,
    keyCloackSSOAPI,
    portalURL,
    portalBackURL,
    mockApiURL,
    environment,
  };
}
