import { v4 as uuidv4 } from 'uuid';

import { IDColumn } from 'shared/types/models/EditMetrics';

export function makeIDColumn(): IDColumn {
  const id = uuidv4();
  return {
    kind: 'id',
    title: 'ID',
    filterColumnIndex: null,
    contentIndex: 0,
    markupID: id,
    id,
  };
}
