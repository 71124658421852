
import * as M from 'shared/types/models';

export function getIndexColumn(column: M.AbstractColumn, columns: M.AbstractColumn[]) {
  return columns.findIndex(x => x.id === column.id);
}

export function getIndexColumnBy<T>(condition: (column: T) => boolean, columns: T[]) {
  const index = columns.findIndex(condition);
  return index !== -1 ? index : null
}

export function getColumnBy<T>(condition: (column: T) => boolean, columns: T[]) {
  const column = columns.find(condition);
  return column ?? null;
}
