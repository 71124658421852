import { CellChange, FactRow, TableCell, CellContent } from 'shared/types/models/EditMetrics';

type Arguments = {
  rows: (Omit<FactRow, 'content'> & { content: CellContent[] | null })[];
  cells: TableCell[][];
  columnIndexes: number[];
};

export function makeChangesFromFactContent(args: Arguments) {
  const { cells, columnIndexes, rows } = args;
  return rows.reduce<CellChange[]>((acc, { rowId, content }) => {
    const rowIndex = cells.findIndex(([firstCell]) => firstCell.content === rowId);

    if (rowIndex === -1) {
      return acc;
    }
    const changes = columnIndexes.map<CellChange | null>((columnIndex, index) => {
      const cell = cells[rowIndex][columnIndex];
      if (content === null) {
        const needToClearValue = !cell.isChanged && cell.content;
        return needToClearValue ? {
          coordinate: { column: columnIndex, row: rowIndex },
          cellProperties: { ...cell, content: null },
        } : null;
      }

      const newValue = content[index];
      return {
        coordinate: { column: columnIndex, row: rowIndex },
        cellProperties: !cell.isChanged
          ? { ...cell, content: newValue, contentFromServer: newValue }
          : { ...cell, contentFromServer: newValue },
      };
    }).filter(Boolean) as CellChange[];

    if (!changes.length) {
      return acc;
    }
    return [...acc, ...changes];
  }, []);
}
