import { put, all, call } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { MetadataForAttributeAMP } from 'shared/types/models/Server';

import * as M from 'shared/types/models';

import { actionCreators as alertActionCreators } from 'services/Alert';
import { tryCatch } from 'services/ErrorTracking';

import { convertMetadataAmpAttribute } from 'services/api/resources/attribute/converters';

import { LoadAmpAttributes } from '../../../types';

import * as actionCreators from '../../actionCreators';

export function* loadAmpAttributes({ api }: IDependencies, { payload: { withDictionaries } }: LoadAmpAttributes) {
  yield tryCatch({
    *successed() {
      const metadataAttributes: MetadataForAttributeAMP[] = yield call(api.attribute.getMetadataForAttributesAMP);
      const effects = metadataAttributes.map(metadata => call(getAttribute, metadata, api, withDictionaries));
      const ampAttributes: M.AttributeAMP[] = yield all(effects);
      yield put(actionCreators.loadAmpAttributesCompleted({ ampAttributes }));
    },
    *failed(_, message) {
      yield put(alertActionCreators.openAlert({ message: 'Ошибка при загрузке AMP атрибутов', type: 'error' }));
      yield put(actionCreators.loadAmpAttributesFailed(message));
    },
  });
}

async function getAttribute(
  metadata: MetadataForAttributeAMP,
  api: IDependencies['api'],
  withDictionaries: boolean,
): Promise<M.AttributeAMP> {
  const dictionaries = metadata.valueType === 'DICTIONARY' && withDictionaries && metadata.url
    ? await api.attribute.getAttributeDictionaryData(metadata.url[0])
    : [];
  return convertMetadataAmpAttribute(metadata, dictionaries);
}
