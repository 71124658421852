import { makeCommunicationReducer } from 'redux-make-communication';
import { combineReducers } from 'redux';

import * as TS from '../../types';
import { initialState } from '../intialState';

export const communication = combineReducers<TS.ReduxState['communication']>({
  loadingAttributes: makeCommunicationReducer<TS.LoadAttributes, TS.LoadAttributesCompleted, TS.LoadAttributesFailed>(
    'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES',
    'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES_FAILED',
    initialState.communication.loadingAttributes,
  ) as any,
  loadingMetrics: makeCommunicationReducer<TS.LoadMetrics, TS.LoadMetricsCompleted, TS.LoadMetricsFailed>(
    'COMMON_DATA_SERVICE:LOAD_METRICS',
    'COMMON_DATA_SERVICE:LOAD_METRICS_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_METRICS_FAILED',
    initialState.communication.loadingMetrics,
  ) as any,
  loadingMediaplan: makeCommunicationReducer<TS.LoadMediaplan, TS.LoadMediaplanCompleted, TS.LoadMediaplanFailed>(
    'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN',
    'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN_FAILED',
    initialState.communication.loadingMediaplan,
  ) as any,
  loadingCabinets: makeCommunicationReducer<TS.LoadCabinets, TS.LoadCabinetsCompleted, TS.LoadCabinetsFailed>(
    'COMMON_DATA_SERVICE:LOAD_CABINETS',
    'COMMON_DATA_SERVICE:LOAD_CABINETS_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_CABINETS_FAILED',
    initialState.communication.loadingCabinets,
  ) as any,
  loadingAmpAttributes: makeCommunicationReducer<TS.LoadAmpAttributes, TS.LoadAmpAttributesCompleted, TS.LoadAmpAttributesFailed>(
    'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES',
    'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES_FAILED',
    initialState.communication.loadingAmpAttributes,
  ) as any,
  loadingAmpMetrics: makeCommunicationReducer<TS.LoadAMPMetrics, TS.LoadAMPMetricsCompleted, TS.LoadAMPMetricsFailed>(
    'COMMON_DATA_SERVICE:LOAD_AMP_METRICS',
    'COMMON_DATA_SERVICE:LOAD_AMP_METRICS_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_AMP_METRICS_FAILED',
    initialState.communication.loadingAmpMetrics,
  ) as any,
  loadingCSDictionaries: makeCommunicationReducer<TS.LoadCSDictionaries, TS.LoadCSDictionariesCompleted, TS.LoadCSDictionariesFailed>(
    'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES',
    'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES_FAILED',
    initialState.communication.loadingCSDictionaries,
  ) as any,
  loadingCsCampaignInfo: makeCommunicationReducer<TS.LoadCsCampaignInfo, TS.LoadCsCampaignInfoCompleted, TS.LoadCsCampaignInfoFailed>(
    'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO',
    'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO_FAILED',
    initialState.communication.loadingCsCampaignInfo,
  ) as any,
  loadingCsAmpCampaignInfo: makeCommunicationReducer<TS.LoadCsAmpCampaignInfo, TS.LoadCsAmpCampaignInfoCompleted, TS.LoadCsAmpCampaignInfoFailed>(
    'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO',
    'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO_COMPLETE',
    'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO_FAILED',
    initialState.communication.loadingCsCampaignInfo,
  ) as any,
});
