import { lineBreak, maxLineLength } from './constants';

export function addLineBreaksToTitle(columnTitle: string) {
  const splittedBySpace = columnTitle.split(' ');
  const titleWithBreaks = addBreakLines(splittedBySpace);
  return titleWithBreaks.join(' ');
}

function addBreakLines(words: string[]): string[] {
  return words.reduce<string[]>((line, word, index, arr) => {
    if (line.length === arr.length) {
      return line;
    }
    const newLine = [...line, word];
    const newLineLength = newLine.join(' ').length;
    if (newLineLength > maxLineLength) {
      return [...line, `${word}${lineBreak}`, ...addBreakLines(arr.slice(index + 1))];
    }
    return newLine;
  }, []);
}
