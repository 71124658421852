import Handsontable from 'handsontable';

type Depedencies = {
  hotInstance: Handsontable | null,
};

const dependencies: Depedencies = {
  hotInstance: null,
};

export function getHotInstance(): Handsontable | null {
  const { hotInstance } = dependencies;
  return hotInstance;
}

export function withHotInstance(mutator: (x: Handsontable) => void) {
  const { hotInstance } = dependencies;
  if (hotInstance !== null) {
    mutator(hotInstance);
  } else {
    console.warn('uninitialized hot instance on mutation request');
  }
}


export function initDependencies(x: Partial<typeof dependencies>) {
  Object.assign(dependencies, x);
}
