import numbro from 'numbro';

export function round(value: string | number, mantissa: number) {
  const rounded = numbro(value).format({
    optionalMantissa: false,
    trimMantissa: true,
    mantissa,
  })
  return Number(rounded);
}
