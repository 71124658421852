import Handsontable from 'handsontable';
import { ColumnConditions } from 'handsontable/plugins/filters';

import { hotTable } from 'shared/helpers';

export type FilterIndexChange = {
  prevIndex: number;
  newIndex: number;
};

type Arguments = {
  hotInstance: Handsontable;
  changes: FilterIndexChange[];
  afterClearFiltersCallback?: () => void;
};

export function updateFiltersIndexesByChanges(args: Arguments) {
  const { hotInstance, changes, afterClearFiltersCallback } = args;
  const filters = hotTable.getFilters(hotInstance);

  hotTable.clearFilters(hotInstance, changes.map(change => change.prevIndex));

  if (afterClearFiltersCallback) {
    afterClearFiltersCallback();
  }

  const updatedFilters = filters
    .map(filter => {
      const filterIndexChange = changes.find(change => change.prevIndex === filter.column);
      return filterIndexChange ? { ...filter, column: filterIndexChange.newIndex } : null;
    })
    .filter(x => x !== null) as ColumnConditions[];

  hotTable.addFilters(hotInstance, updatedFilters);
}