import { AxiosResponse } from 'axios';
import decode from 'jwt-decode';

import { ICookies } from './resources/cookies/types';

import { isErrorStatus, isHandledErrorResponse } from './helpers';
import { HandledApiError, ApiError, ErrorResponse, ErrorsListResponse } from './error';
import { Converter } from './types/response';
import { DecodedToken } from './types/decodedToken';
import HttpActions from './HttpActions';

type HandledErrorResponse = ErrorResponse | ErrorsListResponse;

class BaseApi {
  protected actions: HttpActions;
  protected cookies: ICookies;
  protected _token: string | null = null;

  set token(value: string | null) {
    this._token = value;
  }

  constructor(actions: HttpActions, cookies: ICookies) {
    this.actions = actions;
    this.cookies = cookies;
  }

  protected handleResponse<ResponseData>(response: AxiosResponse<ResponseData | HandledErrorResponse>): ResponseData;
  protected handleResponse<ResponseData, Result>(
    response: AxiosResponse<ResponseData | HandledErrorResponse>,
    converter: Converter<ResponseData, Result>,
  ): Result;

  protected handleResponse<ResponseData, Result>(
    response: AxiosResponse<ResponseData | HandledErrorResponse>,
    converter?: Converter<ResponseData, Result>,
  ): Result | ResponseData {

    if (isHandledErrorResponse(response)) {
      throw new HandledApiError(response);
    }

    if (isErrorStatus(response.status)) {
      throw new ApiError({ response });
    }

    const responseData = response.data as ResponseData;
    if (converter) {
      return converter(responseData);
    }

    return responseData;
  }

  protected get authHeaders() {
    if (!this._token) {
      console.error('Api token not found!');
    }

    return { Authorization: `Bearer ${this._token}`, 'Realm': 'ADV' };
  }

  protected get decodedToken(): DecodedToken | null {
    return this._token ? decode(this._token) : null;
  }
}

export default BaseApi;
