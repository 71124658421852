import { call, put } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';

import { tryCatch } from 'services/ErrorTracking';

import * as actionCreators from '../../actionCreators';

export function* loadCabinets({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const cabinets: ReturnPromisedType<typeof api.cabinet.getCabinets> = yield call(api.cabinet.getCabinets);
      yield put(actionCreators.loadCabinetsCompleted({ cabinets }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadCabinetsFailed(message));
    },
  });
}
