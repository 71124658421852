import * as React from 'react';

import { block } from 'bem-cn';

import CircularProgress from '@material-ui/core/CircularProgress';

import './style.scss';

const b = block('preloader');

interface IOwnProps {
  isShown: boolean;
  transparent?: boolean;
  size?: number;
  thickness?: number;
}

class Preloader extends React.Component<IOwnProps> {

  public render() {
    const { isShown, transparent, size = 100, thickness = 2 } = this.props;
    return (
      <div className={b({ hidden: !isShown, transparent: Boolean(transparent) })}>
        <CircularProgress
          className={b('preloader', { hidden: !isShown }).toString()}
          size={size}
          style={{ color: '#129acc' }}
          thickness={thickness}
        />
      </div>
    );
  }
}

export { Preloader };
