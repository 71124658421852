import * as React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import block from 'bem-cn';
import { Modal as MuiModal } from '@material-ui/core';


import * as M from 'shared/types/models';
import { IAppReduxState } from 'shared/types/app';
import { ICommunication } from 'shared/types/redux';
import { IOption } from 'shared/types/utils';
import { Modal, Select } from 'shared/view/elements';

import { selectors, actionCreators } from '../../../redux';

import './style.scss';

type ActionProps = typeof containerActionCreators;
type Props = OwnProps & StateProps & ActionProps;

type OwnProps = {
  onSubmitAmpCampaign(dealAmpId: number): void;
};

type StateProps = {
  dealAmpId: number | null;
  ampCampaigns: M.AmpCampaigns | null;
  isVisibleCampaignsModal: boolean;
  loadingAmpCampaigns: ICommunication;
};

type State = {
  campaignOptions: IOption<number>[];
  selectedCampaign: IOption<number> | null;
};

const b = block('amp-campaigns-modal');

class CampaignsModal extends React.PureComponent<Props, State> {
  public state: State = {
    campaignOptions: [],
    selectedCampaign: null,
  };

  public componentDidUpdate(prevProps: Props) {
    const { ampCampaigns } = this.props;

    if (prevProps.ampCampaigns === null && ampCampaigns !== null) {
      const matchingCampaign = ampCampaigns.campaignNameToDealAmpIdMatch ? [ampCampaigns.campaignNameToDealAmpIdMatch] : [];
      const campaignOptions = [...matchingCampaign, ...ampCampaigns.campaignNamesToDealAmpIdsMapping]
        .map(x => ({ label: x.campaignName, value: x.dealAmpID }));
      const needSelectFirstCampaign = ampCampaigns.campaignNameToDealAmpIdMatch || ampCampaigns.campaignNamesToDealAmpIdsMapping.length === 1;

      this.setState({
        campaignOptions,
        selectedCampaign: needSelectFirstCampaign ? campaignOptions[0] : null,
      });
    }
  }

  public componentWillUnmount() {
    const { setDealAmpId, setVisibilityAmpCampaignsModal, setAmpCampaigns } = this.props;
    setDealAmpId({ dealAmpID: null });
    setVisibilityAmpCampaignsModal(false);
    setAmpCampaigns({ ampCampaigns: null });
  }

  public render() {
    const { isVisibleCampaignsModal, loadingAmpCampaigns } = this.props;
    const { campaignOptions, selectedCampaign } = this.state;

    return (
      <MuiModal open={isVisibleCampaignsModal}>
        <Modal
          title="Выберите кампанию"
          accessButtonText="Выбрать"
          disableAccessButton={!campaignOptions.length}
          isShow={isVisibleCampaignsModal}
          onAccept={this.onAccept}
          isVisibleOverflow
          modalClasses={{ root: b().toString() }}
        >
          {!loadingAmpCampaigns.isRequesting && campaignOptions.length === 0 ? (
            <p className={b('warning-message')}>Кампания в AMP не найдена</p>
          ) : (
            <Select
              options={campaignOptions}
              value={selectedCampaign ? selectedCampaign.value : ''}
              onChange={this.onChangeCampaign}
              placeholder="Выберите кампанию"
              isLoading={loadingAmpCampaigns.isRequesting}
            />
          )}
        </Modal>
      </MuiModal>
    );
  }

  @autobind
  private onAccept() {
    const { setDealAmpId, setVisibilityAmpCampaignsModal, onSubmitAmpCampaign } = this.props;
    const { selectedCampaign } = this.state;

    if (selectedCampaign) {
      const dealAmpID = selectedCampaign.value;
      setDealAmpId({ dealAmpID });
      setVisibilityAmpCampaignsModal(false);
      onSubmitAmpCampaign(dealAmpID);
    }
  }

  @autobind
  private onChangeCampaign(value: number) {
    const selectedCampaign = this.state.campaignOptions.find(o => o.value === value);

    if (selectedCampaign) {
      this.setState({ selectedCampaign });
    }
  }
}

function mapState(state: IAppReduxState): StateProps {
  return {
    dealAmpId: selectors.selectDealAmpID(state),
    ampCampaigns: selectors.selectAmpCampaigns(state),
    isVisibleCampaignsModal: selectors.selectVisibilityAmpCampaignModal(state),
    loadingAmpCampaigns: selectors.selectCommunication(state, 'loadingAmpCampaigns'),
  };
}

const containerActionCreators = {
  setDealAmpId: actionCreators.setDealAmpID,
  setVisibilityAmpCampaignsModal: actionCreators.setVisibilityAmpCampaignsModal,
  setAmpCampaigns: actionCreators.setAmpCampaigns,
};

export default connect(mapState, containerActionCreators)(CampaignsModal);
