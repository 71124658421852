import * as NS from '../../types';
import { makeCommunicationActionCreators } from 'shared/helpers/redux';

// tslint:disable:max-line-length

export const { execute: loadCabinets, completed: loadCabinetsCompleted, failed: loadCabinetsFailed } =
  makeCommunicationActionCreators<NS.LoadCabinets, NS.LoadCabinetsCompleted, NS.LoadCabinetsFailed>(
    'EDIT_METRICS:LOAD_CABINETS', 'EDIT_METRICS:LOAD_CABINETS_COMPLETE', 'EDIT_METRICS:LOAD_CABINETS_FAILED',
  );

export const { execute: initTable, completed: initTableCompleted, failed: initTableFailed } =
  makeCommunicationActionCreators<NS.InitializeTable, NS.InitializeTableCompleted, NS.InitializeTableFailed>(
    'EDIT_METRICS:INITIALIZE_TABLE', 'EDIT_METRICS:INITIALIZE_TABLE_COMPLETE', 'EDIT_METRICS:INITIALIZE_TABLE_FAILED',
  );

export const { execute: loadMediaplan, completed: loadMediaplanCompleted, failed: loadMediaplanFailed } =
  makeCommunicationActionCreators<NS.LoadMediaplan, NS.LoadMediaplanCompleted, NS.LoadMediaplanFailed>(
    'EDIT_METRICS:LOAD_MEDIAPLAN', 'EDIT_METRICS:LOAD_MEDIAPLAN_COMPLETE', 'EDIT_METRICS:LOAD_MEDIAPLAN_FAILED',
  );

export const { execute: loadAttributes, completed: loadAttributesCompleted, failed: loadAttributesFailed } =
  makeCommunicationActionCreators<NS.LoadAttributes, NS.LoadAttributesCompleted, NS.LoadAttributesFailed>(
    'EDIT_METRICS:LOAD_ATTRIBUTES', 'EDIT_METRICS:LOAD_ATTRIBUTES_COMPLETE', 'EDIT_METRICS:LOAD_ATTRIBUTES_FAILED',
  );

export const { execute: loadMetrics, completed: loadMetricsCompleted, failed: loadMetricsFailed } =
  makeCommunicationActionCreators<NS.LoadMetrics, NS.LoadMetricsCompleted, NS.LoadMetricsFailed>(
    'EDIT_METRICS:LOAD_METRICS', 'EDIT_METRICS:LOAD_METRICS_COMPLETE', 'EDIT_METRICS:LOAD_METRICS_FAILED',
  );

export const { execute: saveMediaplan, completed: saveMediaplanCompleted, failed: saveMediaplanFailed } =
  makeCommunicationActionCreators<NS.SaveMediaplan, NS.SaveMediaplanCompleted, NS.SaveMediaplanFailed>(
    'EDIT_METRICS:SAVE_MEDIAPLAN', 'EDIT_METRICS:SAVE_MEDIAPLAN_COMPLETE', 'EDIT_METRICS:SAVE_MEDIAPLAN_FAILED',
  );

export const { execute: loadTemplate, completed: loadTemplateCompleted, failed: loadTemplateFailed } =
  makeCommunicationActionCreators<NS.LoadTemplate, NS.LoadTemplateCompleted, NS.LoadTemplateFailed>(
    'EDIT_METRICS:LOAD_TEMPLATE', 'EDIT_METRICS:LOAD_TEMPLATE_COMPLETE', 'EDIT_METRICS:LOAD_TEMPLATE_FAILED',
  );


export const { execute: addMetric, completed: addMetricCompleted, failed: addMetricFailed } =
  makeCommunicationActionCreators<NS.AddMetric, NS.AddMetricCompleted, NS.AddMetricFailed>(
    'EDIT_METRICS:ADD_METRIC', 'EDIT_METRICS:ADD_METRIC_COMPLETE', 'EDIT_METRICS:ADD_METRIC_FAILED',
  );

export const { execute: loadCalendarPeriods, completed: loadCalendarPeriodsCompleted, failed: loadCalendarPeriodsFailed } =
  makeCommunicationActionCreators<NS.LoadCalendarPeriods, NS.LoadCalendarPeriodsCompleted, NS.LoadCalendarPeriodsFailed>(
    'EDIT_METRICS:LOAD_CALENDAR_PERIODS', 'EDIT_METRICS:LOAD_CALENDAR_PERIODS_COMPLETE', 'EDIT_METRICS:LOAD_CALENDAR_PERIODS_FAILED',
  );

export const { execute: editMetric, completed: editMetricCompleted, failed: editMetricFailed } =
  makeCommunicationActionCreators<NS.EditMetric, NS.EditMetricCompleted, NS.EditMetricFailed>(
    'EDIT_METRICS:EDIT_METRIC', 'EDIT_METRICS:EDIT_METRIC_COMPLETE', 'EDIT_METRICS:EDIT_METRIC_FAILED',
  );

