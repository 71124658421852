import Handsontable from 'handsontable';

export function getNumericFormatting(decimalSigns: number | null): Handsontable.NumericFormatOptions {
  const pattern = decimalSigns !== null
    ? {
      thousandSeparated: true,
      optionalMantissa: true,
      mantissa: decimalSigns,
      trimMantissa: true,
    } as any
    : { thousandSeparated: true };
  return { pattern, culture: 'ru-RU' };
}
