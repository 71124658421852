import { ReducersMap } from 'shared/types/redux';
import { RootSaga, IAppReduxState, IReduxEntry } from 'shared/types/app';
import { configureStore, createReducer } from './configureStore';
import { configureDeps } from './configureDeps';

import { CommonData as commonDataService, AmpCampaigns, DownloadFile, Templates } from 'services';

type ReducerName = keyof IAppReduxState;

function configureApp() {
  const connectedSagas: RootSaga[] = [];
  const connectedReducers: Partial<ReducersMap<IAppReduxState>> = {};

  const { runSaga, store } = configureStore();
  // TODO: research how it works with several concurrent request,
  // it seems that such bindings works well only if requests are synchronous
  const dependencies = configureDeps();

  const servicesSagas = [
    commonDataService.saga,
    AmpCampaigns.saga,
    DownloadFile.saga,
    Templates.saga,
  ];

  servicesSagas.forEach(serviceSaga => runSaga(serviceSaga, dependencies))

  function connectEntryToStore({ reducers, sagas }: IReduxEntry) {
    if (!store) {
      throw new Error('Cannot find store, while connecting module.');
    }

    if (reducers) {
      const keys = Object.keys(reducers) as ReducerName[];
      const isNeedReplace = keys.reduce(<K extends ReducerName>(acc: boolean, key: K) => {
        const featureReducer = reducers[key];
        if (!connectedReducers[key] && featureReducer) {
          connectedReducers[key] = featureReducer;
          return true;
        }
        return acc || false;
      }, false);

      if (isNeedReplace) {
        const newReducer = createReducer(connectedReducers as ReducersMap<IAppReduxState>)
        store.replaceReducer(newReducer);
      }
    }

    if (sagas) {
      sagas.forEach((saga: RootSaga) => {
        if (!connectedSagas.includes(saga) && runSaga) {
          runSaga(saga(dependencies));
          connectedSagas.push(saga);
        }
      });
    }
  }

  return { store, connectEntryToStore };
}

export { configureApp };
