import 'babel-polyfill';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
// import 'normalize.css';

import { Store } from 'redux';

interface IProps {
  store: Store;
}

const ClientApp: React.FC<IProps> = (props) => {
  const { store, children } = props;

  return (
    <Provider store={store}>
      <BrowserRouter>
        {children}
      </BrowserRouter>
    </Provider>
  );
}

export const App = hot(ClientApp);
