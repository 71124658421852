import { call, put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { tryCatch } from 'services/ErrorTracking';
import { LoadCsAmpCampaignInfo } from '../../../types';
import { actionCreators, selectors } from '../../../redux';

export function* loadCsAmpCampaignInfo({ api }: IDependencies, { payload: { dealAmpId } }: LoadCsAmpCampaignInfo) {
  yield tryCatch({
    *successed() {
      const mediaplanID: number | null = yield select(selectors.selectMediaplanID);
      const mediaplanVersionID: number | null = yield select(selectors.selectMediaplanVersionID);

      if (!mediaplanID || !mediaplanVersionID) {
        throw Error('mediaplanId not found');
      }
      const csAmpCampaignInfo: ReturnPromisedType<typeof api.mediaplan.getCsAmpCampaignInfo> =
        yield call(api.mediaplan.getCsAmpCampaignInfo, {
          mediaplanVersionID,
          mediaplanID,
          dealAmpId,
        });
      yield put(actionCreators.loadCsAmpCampaignInfoCompleted({ csAmpCampaignInfo }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadCsAmpCampaignInfoFailed(message));
    },
  });
}
