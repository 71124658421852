import Handsontable from 'handsontable';

export function setScale(x: Handsontable, scale: number) {
  const hotTableWrapper = x.rootElement.parentElement;
  if (!hotTableWrapper) return;
  hotTableWrapper.style.zoom = `${scale / 100}`;
  x.render();
  // after manual change scale has not visible part of table, select and deselect allow make all table visible
  x.selectCell(0, 0, 0, 0, false);
  x.deselectCell();
}
