import * as React from 'react';

import { ErrorOverlay } from './components';
import { ErrorTracking } from '../ErrorTracking';

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<{}, IState> {

    public state = {
      hasError: false,
    };

    private errorTracking = ErrorTracking.getInstance();

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo) {
      this.errorTracking.tracker.withScope(scope => {
        scope.setExtras(errorInfo);
        this.errorTracking.captureException(error);
      });
    }

    render() {
      if (this.state.hasError) {
        return <ErrorOverlay />
      }

      return this.props.children;
    }
}

export { ErrorBoundary };