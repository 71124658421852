import * as R from 'ramda';

import { TableCell, CellChange, CellContent } from 'shared/types/models/EditMetrics';
import { GeneralCell } from 'shared/types/models/Server';

import { stylesRatioCell } from '../../constants';

export function applyChangesToCells(changes: CellChange[], cells: TableCell[][]): TableCell[][] {
  return changes.reduce(
    (acc: TableCell[][], { coordinate: { row, column }, cellProperties }: CellChange): TableCell[][] =>
      R.over(
        R.lensPath([row, column]),
        (cell: TableCell) => ({ ...cell, ...cellProperties }),
        acc,
      ),
    cells,
  );
}

export function getCellsByColumnIndex(cells: TableCell[][], columnIndex: number): TableCell[] {
  return cells.reduce((acc, row) => [...acc, row[columnIndex]], []);
}

export function makeEmptyCell(value: GeneralCell): TableCell {
  return { style: {}, content: value };
}

export function makeRatioCell(value: GeneralCell): TableCell {
  return { style: stylesRatioCell, content: value };
}

export function convertCellsToContent(cells: TableCell[][]): CellContent[][] {
  return cells.map(row => row.map(cell => cell.content));
}

export function removeCellsByColumn<T>(tableData: T[][], removeIndex: number): T[][] {
  return tableData.map(row => R.remove(removeIndex, 1, row));
}

export function removeCellsByColumnsIndexes<T>(tableData: T[][], columnsIndexes: number[]): T[][] {
  return columnsIndexes.sort((x, y) => x - y).reduce(
    (acc, columnIndex, index) => removeCellsByColumn(acc, columnIndex - index),
    tableData,
  );
}

export function insertEmptyColumnsCells(cells: TableCell[][], columnsIndexes: number[]): TableCell[][] {
  const emptyColumnData = makeEmptyCells(cells.length);
  return columnsIndexes.reduce(
    (acc, columnIndex) => insertColumn(acc, emptyColumnData, columnIndex),
    cells,
  );
}

export function insertEmptyRatioColumnsCells(cells: TableCell[][], columnsIndexes: number[]): TableCell[][] {
  const emptyColumnData = makeRatioColumnCells(cells.length);
  return columnsIndexes.reduce(
    (acc, columnIndex) => insertColumn(acc, emptyColumnData, columnIndex),
    cells,
  );
}

export function makeEmptyArray(amount: number): null[] {
  return R.repeat(null, amount);
}

export function makeEmptyCells(amount: number): TableCell[] {
  return R.repeat(null, amount).map(makeEmptyCell);
}

export function makeRatioColumnCells(amount: number): TableCell[] {
  return R.repeat(null, amount).map(makeRatioCell);
}

export function insertColumn<T>(data: T[][], columnData: T[], insertIndex: number): T[][] {
  return data.map((row, rowIndex) => R.insert(insertIndex, columnData[rowIndex], row));
}
