import * as R from 'ramda';

import * as M from 'shared/types/models';
import { Column, ColumnMetricType, MetricColumn, EditableMetric, MetricType } from 'shared/types/models/EditMetrics';
import { FilterIndexChange } from 'shared/helpers/handsontableHelpers';

import { factualMetricsTypes } from '../../constants';

export function getRangeByHeaderID(markupID: string, columns: Column[]): M.Range {
  const indexes = columns.reduce<number[]>((acc, column, index) =>
    column.markupID === markupID ? [...acc, index] : acc
  , []);
  return { start: indexes[0], end: indexes[indexes.length - 1] };
}

export function getColumnsIndexesByHeaderID(headerID: string, columns: Column[]) {
  const { start, end } = getRangeByHeaderID(headerID, columns);
  return R.range(start, end + 1);
}

export function isFactMetric(type: ColumnMetricType) {
  return R.includes(type, factualMetricsTypes);
}

export function getIndexColumn(column: Column, columns: Column[]) {
  return columns.findIndex(x => x.id === column.id);
}

export function getIndexColumnByProperties(props: Partial<Column>, columns: Column[]) {
  return columns.findIndex(x =>
    Object.entries(props).every(([columnProp, value]) => R.equals(value, x[columnProp]))
  );
}

export function getRatioColumnsIndexesByColumn(column: MetricColumn, columns: Column[]) {
  return columns.reduce<number[]>((acc, x, index) => {
    return x.kind === 'ratio'
      && x.metricID === column.metricID
      && (x.metricType === column.metricType || column.metricType === 'plan')
        ? [...acc, index]
        : acc;
  }, []);
}

export function getMetricColumnsIndexes(columns: Column[]) {
  return columns.reduce<number[]>((acc, column, index) => column.kind === 'metric' ? [...acc, index] : acc, []);
}

export function getColumnsByMetric(columns: Column[], metric: EditableMetric, metricType: MetricType) {
  return columns.filter(x => x.kind === 'metric' && x.metricType === metricType && x.metricID === metric.id);
}

export function isDateIntersect(startA: number, endA: number, startB: number, endB: number) {
  if (startA <= startB && startB <= endA) return true;
  if (startA <= endB   && endB   <= endA) return true;
  if (startB <  startA && endA   <  endB) return true;
  return false;
}

export function getFilterIndexesChanges(columns: Column[]) {
  return columns.reduce<FilterIndexChange[]>((acc, column, columnIndex) => {
    if (column.filterColumnIndex !== null && column.filterColumnIndex !== columnIndex) {
      return [...acc, { prevIndex: column.filterColumnIndex, newIndex: columnIndex }];
    }
    return acc;
  }, []);
}
