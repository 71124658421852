import axios, { AxiosResponse } from 'axios';

import { InterceptorPayload } from './types';
import { IAuthResponse } from '../resources/auth/types';
import Auth from '../resources/auth/auth';

export type TokenExpiredHandler = (payload: InterceptorPayload, onReject: (err: any) => void) => Promise<AxiosResponse>;

function makeHandleTokenExpired(auth: Auth): TokenExpiredHandler {
  let refreshTokenPromise: Promise<IAuthResponse> | null = null;

  return ({ response: axiosResponse }, onReject): Promise<AxiosResponse> => {
    const { config } = axiosResponse;
    return new Promise((resolve, reject) => {

      if (!refreshTokenPromise) {
        refreshTokenPromise = auth.refreshToken();
      }

      refreshTokenPromise
        .then(async (refreshTokenResponse) => {
          const updatedConfig = auth.updateConfigWithNewToken(config, refreshTokenResponse.access_token);
          const repeatResponse = await axios.request(updatedConfig);
          refreshTokenPromise = null;
          resolve(repeatResponse);
        }
        )
        .catch((error) => {
          console.error(error.message);
          reject(axiosResponse);
          onReject(error);
        });
    });
  };
}

export { makeHandleTokenExpired };
