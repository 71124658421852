import { PredefinedMenuItemKey } from 'handsontable/plugins/contextMenu';
import { ICommunication } from 'shared/types/redux';

export const initialCommunicationState: ICommunication = { isRequesting: false, error: '', completed: false };

export const timelineMetricId = 4;
export const googleAnalyticsId = 2;
export const statisticsSourceIds = [6, 7];

export const myTargetCabinetId = 3;
export const doubleVerifyCabinetId = 26;
export const appsFlyerCabinetId = 27;

export const appsFlyerAppDictionaryId = 119;
export const appsFlyerSourceDictionaryId = 120;

export const maxAmountInvalidCells = 45;
export const maxAmountErrorMessages = 10;

export const unitTypeAttributeID = 24;
export const typeOfActivityAttributeID = 25;
export const unitVolumeMetricID = 13;

export const typedAttributeIdToTypeMap = {
  [unitTypeAttributeID]: 'unit-type',
  [typeOfActivityAttributeID]: 'type-of-activity',
} as const;
export const typedAttributeIds = Object.keys(typedAttributeIdToTypeMap).map(Number);

export const styleForCellWithTimelinePlacement: React.CSSProperties = {
  backgroundColor: 'darkgreen',
  color: 'whitesmoke',
};

export const styleForCellWithDiffContent: React.CSSProperties = {
  backgroundColor: '#ff9800',
};

export const styleForDisabledCell: React.CSSProperties = {
  backgroundColor: '#e6e6e6',
};

export const styleForReadonlyCell: React.CSSProperties = {
  backgroundColor: '#fbfcfc',
};

export const styleForCenterAlignedCell: React.CSSProperties = {
  textAlign: 'center',
  verticalAlign: 'middle',
};

export const styleForWideCell: React.CSSProperties = {
  minWidth: '350px',
};

export const styleForWidestCell: React.CSSProperties = {
  minWidth: '500px',
};

export const clientPeriodDateFormat = 'dd/MM/yy';
export const clientSingleDateFormat = 'dd/MM/yyyy';
export const serverDateFormat = 'yyyy-MM-dd';

export const filters: PredefinedMenuItemKey[] = [
  'filter_by_value',
  '---------',
  'filter_by_condition',
  '---------',
  'filter_action_bar',
];

export const datePickerConfig = {
  firstDay: 1,
  i18n: {
    previousMonth: 'Предыдущий месяц',
    nextMonth: 'Следующий месяц',
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
  }
};

export const csDictionaryAttributesIDs = typedAttributeIds; // TODO: get a list of attributes from the backend when api is ready

export const croppingTextLimit = 35;
