import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

import { ReduxState } from '../types/ReduxState';
import * as A from '../types/actions';
import { initialState } from './intialState';

export const reducer = combineReducers<ReduxState>({
  communication: combineReducers<ReduxState['communication']>({
    downloadingFile: makeCommunicationReducer<A.DownloadFile, A.DownloadFileSuccess, A.DownloadFileFail>(
      'FILE_DOWNLOADING_SERVICE:DOWNLOAD',
      'FILE_DOWNLOADING_SERVICE:DOWNLOAD_SUCCESS',
      'FILE_DOWNLOADING_SERVICE:DOWNLOAD_FAIL',
      initialState.communication.downloadingFile,
    ) as any,
  }),
});
