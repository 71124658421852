import { call, put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { tryCatch } from 'services/ErrorTracking';
import { actionCreators, selectors } from '../../../redux';

export function* loadMetrics({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanId: number | null = yield select(selectors.selectMediaplanID);
      const mediaplanVersionId: number | null = yield select(selectors.selectMediaplanVersionID);

      if (mediaplanVersionId === null || mediaplanId === null) {
        throw Error('not correct mediaplan ids');
      }
      const metrics: ReturnPromisedType<typeof api.metric.getMetrics> =
        yield call(api.metric.getMetrics, mediaplanId, mediaplanVersionId);
      yield put(actionCreators.loadMetricsCompleted({ metrics }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadMetricsFailed(message));
    },
  });
}
