import { Column, RatioColumn, MetricColumn } from 'shared/types/models/EditMetrics';

import { isFactMetric } from '../../columns';

export function getRelationColumnsIndexesByRatioColumn(columns: Column[], ratioColumn: RatioColumn
  ) {
  const relatedColumns = columns.filter(x =>
    x.kind === 'metric'
    && x.metricID === ratioColumn.metricID
    && (x.metricType === ratioColumn.metricType || x.metricType === 'plan')
  ) as MetricColumn[];

  const planColumn = relatedColumns.find(x => x.metricType === 'plan');
  const factColumn = relatedColumns.find(x => isFactMetric(x.metricType));
  if (planColumn && factColumn) {
    const planColumnIndex = columns.findIndex(x => x.id === planColumn.id);
    const factColumnIndex = columns.findIndex(x => x.id === factColumn.id);
    return { planColumnIndex, factColumnIndex };
  }
  return null;
}
