import Handsontable from 'handsontable';
import { deselectCells } from './deselectCells';

export function removeColumns(x: Handsontable, indexes: number[]) {
  indexes.forEach((deletingIndex, index) => {
    x.alter('remove_col', deletingIndex - index, 1);
  });
  deselectCells(x);
  x.render();
}
