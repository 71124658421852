import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';
import {
  FormLabel,
  TextField,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import withCheckAccessAction from 'services/AccessActionModal/withCheckAction';
import { Input, Select } from 'shared/view/elements';
import * as M from 'shared/types/models';

import './style.scss';

type Props = {
  mediaplanId: number | null;
  dictionaries: M.TemplateDictionary[];
  templates: M.Template[];
  currentTemplateId: number | null;
  makeHandleTemplateItemChange(templateId: number): () => void;
  makeHandleDeleteButtonClick(templateId: number): () => void;
  loadBrandDictionary: ({ advertiserId: number }) => void;
  isLoadingBrandDictionary: boolean;
};

type State = {
  name: string;
  year: number;
  advertiserId: number | null;
  brandId: number | null;
  isPreviouslyUsed: boolean;
  isArchived: boolean;
};

const b = block('template-selection');

class TemplateSelection extends React.PureComponent<Props, State> {
  private DeleteButton = withCheckAccessAction({
    WrappedComponent: IconButton,
    checkedAction: 'onClick',
    conditionShowWarning: () => true,
    getTitle: () => 'Вы действительно хотите удалить шаблон?',
  });

  public state: State = {
    name: '',
    year: new Date().getFullYear(),
    advertiserId: null,
    brandId: null,
    isPreviouslyUsed: false,
    isArchived: false,
  };

  public render() {
    return (
      <div className={b()}>
        {this.renderFilters()}
        {this.renderFilteredTemplates()}
      </div>
    );
  }

  private renderFilters() {
    const { name, advertiserId, brandId, isPreviouslyUsed, isArchived, year } = this.state;

    return (
      <div className={b('filters')}>
        <div className={b('name')}>
          <Input
            value={name}
            onChange={this.handleNameInputChange}
            placeholder="Имя шаблона"
            isClearable
          />
        </div>
        <div className={b('select-wrapper')}>
          <div className={b('field-wrapper')}>
            <FormLabel classes={{ root: `${b('label')}` }}>Рекламодатель</FormLabel>
            <Select
              options={this.makeConvertDictionariesByOptions('advertisers')}
              value={advertiserId ?? 0}
              onChange={this.handleAdvertisersSelectChange}
              searchable
            />
          </div>
          <div className={b('field-wrapper')}>
            <FormLabel classes={{ root: `${b('label')}` }}>Бренд</FormLabel>
            <Select
              options={this.makeConvertDictionariesByOptions('brands')}
              value={brandId ?? 0}
              onChange={this.handleBrandsSelectChange}
              searchable
              isLoading={this.props.isLoadingBrandDictionary}
            />
          </div>
        </div>
        <div className={b('filter-year-and-checkboxes-wrapper')}>
          <section className={b('filter-checkboxes')}>
            <FormControlLabel
              classes={{ root: `${b('filter-checkbox-label')}` }}
              label="ранее используемые в медиаплане"
              control={
                <Checkbox
                  classes={{ root: `${b('filter-checkbox')}` }}
                  checked={isPreviouslyUsed}
                  onChange={this.makeHandleCheckboxChange('isPreviouslyUsed')}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              classes={{ root: `${b('filter-checkbox-label')}` }}
              label="архивные"
              control={
                <Checkbox
                  classes={{ root: `${b('filter-checkbox')}` }}
                  checked={isArchived}
                  onChange={this.makeHandleCheckboxChange('isArchived')}
                  color="primary"
                />
              }
            />
          </section>
          <TextField
            type="number"
            label="Год"
            value={year}
            onChange={this.handleYearInputChange}
            size="small"
          />
        </div>
      </div>
    );
  }

  private renderFilteredTemplates() {
    const { mediaplanId, templates, currentTemplateId, makeHandleTemplateItemChange, makeHandleDeleteButtonClick } = this.props;
    const { advertiserId, brandId, name, year, isArchived, isPreviouslyUsed } = this.state;
    const filteredTemplates = templates
      .filter(x => (
        (!advertiserId || x.advertiserId === advertiserId) &&
        (!brandId || x.brandId === brandId) &&
        (!name || x.name.toLowerCase().includes(name.toLowerCase())) &&
        (!year || x.year === year) &&
        (!isPreviouslyUsed || x.sourceMediaplanId === mediaplanId) &&
        (isArchived || !x.isDeleted)
      ));

    return (
      <section className={b('filtered-templates')}>
        <div className={b('list')}>
          <List>
            {filteredTemplates.map((template) => (
              <ListItem
                button
                selected={currentTemplateId === template.id}
                onClick={makeHandleTemplateItemChange(template.id)}
                key={template.id}
              >
                <div className={b('list-item')}>
                  <ListItemText primary={template.name}/>
                  <ListItemText primary={template.year}/>
                </div>
                <ListItemSecondaryAction>
                  {!template.isDeleted && (
                    <this.DeleteButton
                      edge="end"
                      aria-label="template"
                      onClick={makeHandleDeleteButtonClick(template.id)}
                    >
                      <DeleteIcon />
                    </this.DeleteButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </section>
    )
  }

  @autobind
  private makeConvertDictionariesByOptions(name: 'advertisers' | 'brands') {
    const options = name === 'brands'
      ? this.getBrandsByCurrentAdvertiser() ?? []
      : this.props.dictionaries.map(x => ({ id: x.advertiserId, name: x.advertiserName }));

    return [
      ...options.map(x => ({ value: x.id, label: x.name })),
      { value: 0, label: 'Все' },
    ];
  }

  private getBrandsByCurrentAdvertiser() {
    return this.props.dictionaries.find(x => x.advertiserId === this.state.advertiserId)?.brands ?? null;
  }

  @autobind
  private handleNameInputChange(name: string) {
    this.setState({ name });
  }

  @autobind
  private handleAdvertisersSelectChange(advertiserId: number) {
    this.setState({ advertiserId, brandId: 0 }, () => {
      const brands = this.getBrandsByCurrentAdvertiser();
      if (brands === null && advertiserId > 0) {
        this.props.loadBrandDictionary({ advertiserId });
      }
    });
  }

  @autobind
  private handleBrandsSelectChange(brandId: number) {
    this.setState({ brandId });
  }

  @autobind
  private makeHandleCheckboxChange<T extends Extract<keyof State, 'isPreviouslyUsed' | 'isArchived'>>(optionName: T) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [optionName]: event.target.checked } as Pick<State, T>);
    };
  };

  @autobind
  private handleYearInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const year = event.target.value.replace(/\D/g, '');
    this.setState({ year: Number(year) });
  }
}

export default TemplateSelection;
