import * as React from 'react';
import cx from 'classnames';
import { block } from 'bem-cn';
import { Card as MuiCard, CardContent, CardHeader } from '@material-ui/core';

import './style.scss';

type Props = {
  className?: string;
  title?: string;
  variant?: 'clear'
};

const b = block('card');

export const Card: React.FC<Props> = (props) => {
  const { className, title, children, variant } = props;
  return (
    <MuiCard  classes={{ root: cx(b({ variant }).toString(), className) }}>
      {title && (
        <CardHeader
          classes={{ root: `${b('title')}` }}
          title={title}
          titleTypographyProps={{ variant: 'h4' }}
        />
      )}
      <CardContent classes={{ root: `${b('content')}` }}>{children}</CardContent>
    </MuiCard>
  );
};

export type { Props as CardProps };