import * as R from 'ramda';
import Handsontable from 'handsontable';

export function updateRowHeadersHeight(
  ht: Handsontable,
  element: 'header' | 'body',
  rowIndexByMaxHeightMap: Record<number, number>,
) {
  const nodeName = element === 'header' ? 'thead' : 'tbody';
  const elem = ht.container?.querySelector(nodeName);
  const scales = ht.rootElement?.querySelectorAll(
    `.ht_clone_top_left_corner ${nodeName}, .ht_clone_left ${nodeName}, .ht_clone_top ${nodeName}, .ht_master ${nodeName}`
  );

  if (elem) {
    R.range(0, elem.children.length).forEach(index => {
      const height = (elem.children[index] as HTMLElement).offsetHeight;
      const maxHeight = rowIndexByMaxHeightMap[index];

      if (!maxHeight || maxHeight < height) {
        rowIndexByMaxHeightMap[index] = height;
      }

      scales.forEach(scale => {
        const element = scale.children[index] as HTMLElement;
        if (element) {
          element.style.height = `${rowIndexByMaxHeightMap[index]}px`;
        }
      });
    });
  }
}