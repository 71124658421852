import * as R from 'ramda';
import Handsontable from 'handsontable';

import { CellCoordinate } from 'shared/types/models';

import { maxAmountInvalidCells } from '../../constants';
import { cropAndFormatItems } from '../formatters';

export function getInvalidCellsCoordinatesWithoutHiddenRows(ht: Handsontable, columnsIndexes: number[]) {
  const hiddenRowsPlugin = ht.getPlugin('hiddenRows');
  if (!hiddenRowsPlugin.enabled) {
    hiddenRowsPlugin.enablePlugin();
  }
  const hiddenRows = hiddenRowsPlugin.getHiddenRows();
  const invalidCellsCoordinates = getInvalidCellsCoordinates(ht, columnsIndexes);
  return invalidCellsCoordinates.filter(({ row }) => !hiddenRows.includes(row));
}

export function checkHasInvalidFilteredCells(ht: Handsontable, columnsIndexes: number[]): boolean {
  return R.range(0, ht.countSourceRows()).some(rowIndex => {
    const visualRowIndex = ht.toVisualRow(rowIndex);
    if (visualRowIndex === null) {
      const rowMeta = ht.getCellMetaAtRow(rowIndex);
      const isHasInvalidCellInRow = rowMeta.some(cellMeta =>
        // cellMeta can be undefined in case with filters
        cellMeta && cellMeta.valid === false && columnsIndexes.includes(cellMeta.col)
      );
      return isHasInvalidCellInRow;
    }
    return false;
  });
}

export function getFormattedCoordinates(ht: Handsontable, coordinates: CellCoordinate[]) {
  const formatter = makeCellCoordinateFormatter(ht);
  return cropAndFormatItems(cropItems, formatter, ', ', coordinates.sort(sortByColumnIndex));
}

export function getInvalidCellsCoordinates(ht: Handsontable, columnsIndexes: number[]) {
  return R.range(0, ht.countRows()).reduce<CellCoordinate[]>((acc, row) => {
    const rowIndex = ht.toPhysicalRow(row);
    const rowMeta = ht.getCellMetaAtRow(rowIndex);

    const invalidColumnIndexes = rowMeta.reduce<CellCoordinate[]>(
      (rowAcc, cellMeta) => {
         // cellMeta can be undefined in case with filters
        return cellMeta && columnsIndexes.includes(cellMeta.col) && cellMeta.valid === false
          ? [...rowAcc, { row: rowIndex, column: cellMeta.col }]
          : rowAcc;
      },
      []
    );
    return [...acc, ...invalidColumnIndexes];
  }, [])
  .sort(sortByColumnIndex);
}

function sortByColumnIndex({ column: aColIndex }, { column: bColIndex }) {
  return aColIndex - bColIndex;
}

export function isTableInvalid(ht: Handsontable) {
  const rowsMeta = R.range(0, ht.countRows()).map((acc, row) => {
    const rowIndex = ht.toPhysicalRow(row);
    return ht.getCellMetaAtRow(rowIndex);
  });
  return rowsMeta.some(rowMeta => rowMeta.some(meta => meta.valid === false));
}

export function makeCellCoordinateFormatter(ht: Handsontable) {
  return ({ row, column }: CellCoordinate) => formatCoordinates(ht, row, column);
}

export function makeRowIdFormatter(ht: Handsontable, idColumnNum?: number) {
  return ({ row }: CellCoordinate) => ht.getDataAtCell(row, idColumnNum || 0);
}

export function formatCoordinates(ht: Handsontable, row: number, column: number) {
  return `${ht.getColHeader(column)}${ht.toVisualRow(row) + 1}`;
}

export function formatInvalidColumnsTitles(titles: string[]) {
  return titles.map(x => `● "${x}"`).join('<br>');
}

export function isNeedCrop(amount: number) {
  return amount > maxAmountInvalidCells;
}

export function maybeCrop(items: string[]) {
  return isNeedCrop(items.length) ? `${items.slice(0, 25).join(', ')}...` : items.join(', ')
}

export function cropItems<T>(items: T[]) {
  return items.slice(0, maxAmountInvalidCells);
}
