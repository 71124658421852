import * as React from 'react';

import AccessActionModal from './AccessActionModal';
import { autobind } from 'core-decorators';

import './withCheckAction.scss';

interface IState {
  isShowWarning: boolean;
  title: string;
  description: string | null;
}

export type FunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function | undefined ? K : never }[keyof T];
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export type ValueOf<T, K extends keyof T> = T[K];

type Arguments<T extends Record<any, any>> = {
  WrappedComponent: React.ComponentType<T>;
  checkedAction: FunctionPropertyNames<T>;
  getTitle: (...args: ArgumentTypes<ValueOf<T, FunctionPropertyNames<T>>>) => string;
  conditionShowWarning: (...args: ArgumentTypes<ValueOf<T, FunctionPropertyNames<T>>>) => boolean;
  getContentText?: (...args: ArgumentTypes<ValueOf<T, FunctionPropertyNames<T>>>) => string | null;
  checkDisableAccessButton?: () => boolean;
  accessButtonText?: string;
  cancelButtonText?: string;
};

function withCheckAccessAction<WrappedComponentProps>(options: Arguments<WrappedComponentProps>) {
  const { 
    WrappedComponent, checkedAction, conditionShowWarning, getTitle, getContentText,
    accessButtonText, cancelButtonText, checkDisableAccessButton
  } = options;
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  type IProps = WrappedComponentProps;

  class CheckerAccessActions extends React.Component<IProps, IState> {
    public static displayName: string = `CheckerAccessActions(${wrappedComponentName})`;

    public state: IState = { isShowWarning: false, title: '', description: null };

    private checkAction = this.makeCheckAction(this.props[checkedAction]);
    private onAccept: () => void = this.stub;

    public render() {
      const { isShowWarning, title, description } = this.state;
      const wrappedComponentProps: WrappedComponentProps = this.props;
      return (
        <>
          <WrappedComponent {...wrappedComponentProps} {...{ [checkedAction]: this.checkAction }} />
          <AccessActionModal
            isShow={isShowWarning}
            onAccept={this.onAccept}
            title={title}
            onClose={this.onClose}
            accessButtonText={accessButtonText}
            cancelButtonText={cancelButtonText}
            disableAccessButton={checkDisableAccessButton?.()}
          >
            {description !== null
              ? <div className="check-action-modal-description" dangerouslySetInnerHTML={{ __html: description }} />
              : undefined
            }
          </AccessActionModal>
        </>
      );
    }

    @autobind
    private stub() {
      console.log('empty function');
    }

    @autobind
    private makeCheckAction(f: any) {
      return (...args: any) => {
        this.onAccept = () => {
          f(...args);
          this.onClose();
        };
        if (conditionShowWarning(...args)) {
          this.setState({ 
            isShowWarning: true,
            title: getTitle(...args),
            description: getContentText ? getContentText(...args) : null,
          });
        } else {
          f(...args);
        }
      };
    }

    @autobind
    private onClose() {
      this.setState({ isShowWarning: false });
    }
  }

  return CheckerAccessActions;
}

export default withCheckAccessAction;
