import * as React from 'react';
import block from 'bem-cn';
import { autobind } from 'core-decorators';

import './style.scss';
import { AppVersion } from 'shared/view/elements';

type Props = {
  renderMainPart(): React.ReactNode;
  renderSidebarContent(): React.ReactNode;
  onSidebarFoldOrUnfold(): void;
  sidebarIsCompact?: boolean;
  isVisibleSidebar?: boolean;
};

type State = {
  sidebarIsFolded: boolean;
};

const b = block('layout-with-sidebar');

class LayoutWithSidebar extends React.Component<Props, State> {
  public state: State = {
    sidebarIsFolded: this.props.isVisibleSidebar !== undefined 
      ? !this.props.isVisibleSidebar
      : false,
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const { onSidebarFoldOrUnfold, isVisibleSidebar } = this.props;
    const { sidebarIsFolded } = this.state;
    if (prevState.sidebarIsFolded !== sidebarIsFolded) {
      onSidebarFoldOrUnfold();
    }
    if (prevProps.isVisibleSidebar !== isVisibleSidebar && isVisibleSidebar !== undefined) {
      this.setState({ sidebarIsFolded: !isVisibleSidebar });
    }
  }

  public render() {
    const { renderMainPart, sidebarIsCompact } = this.props;
    const { sidebarIsFolded } = this.state;

    return (
      <div className={b({
        'sidebar-status': sidebarIsFolded ? 'folded' : 'unfolded',
        'is-compact': sidebarIsCompact,
      })}>
        <div className={b('main-part')}>
          {renderMainPart()}
        </div>
        {this.renderSidebar()}
      </div>
    );
  }

  private renderSidebar() {
    const { renderSidebarContent } = this.props;

    return (
      <div className={b('sidebar')}>
        <div className={b('sidebar-fold-toggle')} onClick={this.handleSidebarFoldToggleClick} />
        {renderSidebarContent()}
        <div className={b('version')}>
          <AppVersion />
        </div>
      </div>
    );
  }

  @autobind
  private handleSidebarFoldToggleClick() {
    this.setState(prevState => ({ sidebarIsFolded: !prevState.sidebarIsFolded }));
  }

}

export { LayoutWithSidebar };
