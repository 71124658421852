import * as M from 'shared/types/models';

import { v4 as uuidv4 } from 'uuid';

export function makeIDColumn(contentIndex: number): M.IDColumn {
  const id = uuidv4();
  return {
    id,
    markupID: id,
    kind: 'id',
    title: 'ID',
    contentIndex,
  };
}
