import { autobind } from 'core-decorators';

import BaseApi from '../../BaseApi';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import { appsFlyerCabinetId } from 'shared/constants';

import * as converters from './converters';

class Cabinet extends BaseApi {


  // TODO: remove legacy endpoints after removing legacy
  @autobind
  public async saveCabinetsForLegacy(id: number, data: any): Promise<any> {
    const response = await this.actions.post({
      url: `/mediaplan/${id}/save-cabinets`,
      options: { headers: this.authHeaders },
      data,
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getCabinetsForLegacy(): Promise<SM.ServerCabinet[]> {
    const response = await this.actions.get<SM.ServerCabinet[]>({
      url: `/cabinet/`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async saveCabinets(args: SaveCabinetsArguments): Promise<void> {
    const { cabinetMapping, mediaplanId, mediaplanVersionId, cabinetContent } = args;
    const response = await this.actions.post({
      url: `/mediaplan/${mediaplanId}/save-cabinets`,
      options: { headers: this.authHeaders },
      data: {
        mediaplan: {
          cabinetContent,
          cabinetMapping,
          metadata: {
            rowIdColumnNum: 0,
          }
        },
        mediaplanVersionId
      },
    });
    this.handleResponse(response);
  }

  @autobind
  public async getCabinets(): Promise<M.Cabinet[]> {
    const response = await this.actions.get<SM.ServerCabinet[]>({
      url: `/cabinet/`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, (data) => converters.convertServerCabinet(data, this.decodedToken));
  }

  @autobind
  public async getCabinetsToExport(): Promise<M.ExportCabinet.Cabinet[]> {
    const response = await this.actions.get<SM.ServerCabinet[]>({
      url: `/cabinet/`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response, converters.convertServerCabinetForExport);
  }

  @autobind
  public async loadAccounts(cabinetId: number, cabinetParamId: number): Promise<SM.CabinetAccountsResponse> {
    const response = await this.actions.get<SM.CabinetAccountsResponse>({
      url: `/cabinet/accounts?cabinetId=${cabinetId}&cabinetParamId=${cabinetParamId}`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async loadStatisticsSource(mediaplanId: number, mediaplanVersionId: number): Promise<string[]> {
    const response = await this.actions.get<(string | null)[]>({
      url: `/cabinet/manual-fact/statistic-source`,
      options: { headers: this.authHeaders },
      data: { mediaplanId, mediaplanVersionId }
    });
    return this.handleResponse(response, converters.convertStatisticsSource);
  }

  @autobind
  public async loadCabinetParameters(): Promise<M.CabinetParamsValues[]> {
    const response = await this.actions.get<M.CabinetParamsValues[]>({
      url: `/cabinet/parameters`,
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async loadAppsFlyerCabinetParameters(): Promise<M.CabinetParamsValues[]> {
    const response = await this.actions.get<SM.ServerDictionary[]>({
      url: '/dictionaries/cabinet',
      options: { headers: this.authHeaders },
      data: { cabinetId: appsFlyerCabinetId },
    });
    return this.handleResponse(response, converters.convertAppsFlyerCabinetParameters);
  }

  @autobind
  public async loadCreatives(
    mediaplanId: number | null,
    mediaplanVersionId: number | null
  ): Promise<M.CabinetCreative[]> {
    const response = await this.actions.post<M.CabinetCreative[]>({
      url: `/mediaplan-creative/${mediaplanId}/all?mediaplanVersionId=${mediaplanVersionId}`,
      data: {},
      options: { headers: this.authHeaders },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async saveCreatives(args: SaveCabinetCreativesArgs): Promise<void> {
    const { cabinetsCreatives, mediaplanId, mediaplanVersionId } = args;
    const response = await this.actions.post<M.CabinetCreative[]>({
      url: `/mediaplan-creative/${mediaplanId}/save`,
      data: {
        creatives: cabinetsCreatives.map((x) => ({
          cabinetId: x.cabinetId,
          creativeUrl: x.creativeUrl,
          itemRowId: x.itemRowId,
          sizmekId: x.sizmekId,
          cabinetJoinId: x.cabinetJoinId,
          cabinetPositionTypeId: x.cabinetPositionTypeId,
        })),
        mediaplanVersionId,
      },
      options: { headers: this.authHeaders },
    });
    this.handleResponse(response);
  }
}

type SaveCabinetCreativesArgs = {
  mediaplanId: number | null;
  mediaplanVersionId: number | null;
  cabinetsCreatives: M.CabinetCreative[];
};

type SaveCabinetsArguments = {
  mediaplanVersionId: number;
  mediaplanId: number;
  cabinetContent: SM.GeneralCell[][];
  cabinetMapping: SM.ServerCabinetMapping[];
};

export default Cabinet;
