import * as A from '../types/actions';

export function openModal(payload: A.OpenModal['payload']): A.OpenModal {
  return {
    type: 'WARNING_SERVICE:OPEN_MODAL',
    payload: payload,
  };
}

export function closeModal(): A.CloseModal {
  return { type: 'WARNING_SERVICE:CLOSE_MODAL' };
}
