import * as M from 'shared/types/models';

import { v4 as uuidv4 } from 'uuid';

type Arguments = {
  attribute: M.Attribute;
  contentIndex: number | null;
};

export function makeAttributeColumn(args: Arguments): M.AttributeColumn {
  const { attribute, contentIndex } = args;
  const id = uuidv4();
  return {
     id,
     markupID: id,
     kind: 'attribute',
     attributeID: attribute.id,
     title: attribute.title,
     contentIndex,
  };
}
