import * as R from 'ramda';

import * as M from 'shared/types/models';
import { Column } from 'shared/types/models/EditMetrics';
import { HandsontableHeader } from 'shared/view/components';

import { checkboxMetricId, ratioHeaderColor, standardHeaderColor, attributeHeaderColor } from '../../../constants';

import { getRangeByHeaderID, getIndexColumn } from '../common';
import { isMarkupBudgetColumn } from '../budget';

export function convertColumnsToHeaderRanges(columns: Column[]) {
  const grouppedColumnsMap = R.groupBy(x => x.markupID, columns);
  return Object.values(grouppedColumnsMap)
    .map<HandsontableHeader.models.ColumnRangeHeader>(grouppedColumns => {
      const [firstColumn] = grouppedColumns;
      const subTitle = (() => {
        if (firstColumn.kind === 'ratio') {
          return firstColumn.subTitle;
        }
        return firstColumn.kind === 'metric' ? firstColumn.metricType : undefined;
      })();

      return {
        title: firstColumn.title,
        subTitles: [subTitle].filter(Boolean) as string[],
        columnRange: getRangeByHeaderID(firstColumn.markupID, columns),
        isCanDelete: firstColumn.kind === 'metric' && firstColumn.metricID !== checkboxMetricId,
        isCanEditDate: false,
        isDateTextFormat: true,
        backgroundColor: (() => {
          if (firstColumn.kind === 'ratio') {
            return ratioHeaderColor;
          }
          return firstColumn.kind === 'attribute' || firstColumn.kind === 'id'
            ? attributeHeaderColor
            : standardHeaderColor;
        })(),
        columnIndexToTitle: grouppedColumns.reduce((acc, column, index) => {
          const colIndex = getIndexColumn(column, columns);
          return column.kind === 'metric' && isMarkupBudgetColumn(column) ? ({ ...acc, [colIndex]: column.columnTitle }) : acc;
        }, {}),
        columnToPeriodMap: grouppedColumns.reduce<Record<number, M.DateRange | null> | undefined>((acc, column) => {
          const colIndex = getIndexColumn(column, columns);
          if (column.kind === 'metric' && column.date) {
            return acc === undefined
              ? ({ [colIndex]: column.date })
              : ({ ...acc, [colIndex]: column.date });
          }
          return acc;
        }, undefined),
      };
  });
}
