import * as R from 'ramda';

import { HeadersMakerArguments } from '../models';

export function makeDateHeaders(args: HeadersMakerArguments) {
  const { columnRangeHeaders, symbolColumnsHeaders } = args;
  return symbolColumnsHeaders.map((initialHeader, index) => {
    const headerWithPeriod = columnRangeHeaders.find(header =>
      header.columnToPeriodMap
      && Object.values(header.columnToPeriodMap).length
      && R.range(header.columnRange.start, header.columnRange.end + 1).includes(index)
    );

    return headerWithPeriod
      ? R.repeat('x', headerWithPeriod.isDateTextFormat ? 14 : 16).join()
      : initialHeader;
  });
}
