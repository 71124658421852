import * as NS from '../../types';

export function removeMetric(payload: NS.RemoveMetric['payload']): NS.RemoveMetric {
  return { type: 'EDIT_METRICS:REMOVE_METRIC', payload };
}

export function setMetricSettings(payload: NS.SetMetricSettings['payload']): NS.SetMetricSettings {
  return { type: 'EDIT_METRICS:SET_METRIC_SETTINGS', payload };
}

export function applyChanges(payload: NS.ApplyChanges['payload']): NS.ApplyChanges {
  return { type: 'EDIT_METRICS:APPLY_CHANGES', payload };
}

export function setCells(payload: NS.SetCells['payload']): NS.SetCells {
  return { type: 'EDIT_METRICS:SET_CELLS', payload };
}

export function setColumns(payload: NS.SetColumns['payload']): NS.SetColumns {
  return { type: 'EDIT_METRICS:SET_COLUMNS', payload };
}

export function addLoadingFactColumnsIDs(payload: NS.AddLoadingFactColumnsIDs['payload']): NS.AddLoadingFactColumnsIDs {
  return { type: 'EDIT_METRICS:ADD_LOADING_FACT_COLUMNS_IDS', payload };
}

export function removeLoadingFactColumnsIDs(payload: NS.RemoveLoadingFactColumnsIDs['payload'])
: NS.RemoveLoadingFactColumnsIDs {
  return { type: 'EDIT_METRICS:REMOVE_LOADING_FACT_COLUMNS_IDS', payload };
}

export function setIsOpenShowMetricSettingsModal(
  payload: NS.SetIsOpenMetricSettingsModal['payload']
): NS.SetIsOpenMetricSettingsModal {
  return { type: 'EDIT_METRICS:SET_IS_OPEN_METRIC_SETTINGS_MODAL', payload };
}

export function setParameterValue(payload: NS.SetParameterValue['payload']): NS.SetParameterValue {
  return { type: 'EDIT_METRICS:SET_PARAMETER', payload };
}

export function loadFactData(payload: NS.LoadFactData['payload']): NS.LoadFactData {
  return { type: 'EDIT_METRICS:LOAD_FACT_DATA', payload };
}

export function setColumnsFilters(payload: NS.SetColumnsFilters['payload']): NS.SetColumnsFilters {
  return { type: 'EDIT_METRICS:SET_COLUMNS_FILTERS', payload };
}

export function initVisibleCellsMeta(): NS.InitVisibleCellsMeta {
  return { type: 'EDIT_METRICS:INIT_VISIBLE_CELLS_META' };
}
