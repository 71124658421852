import * as R from 'ramda';
import { RangeType } from 'handsontable/plugins/copyPaste';

import { placementsAttributeId } from '../../constants';

export function isIntersectColumns(coords: RangeType[], indexes: number[]): boolean {
  return coords.some(x => indexes.some(index => R.range(x.startCol, x.endCol + 1).includes(index)));
}

export function isPlacementsAttribute(attributeID: number): boolean {
  return attributeID === placementsAttributeId;
}
