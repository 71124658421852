import * as React from 'react';
import { RouteProps } from 'react-router';
import { Helmet } from 'react-helmet';

import * as features from 'features';
import { withAsyncFeatures } from 'core';

import { ErrorBoundary } from 'services/ErrorTracking';
import { Alert } from 'services/Alert';
import { WarningModal } from 'services/Warning';
import { getHelmetTale } from 'shared/helpers';

interface IFeatureProps {
  ExportAMP: features.ExportAMP.Entry;
}

type IProps = RouteProps & IFeatureProps;

class ExportAMP extends React.PureComponent<IProps> {
  public render() {
    const { ExportAMP } = this.props;

    return (
      <div>
        <Helmet defer={false}>
          <title>Экспорт в АМП - {getHelmetTale()} Client Space</title>
        </Helmet>
        <Alert />
        <ErrorBoundary>
          <WarningModal />
          <ExportAMP.containers.ExportAMP />
        </ErrorBoundary>
      </div>
    );
  }
}

export default withAsyncFeatures({ ExportAMP: features.ExportAMP.loadEntry })(ExportAMP);
