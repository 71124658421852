import * as A from '../types/actions'

export function increaseScale(): A.IncreaseScale {
  return { type: 'SCALE:INCREASE_SCALE' };
}

export function decreaseScale(): A.DecreaseScale {
  return { type: 'SCALE:DECREASE_SCALE' };
}

export function setScale(payload: A.SetScale['payload']): A.SetScale {
  return { type: 'SCALE:SET_SCALE', payload };
}
