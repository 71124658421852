import * as React from 'react';
import block from 'bem-cn';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { autobind } from 'core-decorators';

import * as M from './model';
import './style.scss';

type Props<PanelKey extends string> = {
  panels: M.Panel<PanelKey>[];
  activePanelsKeys: PanelKey[];
  level?: M.Level;
  onPanelChange(x: PanelKey, expanded: boolean): void;
};

const b = block('accordion');

const valuesForLevelModifiers: Record<M.Level, string> = {
  1: 'one',
  2: 'two',
}

class Component<PanelKey extends string> extends React.PureComponent<Props<PanelKey>> {
  public render() {
    const { panels, level = 1 } = this.props;

    return (
      <div className={b({ 'of-level': valuesForLevelModifiers[level] })}>
        {panels.map(this.renderPanel)}
      </div>
    );
  }

  @autobind
  private renderPanel(x: M.Panel<PanelKey>) {
    const { activePanelsKeys } = this.props;

    const { key, label, isDisabled = false, renderContent } = x
    return (
      <Accordion
        key={key}
        expanded={activePanelsKeys.includes(key)}
        onChange={this.makePanelChangeHandler(key)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          disabled={isDisabled}
        >
          <h2 className={b('label')}>{label}</h2>
        </AccordionSummary>
        <AccordionDetails
          className={b(`${key}-panel-details`).toString()}
        >
          {renderContent()}
        </AccordionDetails>
      </Accordion>
    );
  }

  private makePanelChangeHandler(key: PanelKey) {
    return (_: React.ChangeEvent<{}>, expanded: boolean) => this.props.onPanelChange(key, expanded);
  }
}

export { Component };
