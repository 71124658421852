import * as React from 'react';

import { autobind } from 'core-decorators';

import { ButtonGroup as ButtonGroupMUI, Button as ButtonMUI, TooltipProps } from '@material-ui/core';

import { Tooltip } from 'shared/view/elements';

type Button<K> = {
  label: string;
  key: K;
  tooltip: string | null;
  tooltipPlacement?: TooltipProps['placement'];
}

type OwnProps<K> = {
  activeKey: K;
  buttons: Button<K>[];
  disabled?: boolean;
  onChangeKey(activeKey: K): void;
};

class ButtonGroup<K> extends React.PureComponent<OwnProps<K>> {

  public render() {
    const { activeKey, buttons, disabled = false } = this.props;

    return (
      <ButtonGroupMUI variant="contained">
        {buttons.map(x => {
          const button = (
            <ButtonMUI
              key={x.label}
              variant="contained"
              onClick={this.makeChangeKey(x.key)}
              style={{ 
                backgroundColor: !disabled && x.key === activeKey ? '#4caf50' : undefined,
                textTransform: 'lowercase',
                color: disabled || x.key === activeKey ? '#fff' : '#000',
              }}
              disabled={disabled}
            >
              {x.label}
            </ButtonMUI>
          );
          return x.tooltip
            ? (
              <Tooltip
                key={x.label}
                title={x.tooltip}
                placement={x.tooltipPlacement || 'top-start'}
              >
                {button}
              </Tooltip>
            )
            : button
        })}
      </ButtonGroupMUI>
    );
  }

  @autobind
  private makeChangeKey(key: K) {
    return () => this.props.onChangeKey(key);
  }

}

export default ButtonGroup;
