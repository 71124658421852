import * as React from 'react';
import { block } from 'bem-cn';

import { Scale as ScaleService } from 'services';

import './style.scss';

const b = block('sidebar');

type OwnProps = {
  children: React.ReactNode;
  isScaleDisabled: boolean;
};

const Sidebar = (props: OwnProps) => (
  <div className={b()}>
    <div className={b('scale')}>
      <ScaleService.containers.Scale disabled={props.isScaleDisabled} />
    </div>
    {props.children}
  </div>
);

export { Sidebar };
