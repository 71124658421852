import * as M from 'shared/types/models';
import { TypedContent } from 'shared/types/models/Server';
import { IAppReduxState } from 'shared/types/app';
import * as selectors from 'services/CommonData/redux/selectors';

import { getStandardContent } from './getStandardContent';

export function getInitialCellValue(
  value: M.StandardContent | TypedContent,
  attributeId: number,
  getState: () => IAppReduxState,
) {
  if (M.typeGuards.isTypedContent(value)) {
    const state = getState();
    const dictionaryValues = selectors.selectCSDictionaryValuesByAttributeId(state, attributeId);
    return dictionaryValues.find(x => x.id === value.csid)?.value ?? value.originalValue;
  }

  return getStandardContent(value);
}
