import Handsontable from 'handsontable';
import { ColumnConditions } from 'handsontable/plugins/filters';

export function getFilters(ht: Handsontable): ColumnConditions[] {
  // Handsontable wrong types for method exportAllConditions
  const filterPlugin = ht.getPlugin('filters');
  if (filterPlugin.conditionCollection) {
    return filterPlugin.conditionCollection.exportAllConditions() as any;
  }
  return [];
}