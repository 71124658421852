import * as React from 'react';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Button, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { actionCreators as alertActionCreators } from 'services/Alert';
import { IAppReduxState } from 'shared/types/app';

import { actionCreators, selectors } from '../../../redux';
import './style.scss';
import { bottomBound, upperBound } from 'services/Scale/constants';

type OwnProps = {
  disabled: boolean;
}

type StateProps = {
  scale: number;
}

type ActionProps = typeof containerActionCreators;

type Props = OwnProps & StateProps & ActionProps;

function mapState(state: IAppReduxState): StateProps {
  return {
    scale: selectors.selectScale(state),
  };
}

const b = block('scale');

class ScaleComponent extends React.PureComponent<Props> {

  public render() {
    const { increaseScale, decreaseScale } = this.props;
    return (
      <div className={b()}>
        {this.renderButton('-', decreaseScale)}
        {this.renderInput()}
        {this.renderButton('+', increaseScale)}
      </div>
    );
  }

  private renderInput() {
    const { scale } = this.props;
    const { disabled } = this.props;
    return (
      <div className={b('text-field')}>
        <NumberFormat
          customInput={TextField}
          label="Масштаб"
          value={scale}
          onBlur={this.handleTextFieldBlur}
          onKeyPress={this.handleKeyPress}
          decimalScale={0}
          disabled={disabled}
          allowNegative={false}
        />
      </div>
    );
  }

  @autobind
  private handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  }

  @autobind
  private handleTextFieldBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const { setScale, scale: prevScale, openAlert } = this.props;
    if (!e.target.value) {
      e.target.value = String(prevScale);
      this.forceUpdate();
      return;
    }
    const scale = Number(e.target.value);

    if (bottomBound <= scale && scale <= upperBound) {
      setScale(scale);
    } else {
      setScale(scale < bottomBound ? bottomBound : upperBound);
      openAlert({ message: 'Значение масштаба должно находиться в диапазоне от 10 до 150', type: 'error' });
      this.forceUpdate();
    }
  }

  private renderButton(text: string, onClick: () => void) {
    const { disabled } = this.props;
    return (
      <div className={b('button')}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </Button>
      </div>
    );
  }
}

const containerActionCreators = {
  increaseScale: actionCreators.increaseScale,
  decreaseScale: actionCreators.decreaseScale,
  setScale: actionCreators.setScale,
  openAlert: alertActionCreators.openAlert,
}

export const Scale = (
  connect(mapState, containerActionCreators)(
    ScaleComponent
  ));
