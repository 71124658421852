import * as TS from '../../types';

export function setDealAmpID(payload: TS.SetDealAmpID['payload']): TS.SetDealAmpID {
  return { type: 'AMP_CAMPAIGNS_SERVICE:SET_DEAL_AMP_ID', payload };
}

export function setVisibilityAmpCampaignsModal(payload: TS.SetVisibilityAmpCampaignsModal['payload']): TS.SetVisibilityAmpCampaignsModal {
  return { type: 'AMP_CAMPAIGNS_SERVICE:SET_VISIBILITY_AMP_CAMPAIGNS_MODAL', payload };
}

export function showAmpCampaignsModal(): TS.ShowAmpCampaignsModal {
  return { type: 'AMP_CAMPAIGNS_SERVICE:OPEN_AMP_CAMPAIGNS_MODAL' };
}

export function setAmpCampaigns(payload: TS.SetAmpCampaigns['payload']): TS.SetAmpCampaigns {
  return { type: 'AMP_CAMPAIGNS_SERVICE:SET_AMP_CAMPAIGNS', payload };
}
