import * as TS from '../types';
import { initialState } from './intialState';

export function reducer(state: TS.ReduxState = initialState, action: TS.ServiceAction): TS.ReduxState {
  switch (action.type) {

    case 'ALERT_SERVICE:OPEN_ALERT':
      const { message, type } = action.payload;
      return { isShow: true, message, type };

    case 'ALERT_SERVICE:CLOSE_ALERT':
      return { ...state, isShow: false, type: null };

    default:
      return state;
  }
}
