import * as M from 'shared/types/models';
import { getTimelineCustomPeriodsValue } from 'shared/helpers';

export function convertCellValue(cell: M.PageCell) {
  if (M.typeGuards.isTimelineCell(cell) && cell.periodType === 'full') {
    return '';
  }

  if (M.typeGuards.isTimelineCell(cell) && cell.periodType === 'custom') {
    return getTimelineCustomPeriodsValue(cell.content);
  }

  return cell.content;
}
