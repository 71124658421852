import * as R from 'ramda';

import { Column } from 'shared/types/models/EditMetrics';

import { makeRatioColumn } from '../makers';
import { isFactMetric } from '../common';

export function addRatioColumns(columns: Column[]): Column[] {
  return columns.reduce<Column[]>((acc, column, index) => {
    const lastIndexCurrentColumn = R.findLastIndex(x => x.markupID === column.markupID, columns);
    const isFactMetricColumn = column.kind === 'metric' && isFactMetric(column.metricType);
    const isColumnWithoutDate = column.kind === 'metric' && column.date === null;
    const foundRelatedPlanMarkup = columns.find(x =>
      x.kind === 'metric'
      && column.kind === 'metric'
      && x.metricID === column.metricID
      && x.metricType === 'plan'
    );
    const ratioColumn = columns
      .find(x => x.kind === 'ratio' && column.kind === 'metric' && x.metricType === column.metricType);

    const isNeedAddСolumn = isFactMetricColumn
      && isColumnWithoutDate
      && foundRelatedPlanMarkup
      && lastIndexCurrentColumn === index
      && !ratioColumn;

    return isNeedAddСolumn && column.kind === 'metric'
      ? [...acc, column, makeRatioColumn(column)]
      : [...acc, column];
  }, []);
}
