import getErrorMessage from 'shared/helpers/getErrorMsg';

import { ErrorTracking } from './ErrorTracking';

export function* tryCatch(args: {
  successed: () => void,
  failed: (error: Error, errorMsg: string) => void,
}
) {
  const { successed, failed } = args;
  try {
    yield successed();
  } catch (error) {
    const message = getErrorMessage(error);
    yield failed(error, message);
    const errorTracking = ErrorTracking.getInstance();
    errorTracking.captureException(error);
  }
}
