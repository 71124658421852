import { ErrorMessage, ErrorColumnType, ExportAMPErrorMessage, ExportFactAmpErrorMessage } from './model';

export const exportAMPErrorTemplates: Record<ExportAMPErrorMessage, string> = {
  'export-amp.dealid_not_exists': 'Отсутствует dealId: {DealID}',
  'export-amp.access-denied.agency-client': 'Ошибка доступа - у данного пользователя ({userId}) нет доступа к данному агентсву ( {AgencyID} ) или клиенту ( {ClientID} ).',
  'export-amp.not_one_mediaplan_in_briefid_cs': 'Ошибка экспорта медиаплана в AMP - в одной кампании несколько медиапланов, ошибочные строки: {briefID_CS}.',
  'export-amp.sql-general-error': `Ошибка экспорта медиаплана в AMP. Номер ошибки: {ErrorNumber}; Сообщение ошибки: {ErrorMessage}`,
  'export-amp.userid-missing': 'Отсутствует идентификатор пользователя:  {userId}.',
  'export-amp.userid_not_exists': 'Пользователь с данным идентификатором {userId} не найден.',
  'amp.export.plan.error-change-brief-status': 'Ошибка смена статуса строки в AMP: {errorList}',
}

export const exportFactAMPErrorTemplates: Record<ExportFactAmpErrorMessage, string> = {
  'amp.export.fact.amp.interaction.error': 'При взаимодействии с AMP произошла ошибка ({status})',
  'amp.export.fact.duplicate-alloc-id': 'Часть экспортируемых строк относится к одной строке AMP. Экспорт невозможен',
  'amp.export.fact.null-alloc-id': 'Экспортируемые строки отсутствуют в AMP. Экспорт невозможен',
  'amp.export.fact.unsuccessful-fact-export-for-alloc': 'Не удалось экспортировать факт для строки с allocId {allocId}. Сообщение от AMP: {message}',
  'amp.export.fact.amp.bad-request.error': 'Ошибка при взаимодействии с AMP. Сообщение: {message}',
  'amp.export.fact.no_briefs_found': 'Не удалось найти указанные брифы для Deal Id {dealId}',
  'amp.export.fact.not-valid-brief-status-in-request': "Переводить в статус 'Старт' возможно только брифы в статусе 'Планнинг'",
}

export const errorTemplates: Record<ErrorMessage, string> = {
  'mediaplan.web.empty-content': 'Некорректный формат файла медиаплана, допустимы только xlsx и xlsm',
  'mediaplan.web.excel.wrong-format': 'Отсутствует сопоставление с кабинетам',
  'mediaplan.web.extract.attribute-metric-columns-intersection': 'У атрибутов и метрик найдено пересечение столбцов: {columnList} ',
  'mediaplan.web.extract.no-required-attributes': 'Отсутствуют обязательные атрибуты ({idList})',
  'mediaplan.web.extract.no-required-metrics': 'Отсутствуют обязательные метрики ({idList})',
  'mediaplan.web.invalid-attribute-value-type': 'Некорректное значение атрибута {attribute}, ожидается тип данных {expectedType} ',
  'mediaplan.web.invalid-mapping-column': 'Разметка содержит некорректный номер столбца {columnNum} ({columnType}, общее колчиество столбцов {columnCount})',
  'mediaplan.web.invalid-row-id': 'Некорректный идентификатор строки медиаплана (индекс строки {rowIndex})',
  'mediaplan.web.export-amp.no-required-metric': 'Не указана обязательная метрика {metricName}',
  'mediaplan.web.export-amp.no-timeline': 'Не указан период размещения',
  'mediaplan.web.duplicate-row-id': 'Медиаплан содержит повторяющиеся идентификаторы строк',
  'mediaplan.web.cabinet-with-no-params': 'Некорректно заданы параметры кабинета ({cabinetIds})',
  'mediaplan.web.invalid-cabinet-id': 'Некорректный идентификатор кабинета ({cabinetIds})',
  'mediaplan.web.invalid-cabinet-position-type-id': 'Некорректный идентификатор кабинета ({positionTypeIds})',
  'mediaplan.web.invalid-cabinet-join-id': 'Некорректный идентификатор сопоставления с кабинетом: строка {itemRowId}, столбец {columnNum}, значение {joinId}',
  'mediaplan.web.invalid-cabinet-parameter': 'Некорректное значение параметра {param}',
  'mediaplan.web.custom-metric.duplicate-title': 'Метрика "{title}" уже существует',
  'mediaplan.web.custom-metric.advertiser-not-found': 'Рекламодатель не найден',
  'mediaplan.web.custom-metric.can-not-edit-bound-to-mediaplan': 'Метрика "{title}" уже используется в других медиапланах. Редактирование невозможно. Обратитесь в службу поддержки.',
  'mediaplan.web.custom-metric.can-not-save': 'Не удалось сохранить кастомную метрику',
  'mediaplan.web.custom-metric.can-not-find': 'Кастомная метрика не найдена',
  'mediaplan.web.custom-metric.archived-can-not-find': 'Архивная кастомная метрика не найдена',
  'mediaplan.web.export-amp.no-access-ldap': 'Нет доступа к системе AMP - пользователь отсутствует в LDAP. Обратитесь в службу техподдержки <a href="mailto:support@aizek.io">support@aizek.io</a>.',
  'mediaplan.web.export-amp.no-access': 'Нет доступа к системе AMP (пользователь {ampUserLogin}). Обратитесь в службу техподдержки <a href="mailto:support@aizek.io">support@aizek.io</a>.',
  'mediaplan.web.export-amp.no-access-no-amp-user': 'Нет доступа к системе AMP - пользователь {ampUserLogin} не найден в AMP. Обратитесь в службу техподдержки <a href="mailto:support@aizek.io">support@aizek.io</a>.',
  ...exportAMPErrorTemplates,
  ...exportFactAMPErrorTemplates,
};

export const errorColumnsTypes: Record<ErrorColumnType, string> = {
  'mediaplan.web.mapping.attribute': 'Атрибут',
  'mediaplan.web.mapping.cabinet': 'Cопоставление с кабинетами',
  'mediaplan.web.mapping.metric': 'Метрика',
};