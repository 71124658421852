import { EditableMetric, Column } from 'shared/types/models/EditMetrics';
import { isMarkupBudgetColumn } from '../budget';

export function getReadOnlyColumnsIndexes(columns: Column[], metrics: EditableMetric[]) {
  return columns.reduce<number[]>((acc, column, index) => {
    if (column.kind === 'metric') {

      if (isMarkupBudgetColumn(column)) {
        return [...acc, index];
      }

      const foundMetric = metrics.find(metric => metric.id === column.metricID);
      if (foundMetric) {
        const readOnly = column.metricType === 'plan'
          ? !foundMetric.isPlanEditable
          : !foundMetric.isFactEditable;
        return readOnly ? [...acc, index] : acc;
      }
    }
    return acc;
  }, []);
}
