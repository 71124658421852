import * as React from 'react';
import { connect } from 'react-redux';
import block from 'bem-cn';
import { Dialog, DialogTitle } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { IAppReduxState } from 'shared/types/app';
import { selectors, actionCreators } from 'services/Warning/redux';

import './style.scss';

type Props = {
  isOpen: boolean;
  message: string | null;
};

const b = block('warning-modal');

class WarningModal extends React.PureComponent<Props> {
  public render() {
    const { isOpen, message } = this.props;

    return (
      <Dialog open={isOpen}>
        <DialogTitle>
          <div className={b()}>
            <Alert severity="error">
              <div dangerouslySetInnerHTML={{ __html: message || '' }} />
            </Alert>
          </div>
        </DialogTitle>
      </Dialog>
    );
  }
}

function mapState(state: IAppReduxState): Props {
  return {
    isOpen: selectors.selectIsOpen(state),
    message: selectors.selectMessage(state),
  };
}

const containerActionCreators = {
  closeModal: actionCreators.closeModal,
};

export default connect(mapState, containerActionCreators)(WarningModal);
