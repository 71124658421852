import * as React from 'react';
import cx from 'classnames';
import block from 'bem-cn';
import * as M from 'shared/types/models';
import { CardProps } from 'shared/view/elements';

import { CampaignInfoCard } from '../CampaignInfoCard';

import './style.scss';

type Props = CardProps & {
  className?: string;
  info: M.AmpCampaignInfo;
};

const b = block('amp-campaign-info');

function AmpCampaignInfo(props: Props) {
  const {
    className,
    info: { campaignNameAmp, dealAmpId },
    ...cardProps
  } = props;

  const ampCampaignInfo = [
    {
      title: 'Кампания: ',
      value: campaignNameAmp,
    },
    {
      title: 'Deal ID: ',
      value: dealAmpId,
    },
  ];

  return (
    <div className={cx(b().toString(), className)}>
      <CampaignInfoCard
        title="Кампания AMP"
        list={ampCampaignInfo}
        {...cardProps}
      />
    </div>
  );
}

export { AmpCampaignInfo };
