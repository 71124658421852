import * as React from 'react';
import { autobind } from 'core-decorators';

import ReactFlatpickr, { DateTimePickerProps } from 'react-flatpickr';
import Flatpickr from 'flatpickr';

import { Russian } from 'flatpickr/dist/l10n/ru.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

import { FilledInput, InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import 'flatpickr/dist/plugins/monthSelect/style.css';

type OwnProps = {
  onChange(date: Date): void;
  value: Date | null;
  formatToken?: string;
};

class MonthPicker extends React.PureComponent<OwnProps> {

  public render() {
    return (
      <ReactFlatpickr
        options={{
          locale: Russian,
          onChange: this.onChange,
          plugins: [monthSelectPlugin({ shorthand: true, dateFormat: "m.y", altFormat: "F Y" })],
        }}
        render={this.renderInput}
      />
    );
  }

  @autobind
  private onChange(dates: Date[]) {
    const { onChange } = this.props;
    const [date] = dates;
    onChange(date);
  }

  @autobind
  private formatValue() {
    const { formatToken = 'F Y', value } = this.props;
    return value ? (Flatpickr.formatDate as any)(value, formatToken, Russian) : '';
  }

  @autobind
  private renderInput(_: Omit<DateTimePickerProps, 'options' | 'render'>, ref: (node: HTMLInputElement) => void) {
    return (
      <FilledInput
        inputProps={{ 'data-input': true, style: { padding: '10px 5px' } }}
        ref={ref}
        value={this.formatValue()}
        startAdornment={(
          <InputAdornment position="start">
            <CalendarToday />
          </InputAdornment>
        )}
      />
    );
  }
}

export default MonthPicker;
