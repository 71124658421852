import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { tryCatch } from 'services/ErrorTracking';
import { actionCreators as alertActionCreators } from 'services/Alert';
import { actionCreators } from '../../redux';
import { DownloadFile } from '../../types/actions';

export function* downloadFile({ api }: IDependencies, { payload: { file, fileType } }: DownloadFile) {
  yield tryCatch({
    *successed() {
      if (file === null) {
        throw Error('File not found');
      }
      const { fileS3Id, fileName } = file;
      const response: ReturnPromisedType<typeof api.mediaplan.download> = yield call(api.mediaplan.download, fileS3Id, fileType);
      const filename = response.filename ?? fileName;
      if (!filename) {
        throw Error('Missing file name');
      }
  
      yield call(saveAs, response.blob, filename);
      yield put(alertActionCreators.openAlert({ message: `Файл "${filename}" успешно скачан!`, type: 'success' }));
      yield put(actionCreators.downloadFileSuccess());
    },
    *failed(_, message) {
      yield put(alertActionCreators.openAlert({ message: 'Не удалось скачать файл', type: 'error' }));
      yield put(actionCreators.downloadFileFail(message));
    },
  });
}
