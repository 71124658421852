import { v4 as uuidv4 } from 'uuid';

import { RatioColumn, MetricColumn, FactMetricType } from 'shared/types/models/EditMetrics';

export function makeRatioColumn(column: MetricColumn): RatioColumn {
  const id = uuidv4();
  return {
    id,
    kind: 'ratio',
    metricType: column.metricType as FactMetricType,
    metricID: column.metricID,
    markupID: id,
    subTitle: `plan/${column.metricType}`,
    title: column.title,
    filterColumnIndex: null,
    contentIndex: null,
  };
}
