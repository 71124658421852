import { TableCell } from 'shared/types/models/EditMetrics';

import { stylesReadonlyCell } from '../../../constants';

export function updateReadonlyColumnsStyles(cells: TableCell[][], columnsIndexes: number[]): TableCell[][] {
  return cells.map(row => {
    return row.map<TableCell>((cell, index) => columnsIndexes.includes(index)
      ? ({ ...cell, style: stylesReadonlyCell })
      : cell
    );
  });
}
