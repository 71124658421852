import * as R from 'ramda';

import { FactParams } from 'shared/types/models/Server';
import { Column, MetricColumn } from 'shared/types/models/EditMetrics';

import { isFactMetric } from '../common';

export function makeFactParamsFromColumns(columns: Column[]): FactParams[] {
  return R.pipe(
    R.filter<Column>(x =>
      x.kind === 'metric'
      && x.subId !== null
      && isFactMetric(x.metricType)
    ) as (columns: Column[]) => MetricColumn[],
    R.groupBy(x => String(x.subId)),
    R.values,
    R.map(
      (grouppedColumns) => grouppedColumns.reduce<FactParams>((acc, column) => {
        const dates = acc.dates || [];
        return {
          metricId: column.metricID,
          subId: column.subId!,
          dates: column.date ? [...dates, column.date] : dates,
        };
      }, {} as FactParams)
    ),
    R.unnest,
  )(columns);
}
