import * as React from 'react';
import * as R from 'ramda';
import Handsontable from 'handsontable';
import { autobind } from 'core-decorators';
import { block } from 'bem-cn';
import { ReactTooltip } from 'shared/view/components';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import 'flatpickr/dist/themes/material_blue.css';

import * as M from 'shared/types/models';
import { updateRowHeadersHeight } from 'shared/helpers/handsontableHelpers';

import { dangerouslyUpdateNestedHeaders } from './dangerousUpdateHeaders';
import * as LM from './models';
import { makeDateHeaders, makeRangeTitleHeaders, makeColumnTitleHeaders } from './makersHeaders';
import { isNeedAddLineBreak, addLineBreaksToTitle } from './lineBreaks';

import './style.scss';

export type RenderHeaderOptions = {
  afterGetColHeader: (index: number, th: HTMLTableHeaderCellElement) => void;
  beforeOnCellMouseDown: (event: Event) => void;
  afterRender: () => void;
};

type OwnProps<ColumnRangeHeader extends LM.ColumnRangeHeader> = {
  columnRangeHeaders: ColumnRangeHeader[];
  hotInstance: Handsontable | null;
  levelsHeaders: LM.HeaderLevelType[];
  onColumnRangeHeaderRemove?(header: ColumnRangeHeader): void;
  onAutocorrect?(header: ColumnRangeHeader): void;
  onColumnRangeHeaderEdit?(header: ColumnRangeHeader): void;
  onColumnPeriodChange?(header: ColumnRangeHeader, period: M.DateRange, column: number): void;
  children: (options: RenderHeaderOptions) => React.ReactNode;
  className?: string;
  withTooltip?: boolean;
  markupWithDictionaryIDs?: string[];
};

type RenderHeaderLevelProps<ColumnRangeHeader extends LM.ColumnRangeHeader> = {
  index: number;
  th: HTMLTableHeaderCellElement;
  columnRangeHeader?: ColumnRangeHeader;
  levelHeader: LM.HeaderLevelType;
  isMultilevel: boolean;
};

type Props<Header extends LM.ColumnRangeHeader> = OwnProps<Header>;

const b = block('table-header');

class TableHeader<ColumnRangeHeader extends LM.ColumnRangeHeader> extends React.Component<Props<ColumnRangeHeader>> {
  private initialized: boolean = false;

  // cache picker instances for destroy
  private pickerInstances: Record<number, flatpickr.Instance | null> = {};

  private symbolColumnsHeaders: string[] = [];
  private rowIndexByMaxHeightMap: Record<number, number> = {};

  public componentDidUpdate(prevProps: Props<ColumnRangeHeader>) {
    const { columnRangeHeaders, hotInstance } = this.props;

    if (this.symbolColumnsHeaders.length === 0 && hotInstance !== null) {
      this.symbolColumnsHeaders = this.getSymbolColumnsHeaders(hotInstance);
    }

    if (prevProps.hotInstance === null && hotInstance !== null) {
      this.updateNestedHeaders(hotInstance);
    }

    if (hotInstance !== null) {

      if (!this.initialized) {
        // TODO refactor this
        this.initialized = true;
        this.updateNestedHeaders(hotInstance);
      }

      if (
        prevProps.columnRangeHeaders.length !== columnRangeHeaders.length
        || this.someColumnSubTitleHasChanged(prevProps.columnRangeHeaders, columnRangeHeaders)
        || this.somePeriodHasChanged(prevProps.columnRangeHeaders, columnRangeHeaders)
      ) {
        this.updateNestedHeaders(hotInstance);
      }
    }
    this.updateTooltip();
  }


  @autobind
  private updateNestedHeaders(hotInstance: Handsontable) {
    const nestedHeaders = this.getNestedHeaders(hotInstance);
    // safe update nested headers variant
    // hotInstance.updateSettings({ nestedHeaders });
    dangerouslyUpdateNestedHeaders(hotInstance, nestedHeaders);
  }

  @autobind
  private somePeriodHasChanged(prevHeaders: ColumnRangeHeader[], nextHeaders: ColumnRangeHeader[]) {
    return nextHeaders.some((nextHeader, index) => {
      const prevHeader = prevHeaders[index];
      return !R.equals(nextHeader.columnToPeriodMap, prevHeader.columnToPeriodMap);
    });
  }

  @autobind
  private someColumnSubTitleHasChanged(prevHeaders: ColumnRangeHeader[], nextHeaders: ColumnRangeHeader[]) {
    return nextHeaders.some((nextHeader, index) => {
      const prevHeader = prevHeaders[index];
      return !R.equals(nextHeader.subTitles, prevHeader.subTitles);
    });
  }

  public render() {
    const { children } = this.props;

    return children({
      afterGetColHeader: this.renderColumnHeader,
      beforeOnCellMouseDown: this.stopHeaderElementsClick,
      afterRender: this.recalculateSize,
    });
  }

  @autobind
  private updateTooltip() {
    if (this.props.withTooltip) {
      ReactTooltip.refresh();
    }
  }

  @autobind
  private getNestedHeaders(hotInstance: Handsontable): LM.NestedHeader[][] {
    const { columnRangeHeaders, levelsHeaders } = this.props;

    this.symbolColumnsHeaders = this.getSymbolColumnsHeaders(hotInstance);

    if (!columnRangeHeaders.length) {
      return [this.symbolColumnsHeaders];
    }

    const levelHeaderToMakerHeaders: Record<LM.HeaderLevelType, LM.HeadersMaker> = {
      mainTitle: makeRangeTitleHeaders,
      subTitle: makeRangeTitleHeaders,
      topTitle: makeRangeTitleHeaders,
      columnTitle: makeColumnTitleHeaders,
      date: makeDateHeaders,
      symbols: () => this.symbolColumnsHeaders,
    };

    const isMultilevel = levelsHeaders.includes('topTitle');

    const nestedHeaders = levelsHeaders.map(levelHeaderType => {
      const headersMaker = levelHeaderToMakerHeaders[levelHeaderType];
      const headers = this.getColumnRangeHeaders(columnRangeHeaders, levelHeaderType, isMultilevel);
      return headersMaker({
        columnRangeHeaders: headers,
        symbolColumnsHeaders: this.symbolColumnsHeaders,
        autoWrap: !isMultilevel,
      });
    });

    return nestedHeaders;
  }

  @autobind
  private stopHeaderElementsClick(event: any) {
    const node = event.target as HTMLDivElement;
    if (!node) {
      return;
    }
    const isClickOnDateInput = node.closest(`.${b('date-input')}`);
    const isClickOnTopLevelHeader = node.closest(`.${b()}__header-column_top-level`);
    const isClickOnMiddleLevelHeader = node.closest(`.${b()}__header-column_middle-level`);
    const closeIconClass = b('header-icon', { close: true }).toString();
    if (
      (isClickOnDateInput || isClickOnTopLevelHeader || isClickOnMiddleLevelHeader)
      && !node.className.includes(closeIconClass)
    ) {
      event.stopImmediatePropagation();
    }
  }

  @autobind
  private renderColumnHeader(index: number, th: HTMLTableHeaderCellElement) {
    const { columnRangeHeaders, levelsHeaders } = this.props;

    if (!th.parentNode || !th.parentNode.parentNode) {
      return;
    }

    const tr = th.parentNode;
    const thead = th.parentNode.parentNode;
    const levelHeaderIndex = Array.prototype.indexOf.call(thead.childNodes, tr);

    const levelHeaderToRender: Record<LM.HeaderLevelType, (args: RenderHeaderLevelProps<ColumnRangeHeader>) => void> = {
      mainTitle: this.renderRangeTitleHeader,
      subTitle: this.renderRangeTitleHeader,
      topTitle: this.renderRangeTitleHeader,
      columnTitle: this.renderColumnTitleHeader,
      date: this.renderDateHeader,
      symbols: R.identity,
    };

    const levelHeader = levelsHeaders[levelHeaderIndex];
    const render = levelHeaderToRender[levelHeader];
    const isMultilevel = levelsHeaders.includes('topTitle');

    const headers = this.getColumnRangeHeaders(columnRangeHeaders, levelHeader, isMultilevel) as ColumnRangeHeader[];
    const header = headers.find(c => index >= c.columnRange.start && index <= c.columnRange.end);
    render?.({ index, th, columnRangeHeader: header, levelHeader, isMultilevel });
    this.updateTooltip();
  }

  @autobind
  private renderRangeTitleHeader(props: RenderHeaderLevelProps<ColumnRangeHeader>): void {
    const { columnRangeHeader, index, th, levelHeader, isMultilevel } = props;
    const { className, markupWithDictionaryIDs } = this.props;

    const closeIconClass = b('header-icon', { close: true }).toString();

    const hasCustomHeader = columnRangeHeader !== undefined;

    const getCloseIconRender = () => `${
      columnRangeHeader!.isCanDelete && levelHeader === 'mainTitle'
        ? `<div data-index=${index} class="${closeIconClass}"></div>`
        : ''
    }`;

    const getApplyAutoCorrectButtonRender = () => {
      const isButtonVisible =
        columnRangeHeader &&
        'markupID' in columnRangeHeader &&
        columnRangeHeader.markupID &&
        markupWithDictionaryIDs?.includes(columnRangeHeader.markupID) &&
        levelHeader === 'mainTitle';
      return `${
        isButtonVisible
          ? `<button class="${b('apply-auto-correct-button')}"
                     data-index=${index}
                     data-tip="Выполнить автозамену во всем столбце по ранее выбранным значениям"
                     data-place="left"
             >
               <div class="${b('apply-auto-correct-icon')}"></div>
             </button>`
          : ''
      }`;
    };

    th.innerHTML = hasCustomHeader
      ? `
      <div class="${b('top-level-section')}">
        <div class=${b('top-level-title')}>${this.getRangeHeaderTitle(columnRangeHeader!, levelHeader, isMultilevel)}</div>
        ${getCloseIconRender()}
        ${getApplyAutoCorrectButtonRender()}
      </div>
        `
      : '';
    const classes = b('header-column', {
      'empty': !hasCustomHeader,
      'top-level': true,
      'remove-border-bottom': !hasCustomHeader,
      'fill-color-border-bottom': hasCustomHeader,
      'marked-column': hasCustomHeader,
      'right-separate-border': Boolean(
        columnRangeHeader &&
        (index === columnRangeHeader.columnRange.end || index === columnRangeHeader.columnRange.start)
      ),
      'required': columnRangeHeader?.isRequiredSymbol,
      'theme': className || false,
    }).toString().split(' ');

    th.classList.add(...classes);

    if (th.firstElementChild && columnRangeHeader && columnRangeHeader.backgroundColor) {
      th.style.backgroundColor = columnRangeHeader.backgroundColor;
      (th.firstElementChild as HTMLElement).style.backgroundColor = columnRangeHeader.backgroundColor;
      (th.firstElementChild as HTMLElement).style.borderRight = `1px solid ${columnRangeHeader.backgroundColor}`;
    }

    const closeEl = th.querySelector(`.${b()}__header-icon_close`);

    if (closeEl && columnRangeHeader !== undefined) {
      closeEl.addEventListener('click', this.makeCloseIconClickHandler(columnRangeHeader));
    }

    const applyAutocorrectButton = th.querySelector(`.${b('apply-auto-correct-button')}`);

    if (applyAutocorrectButton && columnRangeHeader !== undefined) {
      applyAutocorrectButton.addEventListener('click', this.makeAutocorrectButtonClickHandler(columnRangeHeader));
      applyAutocorrectButton.addEventListener('mouseover', () => ReactTooltip.show(applyAutocorrectButton));
      applyAutocorrectButton.addEventListener('mouseout', () => ReactTooltip.hide(applyAutocorrectButton));
    }

    const subTitleElAll = th.querySelectorAll(`.${b()}__sub-title`);

    if (subTitleElAll && columnRangeHeader !== undefined) {
      subTitleElAll.forEach(subTitleEl =>
        subTitleEl.addEventListener('click', this.makeSubTitleEditClickHandler(columnRangeHeader)));
    }
  }

  private getColumnRangeHeaders(columnRangeHeaders: ColumnRangeHeader[], levelHeader: LM.HeaderLevelType, isMultilevel: boolean) {
    if (isMultilevel && levelHeader !== 'topTitle') {
      return columnRangeHeaders.flatMap(x => x.childHeaders!);
    }
    return columnRangeHeaders;
  }

  @autobind
  private getRangeHeaderTitle(columnRangeHeader: ColumnRangeHeader, levelHeader: LM.HeaderLevelType, isMultilevel: boolean) {

    if (levelHeader === 'mainTitle' || levelHeader === 'topTitle') {
      if (!isMultilevel && isNeedAddLineBreak(columnRangeHeader.title)) {
        return addLineBreaksToTitle(columnRangeHeader.title);
      }
      return columnRangeHeader.title;
    }

    if (levelHeader === 'subTitle' && columnRangeHeader.subTitles.length) {
      return columnRangeHeader.subTitles.map(title =>
        `<span data-tip="${title}" class=${b('sub-title')}>${title.replaceAll(/\n/g, '<br>')}</span>`
      ).join('');
    }

    return '';
  }

  @autobind
  private renderColumnTitleHeader(props: RenderHeaderLevelProps<ColumnRangeHeader>): void {
    const { columnRangeHeader, index, th } = props;

    const columnTitle = columnRangeHeader
      && columnRangeHeader.columnIndexToTitle
      && columnRangeHeader.columnIndexToTitle[index];

    const formattedTitle = columnTitle && isNeedAddLineBreak(columnTitle)
      ? addLineBreaksToTitle(columnTitle)
      : columnTitle;

    th.innerHTML = formattedTitle
      ? `
        <div class=${b('little-title-column')}>
          <span>${formattedTitle}</span>
        </div>
      `
      : '';

    if (columnRangeHeader && columnRangeHeader.backgroundColor) {
      th.style.backgroundColor = columnRangeHeader.backgroundColor;
      th.style.borderRight = `1px solid ${columnRangeHeader.backgroundColor}`;
    }

    const classes = b('header-column', {
      empty: !Boolean(columnRangeHeader),
      'middle-level': true,
      'marked-column': Boolean(columnRangeHeader),
      'right-separate-border': Boolean(columnRangeHeader && index === columnRangeHeader.columnRange.end),
      'fill-color-border-bottom': Boolean(columnRangeHeader),
      'white-text-color': Boolean(columnTitle),
      'required': columnRangeHeader?.columnIndexToRequiredSymbol?.[index],
    }).toString().split(' ');

    th.classList.add(...classes);
  }

  @autobind
  private renderDateHeader(props: RenderHeaderLevelProps<ColumnRangeHeader>): void {
    const { columnRangeHeader, index, th } = props;
    this.destroyPicker(index);
    const dateIconClass = b('header-icon', { date: true }).toString();

    const rangeDate = columnRangeHeader && columnRangeHeader.columnToPeriodMap
      ? columnRangeHeader.columnToPeriodMap[index]
      : null;

    const formattedRangeDate = this.getFormattedRangeDate(rangeDate);
    const dateInput = columnRangeHeader && columnRangeHeader.isDateTextFormat
      ? `
        <div data-index=${index}>
          <span class="${b('date-value', { white: true })}">${formattedRangeDate}</span>
        </div>
      `
      : `
        <div data-index=${index} class=${b('date-input')}>
          <span class=${b('date-value')}>${formattedRangeDate}</span>
          <span class="${dateIconClass}"></span>
          <input data-index=${index} class="${b('calendar-input')}"/>
        </div>
      `;

    th.innerHTML = columnRangeHeader
      && columnRangeHeader.columnToPeriodMap
      && Object.entries(columnRangeHeader.columnToPeriodMap).length 
        ? dateInput 
        : '';

    if (columnRangeHeader && columnRangeHeader.backgroundColor) {
      (th as HTMLElement).style.backgroundColor = columnRangeHeader.backgroundColor;
      (th as HTMLElement).style.borderRight = `1px solid ${columnRangeHeader.backgroundColor}`;
    }

    const classes = b('header-column', {
      empty: !Boolean(columnRangeHeader),
      'middle-level': true,
      'marked-column': Boolean(columnRangeHeader),
      'right-separate-border': Boolean(columnRangeHeader && index === columnRangeHeader.columnRange.end),
    }).toString().split(' ');

    th.classList.add(...classes);

    const dateInputNode = th.querySelector(`.${b('date-input')}`);
    const calendarInputNode = th.querySelector(`.${b('calendar-input')}`);

    if (
      dateInputNode
      && calendarInputNode
      && columnRangeHeader
      && columnRangeHeader.columnToPeriodMap
      && columnRangeHeader.isCanEditDate
    ) {
      const picker = flatpickr(
        calendarInputNode,
        {
          onChange: this.makeChangeDate(index),
          mode: 'range',
          locale: Russian,
          noCalendar: this.props.onColumnPeriodChange === undefined,
        }
      );
      if (rangeDate) {
        picker.setDate([rangeDate.start, rangeDate.end]);
      }
      this.pickerInstances[index] = picker;
      dateInputNode.addEventListener('click', this.onClickCalendar);
    }
  }

  @autobind
  private getFormattedRangeDate(date: M.DateRange | null) {
    if (!date) {
      return '';
    }
    const startDate = new Date(date.start);
    const endDate = new Date(date.end);
    return `${flatpickr.formatDate(startDate, 'd/m/y')} - ${flatpickr.formatDate(endDate, 'd/m/y')}`;
  }

  @autobind
  private destroyPicker(index: number) {
    const picker = this.pickerInstances[index];
    if (picker) {
      picker.destroy();
      this.pickerInstances[index] = null;
    }
  }

  @autobind
  private onClickCalendar(event: any) {
    const colIndex = event.currentTarget.dataset.index;
    const picker = this.pickerInstances[colIndex];
    picker && picker.open();
  }

  @autobind
  private makeChangeDate(column: number) {
    return (dates: Date[], _: string, instance: flatpickr.Instance) => {
      const { onColumnPeriodChange, columnRangeHeaders } = this.props;
      if (dates.length === 2) {

        const [startDate, endDate] = dates;
        const header = columnRangeHeaders.find(c => column >= c.columnRange.start && column <= c.columnRange.end)!;

        onColumnPeriodChange && onColumnPeriodChange(
          header,
          {
            start: instance.formatDate(startDate, 'Y-m-d'),
            end: instance.formatDate(endDate, 'Y-m-d'),
          },
          column,
        );

      }
    };
  }

  private makeCloseIconClickHandler(header: ColumnRangeHeader) {
    return () => {
      const { onColumnRangeHeaderRemove } = this.props;
      onColumnRangeHeaderRemove && onColumnRangeHeaderRemove(header);
    };
  }

  private makeAutocorrectButtonClickHandler(header: ColumnRangeHeader) {
    return () => {
      const { onAutocorrect } = this.props;
      onAutocorrect?.(header);
    };
  }

  private makeSubTitleEditClickHandler(header: ColumnRangeHeader) {
    return () => {
      const { onColumnRangeHeaderEdit } = this.props;
      onColumnRangeHeaderEdit && onColumnRangeHeaderEdit(header);
    };
  }

  @autobind
  private getSymbolColumnsHeaders(hotInstance: Handsontable): string[] {
    const columnsNumber = hotInstance.countCols();
    return R.range(0, columnsNumber).map(x => hotInstance.getColHeader(x).toString());
  }

  @autobind
  private recalculateSize() {
    const { hotInstance } = this.props;
    if (hotInstance && !hotInstance.isDestroyed) {
      updateRowHeadersHeight(hotInstance, 'header', this.rowIndexByMaxHeightMap);
      const plugin = hotInstance.getPlugin('autoColumnSize');
      const isNeedRecalculate = plugin?.isNeedRecalculate();
      if (isNeedRecalculate) {
        plugin.recalculateAllColumnsWidth();
      }
    }
  }

}

export const Component = TableHeader;
