import * as R from 'ramda';

import { descSort } from 'shared/helpers/descSort';

function fn<T>(removeIndexes: number[], cells: T[][]) {
  return descSort(removeIndexes).reduce((acc, rowIndex) => {
    return R.remove(rowIndex, 1, acc);
  }, cells);
}

export const removeRows = R.curry(fn);
