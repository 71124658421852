import * as R from 'ramda';

export function getColumnByIndexFromMatrix<T>(cells: T[][], columnIndex: number): T[] {
  return cells.reduce((acc, row) => [...acc, row[columnIndex]], []);
}

export function removeColumnFromMatrixByIndex<T>(tableData: T[][], removeIndex: number): T[][] {
  return tableData.map(row => R.remove(removeIndex, 1, row));
}

export function insertColumn<T>(data: T[][], columnData: T[], insertIndex: number): T[][] {
  return data.map((row, rowIndex) => R.insert(insertIndex, columnData[rowIndex], row));
}

export function addColumn<T>(columnData: T[], data: T[][]): T[][] {
  return data.map((row, rowIndex) => R.append(columnData[rowIndex], row));
}
