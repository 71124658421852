import { put, call, select } from 'redux-saga/effects';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import * as M from 'shared/types/models';
import { Column, MetricColumn, TableCell, EditableMetric } from 'shared/types/models/EditMetrics';
import { loadJsonFromFile, hotTable } from 'shared/helpers';
import { IDependencies } from 'shared/types/app';

import { actionCreators as alertActionCreators } from 'services/Alert';
import { tryCatch } from 'services/ErrorTracking';

import { actionCreators, selectors } from '../../../redux';

import { withHotInstance } from '../../dependencies';

export function* loadTemplate(deps: IDependencies, { payload }: NS.LoadTemplate) {
  const { file } = payload;
  yield tryCatch({
    *successed() {
      const columns: Column[] = yield select(selectors.selectColumns);
      const cells: TableCell[][] = yield select(selectors.selectCells);
      const mediaplan: M.Mediaplan = yield select(selectors.selectMediaplan);
      const metrics: EditableMetric[] = yield select(selectors.selectMetrics);
      const archivedMetricIDs: number[] = yield select(selectors.selectArchivedMetricsIDs);
      const templateColumns: MetricColumn[] = yield call(loadJsonFromFile, file);
      const filteredTemplateColumns: MetricColumn[] = templateColumns.filter(c => !archivedMetricIDs.includes(c.metricID));

      const { diffIndexes, updatedColumns } = helpers.mergeColumnsFromTemplate(filteredTemplateColumns, columns);
      const updatedCells = helpers.insertEmptyColumnsCells(cells, diffIndexes);

      const { updatedCells: mergedPlanContentCells, changes: planChanges } = helpers.mergePlanContentToCells({
        columns: updatedColumns,
        mediaplan,
        cells: updatedCells,
        planColumns: updatedColumns.filter(x => x.kind === 'metric' && x.metricType === 'plan')
      });

      const { updatedCells: mergedRatioAndPlanCells, changes: ratioChanges } = helpers.setValuesToRatioCells({
        columns: updatedColumns,
        cells: mergedPlanContentCells,
        ratioColumns: updatedColumns.filter(x => x.kind === 'ratio'),
      });

      const completedCells = helpers.updateCellsStyles({
        columns: updatedColumns,
        cells: mergedRatioAndPlanCells,
        columnsIndexes: updatedColumns.map((_, i) => i),
        metrics,
      });

      withHotInstance(x => {
        hotTable.clearFilters(x);
        hotTable.applyFilters(x);
        hotTable.addColumns(x, diffIndexes);
        helpers.hotTable.setColumnsMeta({
          hotInstance: x,
          columnsIndexes: diffIndexes,
          columns: updatedColumns,
          getState: deps.getState,
        });
        helpers.hotTable.applyChanges({
          hotInstance: x,
          changes: [
            ...planChanges,
            ...ratioChanges,
          ],
        });
        x.clearUndo();
      });

      yield put(actionCreators.loadTemplateCompleted({ columns: updatedColumns, cells: completedCells }));

      yield put(actionCreators.loadFactData({ columnsIDs: helpers.getFactColumnsIDs(updatedColumns) }));
      yield put(alertActionCreators.openAlert({ type: 'success', message: 'Шаблон загружен успешно!' }));
    },
    *failed(_, message) {
      yield put(actionCreators.loadTemplateFailed(message));
      yield put(alertActionCreators.openAlert({ type: 'error', message: 'Ошибка обработки файла шаблона' }));
    },
  });
}
