import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import * as SM from 'shared/types/models/Server';
import { EditableMetric, Column, MetricColumn } from 'shared/types/models/EditMetrics';

import { sortMetaMetricsByColumnNum } from 'shared/helpers';

import { convertServerMetricSourceType, convertServerPeriodType } from 'services/api/resources/metric/converters';

export function initMetricsColumns(
  metaMetricsMap: Record<number, SM.MetaMetric[]>,
  metrics: EditableMetric[],
): Column[] {
  const metaMetricsEntries = sortMetaMetricsByColumnNum(Object.entries(metaMetricsMap));
  const metricColumns = metaMetricsEntries.flatMap(([metricId, metaMetrics]) => {
    const grouppedMetaMetricsMap = R.groupBy(x => `${x.periodType}-${x.sourceType}`, metaMetrics);
    return Object.values(grouppedMetaMetricsMap).flatMap(grouppedMetaMetrics => {
      const foundMetric = metrics.find(x => x.id === +metricId);
      const markupID = uuidv4();
      return grouppedMetaMetrics.map<MetricColumn>(metaMetric => {
        return {
          id: uuidv4(),
          markupID,
          title: foundMetric?.title ?? '',
          metricID: +metricId,
          kind: 'metric',
          metricType: convertServerMetricSourceType(metaMetric.sourceType!),
          subId: metaMetric.metricSubId !== undefined ? metaMetric.metricSubId : null,
          periodType: convertServerPeriodType(metaMetric.periodType),
          filterColumnIndex: null,
          columnTitle: null,
          contentIndex: metaMetric.columnNum,
          date: metaMetric.periodStart && metaMetric.periodEnd
            ? { start: metaMetric.periodStart, end: metaMetric.periodEnd }
            : null,
        };
      });
    });
  });
  // TODO: return when did markup on the back
  // const markupBudgetMetric = metrics.find(x => x.id === markupBudgetMetricId);
  // const isHasBudgetMarkupColumns = metricColumns.some(x => isComparingBudgetColumn(x));
  // if (markupBudgetMetric && isHasBudgetMarkupColumns) {
  //   const subId = markupBudgetMetric.subIds[0].subId || null;
  //   return addMarkupBudgetColumns(metricColumns, subId);
  // }
  return metricColumns;
}
