import Handsontable from 'handsontable';

type Arguments = {
  cellProperties: Handsontable.CellProperties;
  value: any;
};

export function numberValidator({ cellProperties, value }: Arguments) {

  let isNumber: boolean = true;

  const getValidStateByHTValidator = (valid: boolean) => { isNumber = valid; };

  Handsontable.validators.NumericValidator.bind(cellProperties)(value, getValidStateByHTValidator);

  return isNumber;
}
