import { combineReducers } from 'redux';
import { makeCommunicationReducer } from 'shared/helpers/redux';

import { ReducersMap } from 'shared/types/redux';
import * as NS from '../../types';

import { initialState } from '../initialState';

// tslint:disable:max-line-length

export const communicationReducer = combineReducers<NS.ReduxState['communication']>({

  loadingMediaplan: makeCommunicationReducer<NS.LoadMediaplan, NS.LoadMediaplanCompleted, NS.LoadMediaplanFailed>(
    'EDIT_METRICS:LOAD_MEDIAPLAN',
    'EDIT_METRICS:LOAD_MEDIAPLAN_COMPLETE',
    'EDIT_METRICS:LOAD_MEDIAPLAN_FAILED',
    initialState.communication.loadingMediaplan,
  ),

  loadingAttributes: makeCommunicationReducer<NS.LoadAttributes, NS.LoadAttributesCompleted, NS.LoadAttributesFailed>(
    'EDIT_METRICS:LOAD_ATTRIBUTES',
    'EDIT_METRICS:LOAD_ATTRIBUTES_COMPLETE',
    'EDIT_METRICS:LOAD_ATTRIBUTES_FAILED',
    initialState.communication.loadingAttributes,
  ),

  loadingMetrics: makeCommunicationReducer<NS.LoadMetrics, NS.LoadMetricsCompleted, NS.LoadMetricsFailed>(
    'EDIT_METRICS:LOAD_METRICS',
    'EDIT_METRICS:LOAD_METRICS_COMPLETE',
    'EDIT_METRICS:LOAD_METRICS_FAILED',
    initialState.communication.loadingMetrics,
  ),

  savingMediaplan: makeCommunicationReducer<NS.SaveMediaplan, NS.SaveMediaplanCompleted, NS.SaveMediaplanFailed>(
    'EDIT_METRICS:SAVE_MEDIAPLAN',
    'EDIT_METRICS:SAVE_MEDIAPLAN_COMPLETE',
    'EDIT_METRICS:SAVE_MEDIAPLAN_FAILED',
    initialState.communication.savingMediaplan,
  ),

  loadingTemplate: makeCommunicationReducer<NS.LoadTemplate, NS.LoadTemplateCompleted, NS.LoadTemplateFailed>(
    'EDIT_METRICS:LOAD_TEMPLATE',
    'EDIT_METRICS:LOAD_TEMPLATE_COMPLETE',
    'EDIT_METRICS:LOAD_TEMPLATE_FAILED',
    initialState.communication.loadingTemplate,
  ),

  addingMetric: makeCommunicationReducer<NS.AddMetric, NS.AddMetricCompleted, NS.AddMetricFailed>(
    'EDIT_METRICS:ADD_METRIC',
    'EDIT_METRICS:ADD_METRIC_COMPLETE',
    'EDIT_METRICS:ADD_METRIC_FAILED',
    initialState.communication.addingMetric,
  ),

  initializingTable: makeCommunicationReducer<NS.InitializeTable, NS.InitializeTableCompleted, NS.InitializeTableFailed>(
    'EDIT_METRICS:INITIALIZE_TABLE',
    'EDIT_METRICS:INITIALIZE_TABLE_COMPLETE',
    'EDIT_METRICS:INITIALIZE_TABLE_FAILED',
    initialState.communication.initializingTable,
  ),

  loadingCabinets: makeCommunicationReducer<NS.LoadCabinets, NS.LoadCabinetsCompleted, NS.LoadCabinetsFailed>(
    'EDIT_METRICS:LOAD_CABINETS',
    'EDIT_METRICS:LOAD_CABINETS_COMPLETE',
    'EDIT_METRICS:LOAD_CABINETS_FAILED',
    initialState.communication.loadingCabinets,
  ),

  loadingCalendarPeriods: makeCommunicationReducer<NS.LoadCalendarPeriods, NS.LoadCalendarPeriodsCompleted, NS.LoadCalendarPeriodsFailed>(
    'EDIT_METRICS:LOAD_CALENDAR_PERIODS',
    'EDIT_METRICS:LOAD_CALENDAR_PERIODS_COMPLETE',
    'EDIT_METRICS:LOAD_CALENDAR_PERIODS_FAILED',
    initialState.communication.loadingCalendarPeriods,
  ),

  editingMetric: makeCommunicationReducer<NS.EditMetric, NS.EditMetricCompleted, NS.EditMetricFailed>(
    'EDIT_METRICS:EDIT_METRIC',
    'EDIT_METRICS:EDIT_METRIC_COMPLETE',
    'EDIT_METRICS:EDIT_METRIC_FAILED',
    initialState.communication.editingMetric,
  ),

} as ReducersMap<NS.ReduxState['communication']>);
