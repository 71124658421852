import * as React from 'react';

import { block } from 'bem-cn';

import { Tooltip as MUITooltip, TooltipProps } from '@material-ui/core';

import './style.scss';

const b = block('tooltip');

type OwnProps = {
  children: JSX.Element;
} & TooltipProps;

class Tooltip extends React.PureComponent<OwnProps> {

  public render() {
    const { children, ...props } = this.props;
    return (
      <MUITooltip
        classes={{ 
          tooltip: b().toString(),
          popper: b('popper').toString(),
        }}
        {...props}
      >
        {children}
      </MUITooltip>
    );
  }
}

export default Tooltip;
