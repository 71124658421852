import * as R from 'ramda';

import { MetaMetric } from 'shared/types/models/Server';

export function sortMetaMetricsByColumnNum(entries: [string, MetaMetric[]][]) {
  const xHeadLens = R.lensPath([1, 0, 'columnNum']);
  return R.sort((a, b) => {
    const x = R.view<[string, MetaMetric[]], number>(xHeadLens, a);
    const y = R.view<[string, MetaMetric[]], number>(xHeadLens, b);
    return x - y;
  }, entries);
}
