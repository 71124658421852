import Handsontable from 'handsontable';
import { BaseRenderer } from 'handsontable/renderers';

import { IAppReduxState } from 'shared/types/app';
import { selectCells } from 'features/EditMetrics/redux/selectors';

export function makeCellRenderer(
  renderer: BaseRenderer,
  getState: () => IAppReduxState,
): BaseRenderer {
  return (
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: Handsontable.CellValue,
    cellProperties: Handsontable.CellProperties
  ): HTMLTableCellElement | void => {
    const cells = selectCells(getState());
    const rowIndex = instance.toPhysicalRow(row);
    const cell = cells[rowIndex][col];

    if (cell) {
      const { style } = cell;
      Object.assign(td.style, style);
    }

    return renderer(instance, td, row, col, prop, value, cellProperties);
  };
}
