import { put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { TableCell, Column } from 'shared/types/models/EditMetrics';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import { actionCreators, selectors } from '../../../redux';
import { getHotInstance } from '../../dependencies';

export function* applyChanges(deps: IDependencies, { payload }: NS.ApplyChanges) {
  const { changes } = payload;
  const hotInstance = getHotInstance();

  if (!hotInstance) {
    return;
  }

  const cells: TableCell[][] = yield select(selectors.selectCells);
  const columns: Column[] = yield select(selectors.selectColumns);

  const ratioChanges = helpers.makeRelatedRatioChanges(columns, changes, cells);
  // TODO: return when did markup on the back
  // const markupBudgetChanges = helpers.makeRelatedMarkupBudgetChanges(columns, changes, cells);
  const allChanges = [...changes, ...ratioChanges];
  const newCells = helpers.applyChangesToCells(allChanges, cells);

  yield put(actionCreators.setCells({ cells: newCells }));

  helpers.hotTable.applyChanges({
    hotInstance,
    changes: helpers.updateRowsInChanges(row => hotInstance.toVisualRow(row), allChanges),
  });
}
