import { call, select, put, actionChannel, take } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { Column, EditableMetric } from 'shared/types/models/EditMetrics';

import { actionCreators as alertActionCreators } from 'services/Alert';
import { tryCatch } from 'services/ErrorTracking';

import * as helpers from '../../../helpers';

import { notRelevantFoundColumnsForMerge } from '../../../constants';
import { LoadFactData } from '../../../types';
import { withHotInstance } from '../../dependencies';

import { selectors, actionCreators } from '../..';

import { mergeFactToCells } from '../helperSagas';

const loadFactDataType: LoadFactData['type'] = 'EDIT_METRICS:LOAD_FACT_DATA';

export function* watchLoadFactData(deps: IDependencies) {
  const channel = yield actionChannel(loadFactDataType);
  while (true) {
    const action = yield take(channel);
    yield call(loadFactData, deps, action);
  }
}

export function* loadFactData(deps: IDependencies, { payload }: LoadFactData) {
  const { columnsIDs } = payload;
  yield tryCatch({
    *successed() {
      yield put(actionCreators.addLoadingFactColumnsIDs({ ids: columnsIDs }));

      const columns: Column[] = yield select(selectors.selectColumns);
      const metrics: EditableMetric[] = yield select(selectors.selectMetrics);

      const columnsForMerge = columns.filter(x => columnsIDs.includes(x.id));

      const { cells: updatedCells, changes } = yield call(mergeFactToCells, { deps, metrics, columnsForMerge });

      yield put(actionCreators.setCells({ cells: updatedCells }));
      yield put(actionCreators.removeLoadingFactColumnsIDs({ ids: columnsIDs }));

      yield call(withHotInstance, (x) => {
        helpers.hotTable.applyChanges({ hotInstance: x, changes, isSetToSourceData: true });
      });
      yield put(alertActionCreators.openAlert({ message: 'Фактические данные успешно получены', type: 'success' }));
    },
    *failed(_, errorMsg) {
      if (errorMsg === notRelevantFoundColumnsForMerge) {
        yield put(alertActionCreators.openAlert({
          message: 'Не найдены релевантные метрики для добавления фактических данных',
          type: 'error',
        }));
      }
      yield put(actionCreators.removeLoadingFactColumnsIDs({ ids: columnsIDs }));
    },
  });
}
