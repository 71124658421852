import { call, put, select } from 'redux-saga/effects';

import { CommonData } from 'services';
import { tryCatch } from 'services/ErrorTracking';
import { IDependencies } from 'shared/types/app';
import { actionCreators } from '../../../redux';
import * as A from '../../../types/actions';

export function* applyTemplate({ api }: IDependencies, { payload: { templateId, type } }: A.ApplyTemplate) {
  yield tryCatch({
    *successed() {
      const mediaplanVersionId: number | null = yield select(CommonData.selectors.selectMediaplanVersionID);
      const mediaplanId: number | null = yield select(CommonData.selectors.selectMediaplanID);

      if (!mediaplanId || !mediaplanVersionId) {
        throw Error('mediaplanID or mediaplanVersionID not found');
      }

      yield call(api.template.applyTemplate, { mediaplanId, mediaplanVersionId, templateId, type });
      yield put(actionCreators.applyTemplateSuccess());
    },
    *failed(error, message) {
      yield put(actionCreators.applyTemplateFail(message));
    }
  });
}
