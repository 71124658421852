import * as M from 'shared/types/models';

export function isDictionaryCell(cell: M.ExportFactAMP.Cell): cell is M.ExportFactAMP.DictionaryCell {
  return cell.kind === 'dictionary';
}

export function isStandardCell(cell: M.ExportFactAMP.Cell): cell is M.ExportFactAMP.StandardCell {
  return cell.kind === 'standard';
}

export function isDictionaryColumn(column: M.ExportFactAMP.Column): column is M.ExportFactAMP.AttributeColumn {
  return column.kind === 'attribute' && column.valueType === 'dictionary';
}

export function isMetricColumn(column: M.ExportFactAMP.Column): column is M.ExportFactAMP.MetricColumn {
  return column.kind === 'metric'
}

export function isCheckboxColumn(column: M.ExportFactAMP.Column): column is M.ExportFactAMP.CheckboxColumn {
  return column.kind === 'checkbox'
}
