import { all, takeLatest } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import * as actionSagas from './actionSagas';
import * as loadingSagas from './loadingSagas';
import * as NS from '../../types';

const loadTemplatesType: NS.LoadTemplates['type'] = 'TEMPLATES_SERVICE:LOAD_TEMPLATES';
const loadDictionariesType: NS.LoadDictionaries['type'] = 'TEMPLATES_SERVICE:LOAD_DICTIONARIES';
const loadBrandDictionaryType: NS.LoadBrandDictionary['type'] = 'TEMPLATES_SERVICE:LOAD_BRAND_DICTIONARY';
const loadTemplateType: NS.LoadTemplate['type'] = 'TEMPLATES_SERVICE:LOAD_TEMPLATE';
const applyTemplateType: NS.ApplyTemplate['type'] = 'TEMPLATES_SERVICE:APPLY_TEMPLATE';
const deleteTemplateType: NS.DeleteTemplate['type'] = 'TEMPLATES_SERVICE:DELETE_TEMPLATE';
const saveTemplateType: NS.SaveTemplate['type'] = 'TEMPLATES_SERVICE:SAVE_TEMPLATE';

export function* rootSaga(deps: IDependencies) {
  yield all([
    takeLatest(loadTemplatesType, loadingSagas.loadTemplates, deps),
    takeLatest(loadDictionariesType, loadingSagas.loadDictionaries, deps),
    takeLatest(loadBrandDictionaryType, loadingSagas.loadBrandDictionary, deps),
    takeLatest(loadTemplateType, loadingSagas.loadTemplate, deps),
    takeLatest(applyTemplateType, actionSagas.applyTemplate, deps),
    takeLatest(deleteTemplateType, actionSagas.deleteTemplate, deps),
    takeLatest(saveTemplateType, actionSagas.saveTemplate, deps),
  ]);
}
