import * as R from 'ramda';

import { put, call } from 'redux-saga/effects';
import { IDependencies } from 'shared/types/app';

import { tryCatch } from 'services/ErrorTracking';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import { actionCreators } from '../../../redux';
import { withHotInstance } from '../../dependencies';

export function* initTable(deps: IDependencies, { payload }: NS.InitializeTable) {
  const { attributes, mediaplan, metrics } = payload;
  yield tryCatch({
    *successed() {
      const columns = helpers.initializeColumns({ metrics, attributes, mediaplan });
      const cells = helpers.initializeCells({ columns, mediaplan, metrics, getState: deps.getState });

      const { updatedCells: mergedPlanContentCells, changes: planChanges } = helpers.mergePlanContentToCells({
        columns,
        mediaplan,
        cells,
        planColumns: columns.filter(x => x.kind === 'metric' && x.metricType === 'plan')
      });

      const { updatedCells: mergedRatioAndPlanCells, changes: ratioChanges } = helpers.setValuesToRatioCells({
        columns,
        cells: mergedPlanContentCells,
        ratioColumns: columns.filter(x => x.kind === 'ratio'),
      });

      const completedCells = helpers.updateCellsStyles({
        columns,
        cells: mergedRatioAndPlanCells,
        columnsIndexes: columns.map((_, i) => i),
        metrics,
      });

      yield put(actionCreators.initTableCompleted({ columns, cells: completedCells }));

      withHotInstance(x => {
        helpers.hotTable.initColumnsMeta({
          columns,
          getState: deps.getState,
          hotInstance: x,
          rowsIndexes: R.range(0, completedCells.length),
        });
        helpers.hotTable.applyChanges({
          hotInstance: x,
          changes: [
            ...planChanges,
            ...ratioChanges,
          ],
        });
      });

      yield call(deps.api.markPageAsInitialized);
      yield put(actionCreators.loadFactData({ columnsIDs: helpers.getFactColumnsIDs(columns) }));
    },
    *failed(_, message) {
      yield put(actionCreators.initTableFailed(message));
    },
  });
}
