import { IAppReduxState } from 'shared/types/app';
import { TypeAlert } from '../types';

export function selectShow(state: IAppReduxState): boolean {
  return state.Alert.isShow;
}

export function selectMessage(state: IAppReduxState): string {
  return state.Alert.message;
}

export function selectType(state: IAppReduxState): TypeAlert | null {
  return state.Alert.type;
}
