import * as TS from '../types';
import { initialState } from './initialState';

export function reducer(
  state: TS.ReduxState = initialState,
  action: TS.ServiceAction
): TS.ReduxState {
  switch (action.type) {
    case 'WARNING_SERVICE:CLOSE_MODAL':
      return { isOpen: false, message: '' };

    case 'WARNING_SERVICE:OPEN_MODAL':
      return { isOpen: true, message: action.payload.message };

    default:
      return state;
  }
}
