import * as React from 'react';
import block from 'bem-cn';

import './style.scss';
import { Card, CardContent, CardHeader, List, ListItem, ListItemText } from '@material-ui/core';

const b = block('colors-description');

type Item = {
  color: string;
  description: string;
  icon?: JSX.Element;
  isAutocorrect?: boolean;
};

type Props = {
  items: Item[];
};

function ColorsDescription({ items }: Props) {
  return (
    <Card className={b()}>
      <CardHeader classes={{ root: b('card-header').toString() }} title="Расшифровка" />
      <CardContent classes={{ root: b('card-content').toString() }}>
        <List dense disablePadding>
          {items.map(({ description, color, icon = false, isAutocorrect = false }) => (
            <ListItem
              classes={{ root: b('card-content-item').toString() }}
              key={description}
              disableGutters
            >
              <div
                className={b('square', { autocorrect: isAutocorrect }).toString()}
                style={{ backgroundColor: color }}
              >
                {icon}
              </div>
              <ListItemText primary={description} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

export { ColorsDescription };
