import Handsontable from 'handsontable';

import numbro from 'numbro';

export const withoutRoundingFormatOptions = {
  optionalMantissa: false,
  mantissa: 12,
  trimMantissa: true,
  thousandSeparated: true,
};

export class WithoutRoundingEditor extends Handsontable.editors.TextEditor {

  public prepare(
    row: number,
    col: number,
    prop: number,
    td: HTMLTableCellElement,
    originalValue: any,
    cellProperties: Handsontable.CellProperties,
  ) {
    const value = originalValue !== null && cellProperties.valid !== false
      ? numbro(originalValue).format(withoutRoundingFormatOptions)
      : originalValue;
    super.prepare(row, col, prop, td, value, cellProperties);
  }

  public setValue(value: any) {
    super.setValue(value !== null && typeof value === 'string' ? value.replace(/,/gi, '') : value);
  }
}