import * as A from '../types/actions';

export function openModal(payload?: A.OpenModal['payload']): A.OpenModal {
  const defaultMessage = 'Возникла ошибка, приносим свои извинения';
  return { type: 'RELOAD_PAGE_SERVICE:OPEN_MODAL', payload: payload ? payload : { message: defaultMessage } };
}

export function closeModal(): A.CloseModal {
  return { type: 'RELOAD_PAGE_SERVICE:CLOSE_MODAL' };
}

