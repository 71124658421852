import Handsontable from 'handsontable';

import { NestedHeader } from './models';

type ExtendedHandsontable = Handsontable & { originalGetSettings?: () => Handsontable.GridSettings };

export function dangerouslyUpdateNestedHeaders(ht: ExtendedHandsontable, nestedHeaders: NestedHeader[][]) {
  // update nested headers without call update settings of HT, potentially unreliable
  const plugin = ht.getPlugin('nestedHeaders');
  if (!ht.originalGetSettings) {
    ht.originalGetSettings = ht.getSettings;
    const tableMeta = ht.getSettings();
    tableMeta.nestedHeaders = nestedHeaders;
    plugin.enablePlugin();
  }
  ht.getSettings = makeGetSettings(nestedHeaders).bind(ht);
  plugin.updatePlugin();
}

function makeGetSettings(nestedHeaders: NestedHeader[][]) {
  return function getSettings(this: ExtendedHandsontable) {
    const tableMeta = this.originalGetSettings ? this.originalGetSettings() : {};
    tableMeta.nestedHeaders = nestedHeaders;
    return tableMeta;
  };
}
