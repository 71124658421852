import { autobind } from 'core-decorators';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import BaseApi from '../../BaseApi';

class PageSettings extends BaseApi {
  @autobind
  public async getMetadata(pageId: M.PageId): Promise<M.PageSettingsMetadata[]> {
    const response = await this.actions.get<SM.PageSettingsMetadata[]>({
      url: '/page-settings/metadata',
      options: { headers: this.authHeaders },
      data: { pageId },
    });
    return this.handleResponse(response);
  }

  @autobind
  public async getCategories(pageId: M.PageId): Promise<M.PageSettingsCategory[]> {
    const response = await this.actions.get<M.PageSettingsCategory[]>({
      url: '/page-settings/metadata/categories',
      options: { headers: this.authHeaders },
      data: { pageId },
    });
    return this.handleResponse(response);
  }
}

export default PageSettings;
