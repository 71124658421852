export function isStartWithCyrillic(input: string) {
  return /[а-яА-ЯЁё]/.test(input[0]);
}

export function isStartWithLatin(input: string) {
  return /[a-zA-Z]/.test(input[0]);
}

export function isStartWithNumber(input: string) {
  return /^\d/.test(input);
}
