import { parse, format } from 'date-fns';

import * as SM from 'shared/types/models/Server';

import * as constants from '../constants';

export const getClientPeriodDate = (date: string) => {
  const parsed = parse(date, constants.serverDateFormat, new Date());
  return format(parsed, constants.clientPeriodDateFormat);
};

export function getTimelineCustomPeriodsValue(value: SM.TimelineCustomPeriodContent) {
  return `${getClientPeriodDate(value.periodStart)} - ${getClientPeriodDate(value.periodEnd)}`;
}

export const getServerTimelineCell = (dateOrPeriod: string | null): SM.TimelineContent | null => {
  const dateOrPeriodStr = dateOrPeriod || '';
  const parts = dateOrPeriodStr.split('-');

  switch (parts.length) {
    case 1:
      return { type: 'timeline', fullPeriod: true };
    case 2:
      const [periodStart, periodEnd] = parts
        .map(x => x.trim())
        .map(x => format(parse(x, constants.clientPeriodDateFormat, new Date()), constants.serverDateFormat));
      return { type: 'timeline', periodStart, periodEnd };
    default:
      return null;
  }
}
