import { F } from 'ts-toolbelt';
import { call, put, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { ReturnPromisedType } from 'shared/types/utils';
import { CommonData } from 'services';
import { tryCatch } from 'services/ErrorTracking';
import * as actionCreators from '../../actionCreators';

export function* loadMediaplan({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const mediaplanID: number | null = yield select(CommonData.selectors.selectMediaplanID);
      const mediaplanVersionID: number | null = yield select(CommonData.selectors.selectMediaplanVersionID);

      if (!mediaplanID || !mediaplanVersionID) {
        yield put(actionCreators.loadMediaplanFailed('mediaplanID or mediaplanVersionID is null'));
        return;
      }

      const args: F.Parameters<typeof api.mediaplan.getMediaplan>[0] = {
        mediaplanID,
        mediaplanVersionID,
      };

      const mediaplan: ReturnPromisedType<typeof api.mediaplan.getMediaplan> =
        yield call(api.mediaplan.getMediaplan, args);


      yield put(actionCreators.loadMediaplanCompleted(mediaplan));
    },
    *failed(_, message) {
      yield put(actionCreators.loadMediaplanFailed(message));
    },
  });
}
