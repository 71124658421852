import * as M from './model'

/* eslint-disable prefer-promise-reject-errors */
/* https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html */

export const makeCancelable = <T>(promise: Promise<T>): M.ICancellablePromise<T> => {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};
