import { IDependencies } from 'shared/types/app';
import { Api } from 'services/api';
import { makeHandleServerError } from 'services/api/interceptors/handleServerError';

import { store } from './store';
import { makeHandleNetworkError } from 'services/api/interceptors/handleNetworkError';
import { makeHandleTokenExpired } from 'services/api/interceptors/handleTokenExpired';
import { ErrorTracking } from 'services/ErrorTracking';
import { makeHandleCustomServerErrors } from 'services/api/interceptors/handleCustomServerErrors';

export function configureDeps(): IDependencies {
  const api = Api.getInstance();
  const errorTracking = ErrorTracking.getInstance();
  const getDispatch = () => store.dispatch;
  const getState = () => store.getState();
  const handleNetworkError = makeHandleNetworkError(getDispatch, api.checkPageIsInitialized);
  const handleTokenExpired = makeHandleTokenExpired(api.auth);

  api.setNetworkErrorHandler(handleNetworkError);
  api.setTokenExpiredInterceptor(handleTokenExpired);

  const serverErrorInterceptor = makeHandleServerError(handleNetworkError);
  const customServerErrorInterceptor = makeHandleCustomServerErrors(getDispatch);

  api.addErrorInterceptor(serverErrorInterceptor);
  api.addErrorInterceptor(customServerErrorInterceptor);

  return { api, errorTracking, getDispatch, getState };
}
