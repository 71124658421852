import * as React from 'react';

import { bind } from 'decko';
import { block } from 'bem-cn';

import { OutlinedInput } from '@material-ui/core';
import './style.scss';

const b = block('file-input');

interface IOwnProps {
  onChange(file: File): void;
  placeholder?: string;
}

class FileInput extends React.Component<IOwnProps> {

  render() {
    const { placeholder } = this.props;
    return (
      <div className={b()}>
        <OutlinedInput
          type="file"
          placeholder={placeholder}
          onChange={this.onChange}
        />
      </div>
    );
  }

  @bind
  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    const { currentTarget: { files } } = event;

    if (files) {
      const [file] = Array.from(files);
      onChange(file);
    }
  }
}

export { FileInput };
