import * as R from 'ramda';

import * as M from 'shared/types/models';
import { normalizeContent } from 'shared/helpers/cell/cell';

export function getTextByDictionaryID(dictionaries: M.DictionarySubject[], dictionaryId: number | null) {
  if (dictionaryId === null) {
    console.info('amp content has null values in dictionary attributes!');
    return null;
  }
  const dictionary = dictionaries.find(x => x.id === dictionaryId);
  return dictionary ? dictionary.text : null;
}

export function getDictionaryByID(dictionaries: M.DictionarySubject[], dictionaryId: number | null) {
  const dictionary = dictionaries.find(x => x.id === dictionaryId);
  return dictionary ?? null;
}

export function getDictionaryIDByContent(dictionaries: M.DictionarySubject[], text: string | null) {
  const dictionary = getDictionaryByContent(dictionaries, text);
  return dictionary ? dictionary.id : null;
}

export function getDictionaryByContent(dictionaries: M.DictionarySubject[], content: M.CellChange['content']) {
  if (!content || typeof content === 'number' || typeof content === 'boolean') {
    return null;
  }
  const dictionary = dictionaries.find(x => normalizeContent(x.text) === normalizeContent(content));
  return dictionary ? dictionary : null;
}

export function getDictionaryByChange(
  dictionaries: M.DictionarySubject[],
  change: M.ExportAMP.CellChange,
  filteredDictionaryIDs: number[],
) {
  const { content } = change;
  // additional filtering by available IDs for duplicate dictionary values
  const sortedDictionaries = R.sort(
    (a, b) => Number(filteredDictionaryIDs.includes(b.id)) - Number(filteredDictionaryIDs.includes(a.id)),
    dictionaries,
  );

  return getDictionaryByContent(sortedDictionaries, content);
}
