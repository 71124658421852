import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';

export function convertInvalidRows(data: SM.ExportCabinetValidationResults): M.ExportCabinet.ValidateResponseCoords[] {
  return data.validationResults.map(x => ({
    rowID: x.rowId,
    dictIDs: [
      ...x.invalidDependencies.map(dep => dep.dictionaryIdSrc),
      ...x.invalidDependencies.map(dep => dep.dictionaryIdDest),
    ],
  }));
}
