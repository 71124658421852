import Handsontable from 'handsontable';

import { Column } from 'shared/types/models/EditMetrics';
import {
  getInvalidCellsCoordinates, checkHasInvalidFilteredCells, getFormattedCoordinates,
} from 'shared/helpers';

import { getMetricColumnsIndexes } from '../columns';

export function validateTable(ht: Handsontable, columns: Column[]): string {
  const columnsIndexes = getMetricColumnsIndexes(columns);
  const errorMessages: string[] = [];

  const isHasInvalidFilteredCellss = checkHasInvalidFilteredCells(ht, columnsIndexes);
  if (isHasInvalidFilteredCellss) {
    errorMessages.push('В отфильтрованных ячейках имееются невалидные данные');
  }

  const invalidCellsCoordinates = getInvalidCellsCoordinates(ht, columnsIndexes);

  if (invalidCellsCoordinates.length) {
    const formattedInvalidCells = getFormattedCoordinates(ht, invalidCellsCoordinates);
    errorMessages.push(`Таблица содержит некорректные данные в ячейках: ${formattedInvalidCells}`);
  }
  return errorMessages.join('<br /><br />');
}