import * as M from 'shared/types/models';

import { getUnitTypeColumnIndex } from './getUnitTypeColumnIndex';
import { getUnitVolumeColumnIndexes } from './getUnitVolumeColumnIndexes';

export function getUnitVolumeRoundingChanges<T>(
  checkIsUnitVolumeColumn: (column: T) => boolean,
  checkIsUnitTypeColumn: (column: T) => boolean,
  columns: T[],
  rowsIndexes: number[],
  cells: M.AbstractCell[][]
): M.CellRoundingChange[] {
  const unitVolumeColIndexes = getUnitVolumeColumnIndexes(checkIsUnitVolumeColumn, columns);
  const unitTypeColIndex = getUnitTypeColumnIndex(checkIsUnitTypeColumn, columns);

  if (!unitVolumeColIndexes.length || unitTypeColIndex === null) {
    return [];
  }

  const changes = rowsIndexes.reduce<M.CellRoundingChange[]>((acc, rowIndex) => {
    const unitTypeCell = cells[rowIndex][unitTypeColIndex];
    if (M.typeGuards.isCellWithRounding(unitTypeCell)) {
      const changes = unitVolumeColIndexes.map<M.CellRoundingChange>(unitVolumeColIndex => ({
        coordinate: { row: rowIndex, column: unitVolumeColIndex },
        decimalSigns: unitTypeCell.decimalSigns ?? null,
      }))
      return [...acc, ...changes];
    }
    return acc;
  }, []);

  return changes;
}
