import * as R from 'ramda';

import * as M from 'shared/types/models';
import * as SM from 'shared/types/models/Server';
import {
  MapFactSubIdData, MetricType, EditableMetric, FactMetricType,
  MetricSubId, MetricGroup, Column, MetricColumn, CellContent,
} from 'shared/types/models/EditMetrics';

import { BudgetMayExceedPlanType } from 'features/EditMetrics/types';

import { convertServerSourceType, convertServerValueType } from '../../helpers/converters';

export function convertFactSubIdsData(
  factParams: SM.FactParams[],
  content: SM.GeneralCell[][]
): MapFactSubIdData {
  let offset = 0;
  return factParams.reduce<MapFactSubIdData>((acc, { subId, dates, metricId }) => {
    const amountColumns = dates.length || 1;
    const data = content.map(([rowId, ...row]) => ({
      rowId,
      content: row.slice(offset, offset + amountColumns),
    }));
    offset += amountColumns;
    return { ...acc, [subId]: { rows: data, metricId } };
  }, {});
}

export function convertClientMetricSourceType(type: MetricType): SM.ServerMetricSourceType {
  const typeMap: Record<MetricType, SM.ServerMetricSourceType> = {
    plan: 'PLAN',
    adserving: 'ADSERVING',
    publisher: 'PUBLISHER',
  };
  return typeMap[type];
}

export function convertEditableMetric(metric: SM.ServerEditableMetric): EditableMetric {
  const { id, isPlan, sourceType, title } = metric;
  const subIds = (() => {
    if (metric.factMetricSubIds) {

      const mapMetricSubTypes: Record<SM.ServerMetricFactSourceType, FactMetricType> = {
        ADSERVING: 'adserving',
        PUBLISHER: 'publisher'
      };

      return R.toPairs(metric.factMetricSubIds).map<MetricSubId>(([type, subIdsList]) => {
        return { subId: subIdsList[0].metricSubId, type: mapMetricSubTypes[type] };
      });
    }
    return [];
  })();
  return {
    id,
    isPlan,
    sourceType: convertServerMetricSourceType(sourceType),
    title,
    description: metric.description ? metric.description : null,
    subIds: metric.factMetricSubIds ? subIds : [],
    cabinetId: metric.cabinetId,
    isPlanEditable: metric.isPlanEditable,
    isFactEditable: metric.isFactEditable,
    isBudgetMetric: metric.isEditDataPageBudget,
    isCustom: metric.isCustom,
    isCustomArchived: metric.isCustomArchived,
    metricGroups: convertMetricsGroups(metric),
  };
}

export function convertMetricsGroups(metric: SM.ServerEditableMetric): MetricGroup[] {
  const map: Record<number, MetricGroup> = {
    1: 'budget',
    2: 'markupPublisherBudget',
    3: 'markupAdservingBudget',
  }

  if (metric.metricGroups) {
    return metric.metricGroups.map(group => map[group]);
  }

  return [];
}

export function convertServerMetricSourceType(type: SM.ServerMetricSourceType): MetricType {
  const typeMap: Record<SM.ServerMetricSourceType, MetricType> = {
    PLAN: 'plan',
    ADSERVING: 'adserving',
    PUBLISHER: 'publisher',
  };
  return typeMap[type];
}

export function convertEditableMetrics(columns: Column[]): Record<SM.MetricId, SM.ServerMetricSaveData[]> {
  const metricsColums = (columns.filter(x => x.kind === 'metric') as MetricColumn[]);
  const grouppedColumnsMap = R.groupBy(([_, x]) => String(x.metricID), Object.entries(metricsColums));
  const pairsMeta = R.toPairs(grouppedColumnsMap).map(([metricId, grouppedColumns]) => {
    const meta = grouppedColumns.map<SM.ServerMetricSaveData>(([index, column]) => {
      return {
        valueType: 'DOUBLE',
        periodType: convertClientPeriodType(column.periodType),
        periodStart: column.date ? column.date.start : undefined,
        periodEnd: column.date ? column.date.end : undefined,
        columnNum: +index + 1,
        metricSubId: column.subId !== null ? column.subId : undefined,
        sourceType: convertClientMetricSourceType(column.metricType),
      };
    });
    return [+metricId, meta];
  }) as [number, SM.ServerMetricSaveData[]][];
  return R.fromPairs(pairsMeta);
}

export function convertClientPeriodType(periodType: M.PeriodType) {
  const periodsMap: Record<M.PeriodType, SM.ServerPeriodType> = {
    week: 'WEEK',
    custom: 'CUSTOM',
    month: 'MONTH',
    none: 'NONE',
    year: 'YEAR',
  };
  return periodsMap[periodType];
}

export function convertServerPeriodType(periodType: SM.ServerPeriodType) {
  const periodsMap: Record<SM.ServerPeriodType, M.PeriodType> = {
    WEEK: 'week',
    CUSTOM: 'custom',
    MONTH: 'month',
    NONE: 'none',
    YEAR: 'year',
  };
  return periodsMap[periodType];
}

export function convertClientBudgetExceedParameter(type: BudgetMayExceedPlanType): boolean | null {
  const map: Record<BudgetMayExceedPlanType, boolean | null> = {
    cannotExceed: true,
    mayExceed: false,
    setByLine: null,
  };
  return map[type];
}

export function convertEditabaleMetricsContent(content: CellContent[][]) {
  return content.map(row => {
    return row.map(value => {
      if (typeof value === 'boolean') {
        return Boolean(value) ? 1 : null;
      }
      return value;
    });
  });
}

export function convertServerMetric(metric: SM.ServerMetric): M.Metric {
  const {
    description,
    title,
    isRequired,
    isExportAMPRequired,
    isExportAMP,
    id,
    sourceType,
    valueType,
    titleAmp,
    isVisibleOnExtractDataPage,
    isCustom,
    isCustomArchived,
    isMarkupByPeriodsAllowed,
    isMarkupTotalAndByPeriodsAllowed,
    isBoundToMediaplan,
    relParamId,
  } = metric;
  return {
    kind: 'metric',
    id,
    title,
    description,
    isRequired,
    isVisibleOnExtractDataPage,
    isExportAMPRequired,
    isExportAMP,
    isCustom,
    isCustomArchived,
    isMarkupByPeriodsAllowed,
    isMarkupTotalAndByPeriodsAllowed,
    isBoundToMediaplan: Boolean(isBoundToMediaplan),
    titleAmp,
    sourceType: convertServerSourceType(sourceType),
    valueType: valueType && convertServerValueType(valueType),
    metricParamId: metric.metricParamId ? metric.metricParamId : null,
    decimalSigns: metric.roundDecimalSigns !== undefined ? metric.roundDecimalSigns : null,
    relToAmpMetricID: relParamId,
  };
}

