import * as R from 'ramda';

import * as M from 'shared/types/models';

export function applyCellContentChanges
  <T extends M.AbstractCell<T['content']>>(changes: M.CellContentChange<M.StandardContent>[], cells: T[][]): T[][] {
  return changes.reduce(
    (acc: T[][], { coordinate: { row, column }, content, isAutocorrected }: M.CellContentChange<M.StandardContent>): T[][] =>
      R.over(
        R.lensPath([row, column]),
        (cell: T): T => ({ ...cell, content, isAutocorrected }),
        acc,
      ),
    cells,
  );
}
