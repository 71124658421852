import { Dispatch } from 'redux';
import { actionCreators as reloadPageActionCreators } from 'services/ReloadPage/redux';
import { actionCreators as AlertActionCreators } from 'services/Alert/redux';

function makeHandleNetworkError(getDispatch: () => Dispatch<any>, checkPageIsInitialized: () => boolean) {
  return () => {
    const dispatch = getDispatch();
    const message = 'Произошла ошибка, повторите действие еще раз';
    if (checkPageIsInitialized()) {
      dispatch(AlertActionCreators.openAlert({ type: 'error', message }));
    } else {
      dispatch(reloadPageActionCreators.openModal());
    }
  };
}

export { makeHandleNetworkError };
