import { takeLatest } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import { loadAmpCampaigns } from './loadAmpCampaigns';
import { showAmpCampaigns } from './showAmpCampaigns';
import * as NS from '../../types';

const loadAmpCampaignsType: NS.LoadAmpCampaigns['type'] = 'AMP_CAMPAIGNS_SERVICE:LOAD_AMP_CAMPAIGNS';
const showAmpCampaignsModalType: NS.ShowAmpCampaignsModal['type'] = 'AMP_CAMPAIGNS_SERVICE:OPEN_AMP_CAMPAIGNS_MODAL';

export function* rootSaga(deps: IDependencies) {
  yield takeLatest(loadAmpCampaignsType, loadAmpCampaigns, deps);
  yield takeLatest(showAmpCampaignsModalType, showAmpCampaigns);
}
