import { takeLatest } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';

import * as NS from '../../types';

import * as loadingSagas from './loadingSagas';

const loadAttributesType: NS.LoadAttributes['type'] = 'COMMON_DATA_SERVICE:LOAD_ATTRIBUTES';
const loadMetricsType: NS.LoadMetrics['type'] = 'COMMON_DATA_SERVICE:LOAD_METRICS';
const loadMediaplanType: NS.LoadMediaplan['type'] = 'COMMON_DATA_SERVICE:LOAD_MEDIAPLAN';
const loadCabinetsType: NS.LoadCabinets['type'] = 'COMMON_DATA_SERVICE:LOAD_CABINETS';
const loadAmpAttributesType: NS.LoadAmpAttributes['type'] = 'COMMON_DATA_SERVICE:LOAD_AMP_ATTRIBUTES';
const loadAmpMetricsType: NS.LoadAMPMetrics['type'] = 'COMMON_DATA_SERVICE:LOAD_AMP_METRICS';
const loadCSDictionariesType: NS.LoadCSDictionaries['type'] = 'COMMON_DATA_SERVICE:LOAD_CS_DICTIONARIES';
const loadCsCampaignInfoType: NS.LoadCsCampaignInfo['type'] = 'COMMON_DATA_SERVICE:LOAD_CS_CAMPAIGN_INFO';
const loadCsAmpCampaignInfoType: NS.LoadCsAmpCampaignInfo['type'] = 'COMMON_DATA_SERVICE:LOAD_CS_AMP_CAMPAIGN_INFO';

export function* rootSaga(deps: IDependencies) {
  yield takeLatest(loadAttributesType, loadingSagas.loadAttribures, deps);
  yield takeLatest(loadMetricsType, loadingSagas.loadMetrics, deps);
  yield takeLatest(loadMediaplanType, loadingSagas.loadMediaplan, deps);
  yield takeLatest(loadCabinetsType, loadingSagas.loadCabinets, deps);
  yield takeLatest(loadAmpAttributesType, loadingSagas.loadAmpAttributes, deps);
  yield takeLatest(loadAmpMetricsType, loadingSagas.loadAmpMetrics, deps);
  yield takeLatest(loadCSDictionariesType, loadingSagas.loadCSDictionaries, deps);
  yield takeLatest(loadCsCampaignInfoType, loadingSagas.loadCsCampaignInfo, deps);
  yield takeLatest(loadCsAmpCampaignInfoType, loadingSagas.loadCsAmpCampaignInfo, deps);
}
