import * as R from 'ramda';

import { put, call, select } from 'redux-saga/effects';

import { IDependencies } from 'shared/types/app';
import * as M from 'shared/types/models';
import { TableCell, Column, CellContent } from 'shared/types/models/EditMetrics';

import * as NS from '../../../types';
import * as helpers from '../../../helpers';

import { actionCreators as alertActionCreators } from 'services/Alert';
import { tryCatch } from 'services/ErrorTracking';

import { actionCreators, selectors } from '../../../redux';
import { getHotInstance } from '../../dependencies';

export function* saveMediaplan({ api }: IDependencies) {
  yield tryCatch({
    *successed() {
      const cells: TableCell[][] = yield select(selectors.selectCells);
      const columns: Column[] = yield select(selectors.selectColumns);
      const { mediaplanId, mediaplanVersionId }: M.Mediaplan = yield select(selectors.selectMediaplan);
      const ht = getHotInstance();

      if (!ht) {
        throw Error('hot instance not initialized');
      }

      const errorMessage = helpers.hotTable.validateTable(ht, columns);

      if (errorMessage) {
        yield put(actionCreators.saveMediaplanFailed('Ошибка валидации'));
        yield put(alertActionCreators.openAlert({ type: 'error', message: errorMessage }));
        return;
      }

      const content = makeEditMetricsContent(columns, cells);

      const budgetCanExceedPlan: NS.BudgetMayExceedPlanType = yield select(selectors.selectParameterValue, 'budgetMayExceedPlan');

      yield call(api.metric.saveMediaplanEditedMetrics, {
        mediaplanVersionId,
        mediaplanId,
        columns: R.reject(x => helpers.isMarkupBudgetColumn(x), columns),
        content,
        budgetCanExceedPlan,
      });

      yield put(actionCreators.saveMediaplanCompleted());
      yield put(alertActionCreators.openAlert({ type: 'success', message: 'Данные успешно сохранены!' }));
    },
    *failed(_, message) {
      yield put(actionCreators.saveMediaplanFailed(message));
      yield put(alertActionCreators.openAlert({ type: 'error', message: 'Ошибка сохранения' }));
    },
  });
}

function makeEditMetricsContent(columns: Column[], cells: TableCell[][]): CellContent[][] {
  const ratioColumnsIndexes = helpers.getRatioColumnsIndexes(columns);
  const attributeColumnsIndexes = helpers.getAttributeColumnsIndexes(columns);
  const budgetMarkupColumnsIndexes = helpers.getMarkupBudgetColumnsIndexes(columns);
  const indexesForRemove = [
    ...attributeColumnsIndexes,
    ...ratioColumnsIndexes,
    ...budgetMarkupColumnsIndexes,
  ];

  const metricsCells = helpers.removeCellsByColumnsIndexes(cells, indexesForRemove);

  const preparedCells: TableCell[][] = metricsCells.map(row =>
    row.map((cell, i) => ({ ...cell, content: i === 0 || cell.isChanged ? cell.content : null }))
  );

  return helpers.convertCellsToContent(preparedCells);
}
